import App from './MainApp.vue'
//import VueGtag from "vue-gtag";

import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import SubscriptionService from './services/subscriptionservice'
import UserService from './services/userService'
import { VueQueryPlugin } from '@tanstack/vue-query';
/*import 'element-ui/lib/theme-chalk/index.css';*/
import 'element-plus/dist/index.css';
import './index.css';
import router from './routes';
import axios from 'axios';

axios.defaults.baseURL = '/';

const app = createApp(App);

app.provide('SubscriptionService', SubscriptionService);
app.provide('UserService', UserService);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(ElementPlus);
app.use(VueQueryPlugin);
/*app.use(VueGtag, {
    config: {
        id: "G-5GZDW592KV",
        params: {
            send_page_view: false
        }
    }

});*/

app.use(router);
app.mount('#MainPage')
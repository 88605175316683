<template>
    <div>
        <el-dialog :title="Question" style="word-break:break-word !important" :width="Stage === 'ReportType' ? '600px' : '750px'" v-model="IsVisible">
            <el-radio-group v-model="ReportType" v-if="Stage==='ReportType'">
                <el-radio label="short" border class="myRadio">

                    <strong>Short Report</strong><br />
                    <span v-if="UserSelection.legacyPolicyProviderReference !== null && this.UserSelection.legacyPolicyProviderReference !== ''">
                        A high-level report that compares the existing product to your chosen<br />product and the rest of the market
                    </span>
                    <span v-else>
                        A high level report that shows the overall results for each product.
                    </span>
                </el-radio><br />
                <el-radio label="long" border class="myRadio">
                    <strong>
                        Detailed Report
                    </strong><br />
                    <span v-if="UserSelection.legacyPolicyProviderReference !== null && this.UserSelection.legacyPolicyProviderReference !== ''">
                        Compare the existing product to a new product in detail showing any<br />conditions where the existing plan provides better coverage
                    </span>
                    <span v-else>
                        Overall results and details behind these result for up to 3 products
                    </span>
                </el-radio>
            </el-radio-group>
            <!--<span>
            <el-checkbox v-if="ReportType === 'long' && Stage==='ReportType'" v-model="HasDataTables">Include detailed quality comparisons</el-checkbox>
                </span>-->
            <span v-if="false && ReportType === 'long' && HasDataTables && Stage==='ReportType'">
                <span v-for="cat in FeaturesCategory" :key="cat.category">
                    <span v-if="cat.category === 'Critical Illness Analysis'">
                        <span v-for="featureCat in CriticalIllnessFeatures" :key="featureCat.featureReference">
                            <el-checkbox :value="featureCat.featureReference"
                                         :checked="UserSelection.includedFeatureInDataTable.some(function(ef) { return ef.featureReference === featureCat.featureReference})"
                                         @click="ToggleFeatureToDataTable(featureCat)">
                                {{featureCat.featureName}}
                            </el-checkbox>
                        </span>
                    </span>
                    <span v-else>

                        <el-checkbox-group v-model="UserSelection.includeCategoryInDataTable">
                            <el-checkbox :label="cat.category">

                            </el-checkbox>
                        </el-checkbox-group>
                    </span>
                </span>
            </span>
            <span v-if="Stage === 'ProviderSelection'">
                <div style="max-height:400px; overflow-y:auto;">
                    <collapsable-bar-chart :analysis="Analysis" :user-selection="UserSelection" chart-type="HighSelectable" :max-selection="UserSelection.legacyPolicyProviderReference !== null && UserSelection.legacyPolicyProviderReference !== '' ? 2 : 3" :selection="CompareSelection" :chart-data="SelectedMainChartData" :expanded-keys="ExpandedKeys" :alternate-value-title="ChartDataHeadings" />
                    <collapsable-bar-chart :analysis="Analysis" :user-selection="UserSelection" chart-type="Selectable" :max-selection="UserSelection.legacyPolicyProviderReference !== null && UserSelection.legacyPolicyProviderReference !== '' ? 2 : 3" :selection="CompareSelection" :chart-data="UnselectedMainChartData" :expanded-keys="ExpandedKeys" :alternate-value-title="ChartDataHeadings" />
                </div>
            </span>
            <span v-if="Stage === 'RecommendedSelection'">
                <collapsable-bar-chart :analysis="Analysis" :user-selection="UserSelection" chart-type="RecommendSelectable" :max-selection="1"
                                       :selection="CompareSelection" :chart-data="SelectedMainChartData" :expanded-keys="ExpandedKeys"
                                       :alternate-value-title="ChartDataHeadings" />
            </span>
            <p style="text-align:right">
                <el-button v-if="Stage === 'ReportType'" :type="NextButtonDisabled ? null : 'primary'" style="width:100px;" @click="NextPressed" :disabled="NextButtonDisabled">
                    Next
                    <i class="el-icon-arrow-right"></i>
                </el-button>
                <el-button type="default" v-if="Stage === 'ProviderSelection'" @click="Stage = 'ReportType'" style="width:100px;">
                    <i class="el-icon-arrow-left"></i>
                    Back
                </el-button>
                <el-button type="default" v-if="Stage === 'RecommendedSelection'" @click="Stage = 'ProviderSelection'" style="width:100px;">
                    <i class="el-icon-arrow-left"></i>
                    Back
                </el-button>
                <el-button v-if="Stage === 'ProviderSelection' || Stage === 'RecommendedSelection'" style="width:200px;" :type="NextButtonDisabled ? null : 'primary'" @click="NextPressed" :disabled="NextButtonDisabled">
                    Select and continue
                </el-button>
            </p>
        </el-dialog>
    </div>
</template>
<style>
    .el-dialog__header {
        word-break: break-word !important;
    }
</style>
<style scoped>

    .el-dialog__header {
        word-break: break-word !important;
    }

    .el-radio {
        margin-right: 0px !important;
    }

    .el-button--primary, .el-button--primary:hover {
        background: #1E228C !important;
    }

    .myRadio {
        padding-left: 15px !important;
        width: 100%;
        min-height: 60px;
        margin-bottom: 10px;
    }

    .selectList {
        text-align: center;
    }

        .selectList .dtButton label {
            width: 350px;
        }
</style>
<script>

    import CollapsableBarChart from './CollapsableBarChart.vue';
    import $ from 'jquery';

    export default {
        name: 'ReportDetails',
        components: {
            CollapsableBarChart
        },
        props: ["analysis", "userSelection", "userDetails", "currentAnalysis", "chartData", "chartDataHeadings", "compareSelection"],
        data: function () {
            return {
                ReportTitle: "",
                HasDataTables: false,
                IncludedProviders: [],
                SortMainChartOrder: "Overall",
                IncludeCategoryInDataTable: [],
                ShouldIncludeDefinitions: true,
                Stage: "ReportType",
                ExpandedKeys: { 'keys': [] },
                ReportType: null,
                IsVisible: true
            }
        },
        watch: {
            IsVisible: function (newValue) {
                if (newValue === false) {
                    this.$emit('report-details-cancelled')
                }
            },
            ReportType: function () {
                if (this.ReportType === 'long' || this.ReportType === 'short')
                    this.HasDataTables = true;
            }
        },
        methods: {
            ToggleFeatureToDataTable: function (feature) {
                if (this.UserSelection.includedFeatureInDataTable.some(function (ef) { return ef.featureReference === feature.featureReference }))
                    this.UserSelection.includedFeatureInDataTable = this.UserSelection.includedFeatureInDataTable.filter(function (ef) { return ef.featureReference !== feature.featureReference })
                else {
                    this.UserSelection.includedFeatureInDataTable.push({
                        featureReference: feature.featureReference,
                        subfeatures: feature.subFeatures.map(function (sf) { return sf.subfeatureReference; })
                    });
                }
            },
            RemoveIncludedProvider: function (provider) {
                this.IncludedProviders.splice(this.IncludedProviders.indexOf(provider), 1);
            },
            CanGenerate: function () {
                //if (this.Person1.trim() === "")
                //    return false;
                //if (this.Person2.trim() === "" && this.UserSelection.secondaryClient !== null)
                //    return false;
                /*if (this.ReportTitle.trim() === "")
                    return false;
                if (this.HasDataTables && this.UserSelection.includedProviders != null && this.UserSelection.includedProviders.length === 0)
                    return false;
                return true;*/

                if (this.ReportTitle.trim() === "")
                    return false;
                if (this.CompareSelection != null && this.CompareSelection.length === 0)
                    return false;
                return true;
            },
            ChangeProvider: function (oldProvider, ev) {
                this.IncludedProviders.splice(this.IncludedProviders.indexOf(oldProvider), 1, ev.target.options[ev.target.selectedIndex].value)
            },
            AddProvider: function (ev) {
                if (ev.target.selectedIndex !== 0) {
                    this.IncludedProviders.push(ev.target.options[ev.target.selectedIndex].value);
                    ev.target.selectedIndex = 0;
                }
            },
            AvailableProviders: function (providerReferenceToInclude) {
                var thisControl = this;
                return this.CurrentAnalysis.providers.filter(function (p) { return thisControl.IncludedProviders.indexOf(p.providerReference) == -1 || p.providerReference === providerReferenceToInclude })
            },
            ReportDetails: function () {
                var thisControl = this;

                thisControl.UserSelection.reportTitle = thisControl.ReportTitle;
                thisControl.UserSelection.extendedFeatures.filter(function (feature) { return feature.weight > 0 }).forEach(function (feature) {
                    if (thisControl.UserSelection.includedFeatureInDataTable.some(function (ifid) { return ifid.featureReference == feature.featureReference }))
                        feature.includeInDataTable = true;
                });
                thisControl.UserSelection.categoryWeightings.filter(function (category) { return category.weight > 0 }).forEach(function (category) {

                    if (thisControl.UserSelection.includeCategoryInDataTable.includes(category.category)) {
                        thisControl.UserSelection.extendedFeatures.filter(function (feature) { return feature.weight > 0 && feature.featureCategory === category.category }).forEach(function (feature) {
                            feature.includeInDataTable = true;
                        });
                    }
                });

                return {
                    selection: thisControl.UserSelection,
                    analysis: thisControl.CurrentAnalysis,
                    firstInsuredName: thisControl.UserSelection.primaryClient.forename + " " + thisControl.UserSelection.primaryClient.surname,
                    secondInsuredName: thisControl.UserSelection.secondaryClient === null ? null : thisControl.UserSelection.secondaryClient.forename + " " + thisControl.UserSelection.secondaryClient.surname,
                    providersInDataTables: thisControl.HasDataTables ? thisControl.CompareSelection : null,
                    reportTitle: thisControl.ReportTitle,
                    ShouldIncludeDefinitions: true
                };
            },
            GetCriticalIllnessFeatures: function () {
                var thisControl = this;
                return thisControl.UserSelection.extendedFeatures.filter(function (feature) {
                    return feature.featureCategory === 'Critical Illness Analysis' && feature.weight > 0
                });
            },
            GetFeaturesCategory: function () {
                var thisControl = this;
                return thisControl.UserSelection.categoryWeightings.filter(function (category) {
                    return category.weight > 0
                });
            },
            SetIncludeCategoryInDataTable: function (fa, IsCategory) {
                if (IsCategory === true)
                    this.IncludeCategoryInDataTable.push(fa.category)
                else
                    this.IncludeCategoryInDataTable.push(fa.featureCategory)
            },
            SaveTemplate: function () {
                var thisControl = this;
                thisControl.UserSelection.hasDataTables = thisControl.HasDataTables;
                thisControl.UserSelection.reportTitle = thisControl.ReportTitle;
                thisControl.UserSelection.shouldIncludeDefinitions = thisControl.ShouldIncludeDefinitions;

                thisControl.UserSelection.amountAssured = 0;
                thisControl.UserSelection.policyTerm = 0;
                thisControl.UserSelection.children = [];
                thisControl.UserSelection.primaryPersonName = '';
                thisControl.UserSelection.secondaryPersonName = '';
                thisControl.UserSelection.primaryClient = { classification: "Male", dateOfBirth: '1990-01-01' };
                thisControl.UserSelection.secondaryClient = null;

                var vTemplateName = prompt("Enter template name:");
                if (vTemplateName != null && vTemplateName != undefined && vTemplateName != "") {
                    $.ajax({
                        type: 'PUT',
                        url: "/ComparisonTemplate/" + this.UserSelection.tool.toolReference,
                        data: JSON.stringify({
                            templateName: vTemplateName,
                            toolKey: thisControl.UserSelection.tool.toolReference,
                            selection: thisControl.UserSelection,
                        }),
                        contentType: 'application/json',
                        error: function (xhr) {
                            thisControl.$message.error("Error saving template:" + xhr.responseText);
                        },
                        success: function () {
                            thisControl.$message.error("Saved");
                        }
                    });
                }
            },
            NextPressed: function () {
                switch (this.Stage) {
                    case 'ReportType':
                        this.Stage = 'ProviderSelection';
                        break;
                    case 'ProviderSelection':
                        if (this.UserSelection.legacyPolicyProviderReference !== null && this.UserSelection.legacyPolicyProviderReference !== '') {
                            if (this.ReportType === 'short' && this.UserSelection.productsInFocus.length >= 1) {
                                this.$emit('generate-short-report', this.ReportDetails())
                            }
                            else
                                this.$emit('generate-report', this.ReportDetails())
                        }
                        else {
                            this.Stage = 'RecommendedSelection';
                        }
                        break;
                    case 'RecommendedSelection':
                        if (this.ReportType === 'short' && this.UserSelection.productsInFocus.length >= 1) {
                            this.$emit('generate-short-report', this.ReportDetails())
                        }
                        else
                            this.$emit('generate-report', this.ReportDetails())
                        break;
                }
            }
        },
        created: function () {
            var thisControl = this;

            thisControl.HasDataTables = thisControl.UserSelection.hasDataTables;

            if (thisControl.UserSelection.includedProviders !== null && thisControl.UserSelection.includedProviders.length > 0)
                thisControl.IncludedProviders = thisControl.UserSelection.includedProviders;

            //thisControl.UserSelection.includedFeatureInDataTable = [];

            if (thisControl.UserSelection.includeCategoryInDataTable !== null && thisControl.UserSelection.includeCategoryInDataTable.length == 0)
                thisControl.UserSelection.includeCategoryInDataTable = [];
            else
                thisControl.IncludeCategoryInDataTable = thisControl.UserSelection.includeCategoryInDataTable;

            if (thisControl.UserSelection.reportTitle !== null && thisControl.UserSelection.reportTitle !== undefined && thisControl.UserSelection.reportTitle.length > 0)
                thisControl.UserSelection.reportTitle = thisControl.ReportTitle

            thisControl.ShouldIncludeDefinitions = thisControl.UserSelection.shouldIncludeDefinitions;

            thisControl.UserSelection.extendedFeatures.filter(function (feature) { return feature.weight > 0 }).forEach(function (feature) {
                feature.includeInDataTable = false;
            });
            thisControl.UserSelection.categoryWeightings.filter(function (category) { return category.weight > 0 }).forEach(function (category) {
                if (thisControl.UserSelection.includeCategoryInDataTable.includes(category.category)) {
                    thisControl.UserSelection.extendedFeatures.filter(function (feature) { return feature.weight > 0 && feature.featureCategory === category.category }).forEach(function (feature) {
                        feature.includeInDataTable = false;
                    });
                }
            });
        },
        computed:
        {
            Analysis: function () { return this.analysis; },
            UserSelection: function () { return this.userSelection; },
            UserDetails: function () { return this.userDetails; },
            CurrentAnalysis: function () { return this.currentAnalysis; },
            ChartData: function () { return this.chartData; },
            ChartDataHeadings: function () { return this.chartDataHeadings; },
            CompareSelection: function () { return this.compareSelection; },
            SelectedMainChartData: function () {
                var result = this.chartData;
                var thisControl = this;

                return {
                    showLegend: result.showLegend,
                    max: result.max,
                    items: result.items
                        .filter(function (a) { return thisControl.CompareSelection.indexOf(a.values[0].Reference) >= 0 })
                        .sort(function (a) { return a.values[0]?.IsLegacy ? -1 : 1 })
                };
            },
            UnselectedMainChartData: function () {
                var result = this.ChartData;
                var thisControl = this;

                return {
                    showLegend: result.showLegend,
                    max: result.max,
                    items: result.items.filter(function (a) { return thisControl.compareSelection.indexOf(a.values[0].Reference) < 0 })
                };
            },

            NextButtonDisabled: function () {
                if (this.Stage === 'RecommendedSelection' && (this.UserSelection.recommendProviderReference === null || this.UserSelection.recommendProviderReference === ""))
                    return true;
                if (this.Stage === 'ReportType') {
                    if (this.ReportType === null)
                        return true; //Use needs to select report type...

                    if (this.ReportType === 'long' && this.HasDataTables && this.UserSelection.includeCategoryInDataTable.length === 0) {
                        return false; //If long report with datatables, you must select at least datatable
                    }
                }

                if (this.Stage === 'ProviderSelection' && this.compareSelection.length == 1 && this.UserSelection.legacyPolicyProviderReference !== null && this.UserSelection.legacyPolicyProviderReference !== '')
                    return true;

                if (this.Stage === 'ProviderSelection') {
                    return this.CompareSelection.length == 0;
                }
                return false;
            },
            Question: function () {
                switch (this.Stage) {
                    case 'ReportType':
                        return 'What type of report would you like to generate?';
                    case 'ProviderSelection':
                        {
                            if (this.UserSelection.legacyPolicyProviderReference !== null && this.UserSelection.legacyPolicyProviderReference !== '')
                                return 'Select which policy you would like to compare to the ' + this.UserSelection.legacyPolicyProvider + ' within the detail report';
                            else
                                return 'Select up to 3 products you would like compared in detail';
                        }
                    case 'RecommendedSelection':
                        {
                            return 'Select which product you plan to recommend to your client';
                        }
                }
                return null;
            },
            IsIncludingPivotedFeature: {
                get: function () {
                    var thisControl = this;
                    return thisControl.UserSelection.includedFeatureInDataTable.some(
                        function (fr) {
                            return thisControl.UserSelection.extendedFeatures.some(function (ef) { return ef.featureReference == fr.featureReference && ef.shouldDataTableBePivoted; });
                        }
                    )
                }
            },
            SortedChartData: {
                get: function () {
                    if (this.chartData != null) {
                        var result = this.chartData.map(function (a) { return a });

                        if (this.SortMainChartOrder == "Overall")
                            result.sort(function (a, b) { return b.Value - a.Value; });
                        else
                            result.sort(function (a, b) {
                                if (a.Label < b.Label)
                                    return -1;
                                if (a.Label > b.Label)
                                    return 1;
                                return 0;
                            });

                        return result;
                    }
                    return "";
                }
            }
        },
    }</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

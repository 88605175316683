<template>
    <div class="holder">
        <div class="mainCategoryDiv">
            <div>
                <p>
                    <span>
                        <label class="numbers">5</label>
                        <label class="categoryDetails">Feature categories</label>
                    </span>

                    <span style="float:right">
                        <label class="percentageCompleted">85% completed</label>
                    </span>
                </p>

                <p>
                    <el-progress style="padding-top:5px;" class="el-progress--without-text" :percentage="85"></el-progress>
                </p>

                <div class="item">
                    <p class="Heading4">How would you like to compare the quality of plans?</p>
                </div>

                <category-row :category="cat"
                              v-for="cat in AvailableCategories"
                              :is-data-table="UserSelection.analysisType == 'DataTables'"
                              :includ-categories="includedCategories"
                              :express-template="UserSelection.expressTemplate"
                              :key="cat.category"
                              :category-tooltip="getTooltip(cat.category)" />

                <div class="btnNextPosition">
                    <el-button type="default" class="btnDefault" v-on:click.stop="$emit('back')"> <span class="el-icon-arrow-left"></span> Back</el-button>
                    <el-button type="success" v-on:click.stop="$emit('category-selection-completed')" :disabled="!canProceed()">Next</el-button>
                </div>

            </div>
        </div>

        <div class="mainCategoryDiv" v-if="false">
            <advertisement v-if="advert !== null" :advert="advert" loading-message="Loading..." :user-selection="UserSelection" screen="Category Selection" />
            <loading-control v-if="isLoading && advert === null" :loading-message="'loading...'" />
            <fieldset v-if="!isLoading">
                <legend>
                    Feature Categories
                </legend>
                <p v-if="UserSelection.analysisType === 'Comparison'">Please select the categories you would like to include in the analysis and give these a priority, where 1 is the lowest priority and 5 is the highest priority. Categories that are given a higher priority will have a bigger impact on the end score</p>
                <p v-else>Please select the categories you would like to include in the data table and give these a priority.</p>
                <table class="analysisStyles">
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>Priority</th>
                        </tr>
                    </thead>
                    <tbody>
                        <category-row :category="cat"
                                      v-for="cat in AvailableCategories"
                                      :is-data-table="UserSelection.analysisType == 'DataTables'"
                                      :includ-categories="includedCategories"
                                      :express-template="UserSelection.expressTemplate"
                                      :key="cat.category"
                                      :category-tooltip="getTooltip(cat.category)" />
                    </tbody>
                </table>
                <div>
                </div>
            </fieldset>
            <fieldset class="navigation analysisbottomMargin50" v-if="!isLoading">
                <button v-on:click.stop="$emit('back')">Back</button>
                <button v-on:click.stop="$emit('category-selection-completed')" :disabled="!canProceed()">Next</button>
            </fieldset>
        </div>

        <div class="summary">
            <label class="summaryTitle">Summary</label> <br />

            <div style="display:inline-flex">
                <label class="summaryLabels">Client details</label>
                <el-link v-on:click.stop="$emit('edit-customer')" class="summaryLabels" style="margin-left: 120px; text-decoration: underline">Edit</el-link>
            </div>

            <div v-if="UserSelection.primaryClient !== null">
                <label class="summaryDetail">{{UserSelection.primaryClient.forename + " " + UserSelection.primaryClient.surname}}</label>
                <label class="summaryDetail">
                    {{helper.GetDisplayableDate(UserSelection.primaryClient.dateOfBirth)}} , {{helper.GetDisplayableClassification(UserSelection.primaryClient.classification)}}
                </label>
            </div>

            <div v-if="UserSelection.secondaryClient !== null">
                <label class="summaryDetail">{{UserSelection.secondaryClient.forename + " " + UserSelection.secondaryClient.surname}}</label>
                <label class="summaryDetail">
                    {{helper.GetDisplayableDate(UserSelection.secondaryClient.dateOfBirth)}} , {{helper.GetDisplayableClassification(UserSelection.secondaryClient.classification)}}
                </label>
            </div>

            <hr style="border: 1px solid #A0A0A0;" />
            <label class="summaryLabels">Policy type</label>

            <label class="summaryDetail">{{UserSelection.tool.toolName}}</label>
            <hr style="border: 1px solid #A0A0A0;" />

            <label class="summaryLabels">Policy details</label><br />
            <label class="summaryDetail">{{"Term length " + UserSelection.policyTerm + " , Benefit basis " + UserSelection.ipBenefitBasis + ", initial deferred period " + deferredPeriod[UserSelection.ipInitialDeferredPeriod] }}</label> <br />

            <hr style="border: 1px solid #A0A0A0;" />

            <label class="summaryLabels">Quality of plans</label>
            <label class="summaryDetail">{{UserSelection.planType}}</label>
            <hr style="border: 1px solid #A0A0A0;" />

            <label class="summaryLabels">Additional info</label>
        </div>
    </div>

</template>
<style>
    .el-collapse-item__header {
        display: block !important;
        color: var(--el-collapse-header-text-color) !important;
        cursor: pointer !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        background: none !important;
    }
</style>
<style scoped>
    .holder {
        max-width: 1144px;
        min-width: 1144px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 24px;
        position: relative;
    }
    /* ipad Mini Landscape */
    @media only screen and (width:1024px) {
        .mainCategoryDiv {
            margin-left: -250px;
        }

        .summary {
            left: -250px;
        }
    }

    .mainCategoryDiv {
        padding: 82px;
        position: absolute;
        width: 531px;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .el-link.el-link--default:hover {
        color: black !important;
    }

    .el-link.is-underline:hover:after {
        border: none;
    }

    .el-link.el-link--default:after {
        border: none;
    }

    .summaryDetail {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;
        width: 194px;
        height: 40px;
    }

    .summaryLabels {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 36px;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;
        color: #A0A0A0;
        /*margin-top:16px*/
    }

    .summary {
        position: absolute;
        width: 232px;
        height: 572px;
        top: 0;
        right: 150px;
    }

    .summaryTitle {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 41px;
        letter-spacing: -0.04em;
        color: #0F172A;
    }

    .btnNextPosition {
        display: inline-flex;
        padding-top: 20px;
        padding-left: 10px;
    }

    .el-button--success {
        color: #fff !important;
        background-color: #059669 !important;
        /*border-color: #059669 !important;*/
    }

    .el-button--default {
        color: black !important;
        background-color: white !important;
        border: 1px solid #191D23 !important;
    }

    /*.el-button--default:focus, .el-button--default:hover {
            background: white;
            border-radius: 8px;
            border: 1px solid #191D23;
            border-radius: 8px;
            color: black;
        }*/

    .el-button {
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        align-items: center !important;
        padding: 10px !important;
        position: static !important;
        width: 167px !important;
        height: 44px !important;
        left: 199px !important;
        top: 0px !important;
        border-radius: 8px !important;
    }

    .el-button--success:focus, .el-button--success:hover {
        background: #059669 !important;
        border-radius: 8px !important;
        /*border-color: #059669 !important;*/
    }

    .el-button--success.is-disabled, .el-button--success.is-disabled:active, .el-button--success.is-disabled:focus, .el-button--success.is-disabled:hover {
        background: #E7EAEE !important;
        border-radius: 8px !important;
        /*border-color: #E7EAEE !important;*/
        font-style: normal !important;
        color: #A0ABBB !important;
    }


    .Heading4 {
        width: 366px;
        height: 74px;
        left: 0px;
        top: 66px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -0.02em;
        color: #0F172A;
    }

    .el-collapse-item__header {
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 19px !important;
        display: flex !important;
        align-items: center !important;
        width: 313px !important;
        height: 33px !important;
    }

    .el-icon-arrow-right:before {
        content: "+" !important;
    }

    .el-icon-arrow-right:before {
        content: "-" !important
    }

    .numbers {
        background: #EEEEEE;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        background: #EEEEEE;
        border-radius: 15px;
        padding: 2px;
    }

    .categoryDetails {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        align-items: center;
        letter-spacing: -0.04em;
        padding-left: 10px;
    }

    .percentageCompleted {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: -0.04em;
    }
</style>
<script>
    import $ from 'jquery';
    import CategoryRow from "../components/CategoryRow.vue";
    import helper from "../components/HelperMethods.vue";
    import LoadingControl from '../components/LoadingControl.vue';
    import Advertisement from '../components/Advertisement.vue';
    export default {
        name: 'CategorySelectionPage',
        components: { CategoryRow, LoadingControl, Advertisement },
        props: ["userSelection", "adverts"],
        data: function () {
            return {
                deferredPeriod: ['1 day', '1 Week', '2 Weeks', '1 Month', '2 Month', '3 Month', '6 Month', '12 Month', '24 Months'],
                helper: helper,
                isDatatable: false,
                isLoading: false,
                advert: null,
                includedCategories: []
            }
        },
        methods: {
            getTooltip: function (catKey) {
                return this.UserSelection.extendedFeatures.filter(function (f) { return f.featureCategory === catKey; })[0].featureCategoryDescription;
            },
            canProceed: function () {
                if (this.UserSelection.analysisType === "DataTables")
                    return this.UserSelection.categoryWeightings.some(function (cw) { return cw.includedInDataTable; });
                return this.UserSelection.categoryWeightings.some(function (cw) { return cw.weight > 0; });
            }
        },
        mounted: function () {
            var thisControl = this;
            if (thisControl.UserSelection.expressTemplate !== null) {
                thisControl.UserSelection.expressTemplate.includedCategories.forEach(function (ic) {
                    if (thisControl.UserSelection.legacyPolicyProviderReference !== null && thisControl.UserSelection.legacyPolicyProviderReference !== '') {
                        thisControl.includedCategories.push(thisControl.UserSelection.categoryWeightings.filter(function (cw) { return (cw.category === ic.categoryReference || ic.categoryReference.includes(cw.category)); })[0].category);
                        thisControl.UserSelection.categoryWeightings
                            .filter(function (cw) { return (cw.category === ic.categoryReference || ic.categoryReference.includes(cw.category)); })[0].weight = ic.defaultPriority;
                    }
                    else {
                        thisControl.includedCategories.push(ic.categoryReference);
                        thisControl.UserSelection.categoryWeightings
                            .filter(function (cw) { return cw.category === ic.categoryReference; })[0].weight = ic.defaultPriority;
                    }
                });
                //can we change included categories dont sent event
                if (thisControl.UserSelection.expressTemplate.includedCategories.some(function (ic) { return ic.availablePriorities.length > 1 })) {
                    return;
                }
                var toolCategories = thisControl.UserSelection.extendedFeatures.map(function (f) { return f.featureCategory }).filter(helper.distinct);
                //can add additional categories (allowed and available)
                if (thisControl.UserSelection.expressTemplate.canAddAdditionalCategories && toolCategories.some(function (c) { return !thisControl.includedCategories.includes(c); })) {
                    return;
                }

                //There is nothing a user can do here so let's skip this screen.
                this.$emit('category-selection-skipped')
                return;
            }


            var waiting = 1;
            this.isLoading = true;

            var done = function () {
                thisControl.isLoading = false;
                thisControl.advert = null;
            }

            var adToShow = helper.GetAdvert(this.adverts, "CategorySelectionPage", this.UserSelection);
            if (adToShow !== null) {
                this.advert = adToShow;
                waiting++;
                setTimeout(function () {
                    waiting--;
                    if (waiting == 0)
                        done();
                }, adToShow.minimumDurationInSeconds * 1000);
            }

            $.ajax({
                type: 'GET',
                url: '/api/Journey/Update/' + this.UserSelection.sessionReference + "/" + this.UserSelection.journeyReference + "/20/Category%20Selection",
                success: function () {
                    waiting--;
                    if (waiting == 0)
                        done();
                },
            });
        },
        computed: {
            UserSelection: function () { return this.userSelection; },
            "AvailableCategories": function () {
                var thisControl = this;
                if (this.UserSelection.expressTemplate != null) {
                    if (!this.UserSelection.expressTemplate.canAddAdditionalCategories)
                        return this.UserSelection.categoryWeightings.filter(function (c) { return thisControl.UserSelection.expressTemplate.includedCategories.some(function (ic) { return ic.categoryReference == c.category; }) })
                }
                return this.UserSelection.categoryWeightings;
            }

        },
    }</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

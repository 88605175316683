<template>
    <div class="ModalBackground" style="display:block;">
        <div class="ModalDisplayPanel">
            <div class="header" style="border:none">
                <div class="header">
                    <el-button type="default" v-on:click.stop="close"><b>X</b></el-button>
                    <h4 class="Title userHeader">{{ form.header }}</h4>
                </div>
                <hr />
                <div class="content">
                    <p v-html="formattedBody"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import { ElMessage } from 'element-plus';

    export default {
        data: function () {
            return {
                form: {
                    timeStamp: "",
                    header: "",
                    body: "",
                },
            };
        },
        computed: {
            formattedBody() {
                return this.form.body.replace(/\n/g, '<br>');
            }
        },
        methods: {
            close() {
                sessionStorage.setItem("LastSeenPopupTimestamp", this.form.timeStamp);
                sessionStorage.setItem("IsShowUserPopup", false);
                this.$emit('close');
            },
            async fetchPopupMessage() {
                try {
                    const response = await axios.get(`/api/PopUpMessages/getPopupMessage`);
                    if (response.data.success) {
                        const newMessageTimestamp = response.data.data.timestamp;
                        const lastSeenTimestamp = sessionStorage.getItem("LastSeenPopupTimestamp");

                        this.form = response.data.data;
                        this.form.timeStamp = newMessageTimestamp;

                        if (newMessageTimestamp !== lastSeenTimestamp) {
                            sessionStorage.setItem("IsShowUserPopup", "true");
                            sessionStorage.setItem("LastSeenPopupTimestamp", newMessageTimestamp);
                        }
                    }
                } catch (error) {
                    console.log('Error:', error);
                    ElMessage({
                        message: "An error occurred while retrieving the message",
                        type: 'error'
                    });
                }
            }
        },
        mounted() {
            this.fetchPopupMessage();
        }
    }
</script>

<style scoped>
    .ModalBackground {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .ModalDisplayPanel {
        width: 800px !important;
        min-height: 150px !important;
        max-height: 500px !important;
        background: #FFFFFF !important;
        border-radius: 8px !important;
        padding: 20px !important;
        box-sizing: border-box !important;
        overflow-y: auto !important;
        display: flex !important;
        flex-direction: column !important;
        position: fixed !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
    }

    .header {
        flex-shrink: 0;
        border-bottom: 0px solid #e0e0e0 !important;
        margin-bottom: 10px;
    }

    .userHeader {
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 20px !important;
        line-height: 23px !important;
        letter-spacing: -0.02em !important;
        color: #2D2D2D !important;
    }

    .content {
        margin-top:20px;
        flex-grow: 1;
        overflow-y: auto;
    }

    .el-button {
        font-weight: 700 !important;
        float: right !important;
    }

    p {
        line-height: 1.6;
        margin: 0;
    }
</style>

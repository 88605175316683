<template>
    <tr style="width:400px">
        <el-collapse style="width:400px" v-model="activeNames">
            <el-collapse-item :title="ExtendedFeature.featureName" name="expanded">

                <!--<td v-bind:title="GetToolTip(ExtendedFeature)">
                    <label>{{ExtendedFeature.featureName}}</label><a v-if="ExtendedFeature.featureDescriptionLink !== null && ExtendedFeature.featureDescriptionLink !== ''" target="_blank" :href="ExtendedFeature.featureDescriptionLink">Find out more</a>
                    <br v-if="AnalysisType === 'Comparison' && ExtendedFeature.weight > 0 && ExtendedFeature.subFeatures !== null" />
                    <div v-if="(expressTemplateFeature === null || expressTemplateFeature.canChangeSubfeaturesweightings) && AnalysisType === 'Comparison' && ExtendedFeature.weight > 0 && !ExtendedFeature.canWeightSubfeaturesUsingIncidenceData && ExtendedFeature.subFeatures !== null && ExtendedFeature.subFeatures.length > 1">
                        <button class="button160" v-on:click.stop="SetEqualWeightings(ExtendedFeature);" v-bind:class="ExtendedFeature.subFeatureWeightModel == 'Equality weightings for subfeatures' ? 'button' : 'button btnNonHighlighted'">Prioritise Subfeatures Equally</button>
                        <button class="button160" v-on:click.stop="SetCustomWeightings(ExtendedFeature);" v-bind:class="ExtendedFeature.subFeatureWeightModel == 'Custom Weightings' ? 'button' : 'button btnNonHighlighted'">Custom Subfeature Priority</button>
                    </div>
                </td>-->

                <td v-bind:title="GetToolTip(ExtendedFeature)">
                    Select level of importance
                </td>

                <td v-if="AnalysisType === 'Comparison'" style="width: 100%; float: left; clear: left">
                    <select :disabled="ShouldDisable && ExtendedFeature.weight == 0" v-if="expressTemplateFeature !== null" v-model.number="ExtendedFeature.weight">
                        <option v-for="priority in GetPrioritiesForFeature()" :value="priority" :key="ExtendedFeature.featureReferece + priority"> {{ priority == 0 ? "Excluded" :  priority }}</option>
                    </select>

                    <!--<select v-else :disabled="ShouldDisable && ExtendedFeature.weight == 0" v-bind:id="'ExtendedFeature' + ExtendedFeature.featureReference" v-model.number="ExtendedFeature.weight">
                        <option value="0">Excluded</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>-->

                    <el-select v-else :disabled="ShouldDisable && ExtendedFeature.weight == 0" v-bind:id="'ExtendedFeature' + ExtendedFeature.featureReference" v-model.number="ExtendedFeature.weight" placeholder="Please select" style="width: 186px;margin-bottom:25px;">
                        <el-option label="Excluded" value="0"></el-option>
                        <el-option label="1" value="1"></el-option>
                        <el-option label="2" value="2"></el-option>
                        <el-option label="3" value="3"></el-option>
                        <el-option label="4" value="4"></el-option>
                        <el-option label="5" value="5"></el-option>
                    </el-select>

                </td>
                <td v-else>
                    <!--<select v-model.number="IncludedInDataTable">
                        <option value="false">Excluded</option>
                        <option value="true">Included</option>
                    </select>-->

                    <el-select v-model.number="IncludedInDataTable" placeholder="Please select" style="width: 186px;margin-bottom:25px;">
                        <el-option label="Excluded" value="false"></el-option>
                        <el-option label="Included" value="true"></el-option>
                    </el-select>
                </td>

                <!--<td v-bind:title="GetToolTip(ExtendedFeature)">
                    <label>{{ExtendedFeature.featureName}}</label><a v-if="ExtendedFeature.featureDescriptionLink !== null && ExtendedFeature.featureDescriptionLink !== ''" target="_blank" :href="ExtendedFeature.featureDescriptionLink">Find out more</a>
                    <br v-if="AnalysisType === 'Comparison' && ExtendedFeature.weight > 0 && ExtendedFeature.subFeatures !== null" />
                    <div v-if="(expressTemplateFeature === null || expressTemplateFeature.canChangeSubfeaturesweightings) && AnalysisType === 'Comparison' && ExtendedFeature.weight > 0 && !ExtendedFeature.canWeightSubfeaturesUsingIncidenceData && ExtendedFeature.subFeatures !== null && ExtendedFeature.subFeatures.length > 1">
                        <button class="button160" v-on:click.stop="SetEqualWeightings(ExtendedFeature);" v-bind:class="ExtendedFeature.subFeatureWeightModel == 'Equality weightings for subfeatures' ? 'button' : 'button btnNonHighlighted'">Prioritise Subfeatures Equally</button>
                        <button class="button160" v-on:click.stop="SetCustomWeightings(ExtendedFeature);" v-bind:class="ExtendedFeature.subFeatureWeightModel == 'Custom Weightings' ? 'button' : 'button btnNonHighlighted'">Custom Subfeature Priority</button>
                    </div>
                </td>
                <td v-if="AnalysisType === 'Comparison'">
                    <select :disabled="ShouldDisable && ExtendedFeature.weight == 0" v-if="expressTemplateFeature !== null" v-model.number="ExtendedFeature.weight">
                        <option v-for="priority in GetPrioritiesForFeature()" :value="priority" :key="ExtendedFeature.featureReferece + priority"> {{ priority == 0 ? "Excluded" :  priority }}</option>
                    </select>
                    <select v-else :disabled="ShouldDisable && ExtendedFeature.weight == 0" v-bind:id="'ExtendedFeature' + ExtendedFeature.featureReference" v-model.number="ExtendedFeature.weight">
                        <option value="0">Excluded</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>
                </td>
                <td v-else>
                    <select v-model.number="IncludedInDataTable">
                        <option value="false">Excluded</option>
                        <option value="true">Included</option>
                    </select>
                </td>-->

            </el-collapse-item>
        </el-collapse>
    </tr>
</template>

<script>export default {
        name: 'FeatureRow',
        props: ["expressTemplateFeature", "extendedFeature", "analysisType", "shouldDisable"],
        data: function () {
            return {
                CurrentFeature: null,
                activeNames: []
            };
        },
        methods: {
            GetPrioritiesForFeature: function () {
                var thisControl = this;
                if (thisControl.expressTemplateFeature !== null) {
                    return (this.expressTemplateFeature.availablePriorities).map(function (a) { return a; }).sort();
                }
            },
            GetToolTip: function (extendedFeature) {
                if (extendedFeature.subFeatureWeightModel !== "Custom Weightings" || extendedFeature.subFeatureWeightModel === "Custom Weightings") {
                    return extendedFeature.featureDescription;
                }
            },
            SetEqualWeightings: function (ef) {
                ef.subFeatureWeightModel = "Equality weightings for subfeatures";
            },
            SetCustomWeightings: function (ef) {
                //ef.subFeatureWeightModel = 'Custom Weightings';
                this.$emit('custom-subfeature-weights-selected', ef);
            }
        },
        computed: {
            ExtendedFeature: function () { return this.extendedFeature; },
            AnalysisType: function () { return this.analysisType; },
            ShouldDisable: function () { return this.shouldDisable; },
            IncludedInDataTable: {
                get: function () {
                    return this.ExtendedFeature.includeInDataTable ? "true" : "false";
                },
                set: function (value) {
                    this.ExtendedFeature.includeInDataTable = value === "true";
                }
            }
        },
        mounted: function () {
            if (this.ExtendedFeature.weight > 0) {
                this.activeNames = ["expanded"];
            }
        }

    }</script>
<style scoped>
    a {
        float: right;
        display: inline-block;
    }
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->

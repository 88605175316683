<template>
    <div>
        <income-protection-plan-selection :adverts="Adverts"
                                          v-if="currentScreen == 'Income Protection Plan Selection'"
                                          v-on:ip-express-question-selected="$emit('ip-express-question-selected')"
                                          v-on:express-question-selected="$emit('express-question-selected')"
                                          v-bind:template-set="TemplateSet"
                                          v-on:edit-customer="$emit('edit-customer')" v-on:back="$emit('back')" v-bind:user-selection="userSelection"
                                          v-bind:user-details="UserDetails" />

        <term-assurance-plan-selection :adverts="Adverts"
                                       v-if="currentScreen == 'Term Assurance Plan Selection'"
                                       v-on:ta-express-question-selected="$emit('ta-express-question-selected')"
                                       v-on:express-question-selected="$emit('express-question-selected')" v-bind:template-set="TemplateSet"
                                       v-on:edit-customer="$emit('edit-customer')" v-on:back="$emit('back')" v-bind:user-selection="userSelection"
                                       v-bind:user-details="UserDetails" />

        <family-income-benefit-plan-selection :adverts="Adverts"
                                              v-if="currentScreen == 'Family Income Benefit Plan Selection'"
                                              v-on:fib-express-question-selected="$emit('fib-express-question-selected')"
                                              v-on:express-question-selected="$emit('express-question-selected')" v-bind:template-set="TemplateSet"
                                              v-on:edit-customer="$emit('edit-customer')" v-on:back="$emit('back')" v-bind:user-selection="userSelection"
                                              v-bind:user-details="UserDetails" />

        <business-protection-plan-selection :adverts="Adverts"
                                            v-if="currentScreen == 'Business Protection Plan Selection'"
                                            v-on:bp-compare-critical-illness-policy="$emit('bp-compare-critical-illness-policy')"
                                            v-on:bp-express-question-selected="$emit('bp-express-question-selected')"
                                            v-on:express-question-selected="$emit('express-question-selected')" v-bind:template-set="TemplateSet"
                                            v-on:edit-customer="$emit('edit-customer')" v-on:back="$emit('back')" v-bind:user-selection="userSelection"
                                            v-bind:user-details="UserDetails" />

        <express-question :adverts="Adverts" v-if="currentScreen === 'Express Question'"
                          v-on:edit-customer="$emit('edit-customer')"
                          v-on:express-question-selected="$emit('express-question-selected')"
                          v-on:express-question-without-price-selected="$emit('express-question-without-price-selected')"
                          v-bind:template-set="TemplateSet" v-on:back="$emit('back')" v-bind:user-selection="userSelection"
                          v-bind:user-details="UserDetails" />
    </div>
</template>

<script>
    import ExpressQuestion from '../components/ExpressQuestion.vue';
    import helper from '../components/HelperMethods.vue';
    import IncomeProtectionPlanSelection from '../components/IncomeProtectionPlanSelection.vue';
    import TermAssurancePlanSelection from '../components/TermAssurancePlanSelection.vue';
    import FamilyIncomeBenefitPlanSelection from "../components/FamilyIncomeBenefitPlanSelection.vue";
    import BusinessProtectionPlanSelection from "../components/BusinessProtectionPlanSelection.vue";
    export default {
        components: {
            ExpressQuestion,
            IncomeProtectionPlanSelection,
            TermAssurancePlanSelection,
            FamilyIncomeBenefitPlanSelection,
            BusinessProtectionPlanSelection
        },
        name: 'ExpressQuestionPage',
        props: ["userSelection", "userDetails", "templateSet", "currentScreen"],
        data: function () {
            return {
                helper: helper,
            }
        },
        computed: {
            UserSelection: function () { return this.userSelection; },
            UserDetails: function () { return this.userDetails; },
            TemplateSet: function () { return this.templateSet; },
        },
        methods: {
            
        },
        mounted: function () {

        },
        watch: {

        },
        created: function () {

        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<template>
    <div class="ModalBackground" style="display:block;">
        <div class="ModalBackGroundPanel">
            <div class="header" style="border:none">
                <el-button type="default" v-on:click.stop="close"><b>X</b></el-button>
                <h4 class="Title popupMessageHeader">{{ Header }}</h4>

                <el-form :model="form" :rules="rules" ref="form" label-width="80px" class="modal-form">
                    <el-form-item label="Header" prop="header">
                        <el-input v-model="form.header"
                                  placeholder="Add Header Message"></el-input>
                    </el-form-item>

                    <el-form-item label="Body" prop="body">
                        <el-input type="textarea"
                                  v-model="form.body"
                                  :rows="13"
                                  placeholder="Add Body Message"></el-input>
                    </el-form-item>

                    <el-form-item class="button-container">
                        <el-button type="success" class="" @click="savePopupMessage">Publish Message</el-button>
                        <el-button type="danger" @click="removePopupMessage()" :disabled="!fetchedData">Remove Message</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    import { ElMessage } from 'element-plus';
    export default {
        data: function () {
            return {
                Header: "Popup Message Configuration",
                form: {
                    header: "",
                    body: "",
                },
                fetchedData: false,
                rules: {
                    header: [
                        { required: true, message: 'Please add header message', trigger: 'change' },
                        { validator: this.validateNoWhiteSpace, trigger: 'blur' }
                    ],
                    body: [
                        { required: true, message: 'Please add body message', trigger: 'change' },
                        { validator: this.validateNoWhiteSpace, trigger: 'blur' }
                    ],
                }
            };
        },
        methods: {
            validateNoWhiteSpace(rule, value, callback) {
                if (!value.trim()) {
                    callback(new Error(`${rule.field.charAt(0).toUpperCase() + rule.field.slice(1)} cannot be just white spaces`));
                } else {
                    callback();
                }
            },
            close() {
                this.resetForm();
                this.$emit('back');
            },
            savePopupMessage() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        this.$confirm('Are you sure you want to publish this message? Once published, other users will see it after logging in.', 'Confirmation', {
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No',
                            type: 'warning',
                            customClass: 'custom-confirm-box',
                            confirmButtonClass: 'small-button',
                            cancelButtonClass: 'small-button'
                        }).then(() => {
                            axios.post('/api/PopUpMessages/savePopupMessage', this.form)
                                .then(response => {
                                    if (response.data.success) {
                                        ElMessage({
                                            message: response.data.message,
                                            type: 'success'
                                        });
                                        sessionStorage.clear();
                                        this.close();
                                        location.reload();
                                    } else {
                                        ElMessage({
                                            message: "Failed to save message",
                                            type: 'error'
                                        });
                                    }
                                })
                                .catch(error => {
                                    console.log('Error:', error);
                                    ElMessage({
                                        message: "An error occurred while saving the message",
                                        type: 'error'
                                    });
                                });
                        }).catch(() => {

                        });
                    }
                });
            },
            resetForm() {
                this.$refs.form.resetFields();
            },
            removePopupMessage() {
                this.$confirm('Are you sure you want to remove this message? Once removed, other users will no longer be able to see it.', 'Confirmation', {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    type: 'warning',
                    customClass: 'custom-confirm-box',
                    confirmButtonClass: 'small-button',
                    cancelButtonClass: 'small-button'
                }).then(() => {
                    axios.delete(`/api/PopUpMessages/removePopupMessage`)
                        .then(response => {
                            if (response.data.success) {
                                sessionStorage.clear();
                                this.resetForm();
                                ElMessage({
                                    message: response.data.message,
                                    type: 'success'
                                });
                                this.close();
                            } else {
                                ElMessage({
                                    message: "Failed to remove message",
                                    type: 'error'
                                });
                            }
                        })
                        .catch(error => {
                            console.log('Error:', error);
                            ElMessage({
                                message: "An error occurred while removing the message",
                                type: 'error'
                            });
                        });
                }).catch(() => {

                });
            },
            fetchPopupMessage() {
                axios.get(`/api/PopUpMessages/getPopupMessage`)
                    .then(response => {
                        if (response.data.success) {
                            this.form = response.data.data;
                            this.fetchedData = response.data.success;
                        }
                        else {
                            this.fetchedData = response.data.success;
                        }
                    })
                    .catch(error => {
                        console.log('Error:', error);
                        ElMessage({
                            message: "An error occurred while retrieving the message",
                            type: 'error'
                        });
                    });
            },
        },
        mounted() {
            this.fetchPopupMessage();
        }
    }
</script>
<style>
    .el-message-box__btns {
        padding: 5px 15px 0 !important;
        display: flex !important;
        flex-wrap: nowrap !important;
        justify-content: flex-end !important;
        align-items: center !important;
    }

    .custom-confirm-box .small-button {
        height: 35px !important;
        line-height: 28px !important;
        font-size: 14px !important;
        padding: 0 15px !important;
        border-radius: 4px !important;
        flex-wrap: nowrap !important;
    }

    .el-message-box__headerbtn .el-message-box__close {
        display: none;
    }
</style>
<style scoped>
    .ModalBackground {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ModalBackGroundPanel {
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        width: 800px;
        height: 500px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .modal-form {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .button-container {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-top: auto;
    }

    .Title {
        font-size: 24px;
        margin-top: 10px;
    }

    .el-button {
        float: right;
    }

    .el-button--success, .el-button--success:focus, .el-button--success:hover {
        position: static !important;
        height: 48px !important;
        background: #49A267 !important;
        border-radius: 8px !important;
        border: none !important;
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        text-align: center !important;
        color: white !important;
        width: 150px !important;
    }

    .el-button--danger {
        background: #E74C3C;
        border-radius: 8px !important;
        border: none !important;
        font-family: 'Inter' !important;
        font-weight: 700 !important;
        font-size: 16px !important;
        color: white !important;
        width: 150px !important;
        height: 48px !important;
        box-shadow: none !important;
        transition: none !important;
    }

        .el-button--danger:hover,
        .el-button--danger:focus {
            background: #E74C3C;
            color: white !important;
            cursor: pointer;
        }

        .el-button.is-disabled,
        .el-button--danger.el-button--disabled:hover,
        .el-button--danger.el-button--disabled:focus {
            cursor: not-allowed;
        }

        .el-button--danger.el-button--disabled .el-button__icon,
        .el-button--danger.is-disabled .el-button__icon {
            color: #c0c4cc !important;
        }
</style>
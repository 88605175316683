<template>
    <div>
        <fieldset class="selectList" v-if="Templates !== null">
            <legend>
                Existing Templates
            </legend>
            <table class="analysisStyles">
                <thead>
                    <tr>
                        <th>
                            Template Name
                        </th>
                        <th>
                            Date Created
                        </th>
                        <th>Options</th>
                    </tr>
                </thead>
                <tbody v-if="Templates.length == 0">
                    <tr>
                        <td colspan="3">No Templates Found</td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr v-for="template in Templates" :key="template.dataTableReference">
                        <td>{{template.templateName}}</td>
                        <td>{{displayDate(template.createdOn)}}</td>
                        <td>
                            <button @click="LoadTemplate(template)">Load</button>
                            <button @click="DeleteTemplate(template)">Delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </fieldset>
        <fieldset class="navigation analysisbottomMargin50" v-if="Templates !== null">
            <button v-on:click.stop="$emit('back')">Back</button>
            <button v-on:click.stop="$emit('select-no-template')">New Detailed Product Data</button>
        </fieldset>
        <loading-control v-else :loading-message="'Loading DataTables...'" />
    </div>
</template>
<style type="text/css" scoped>
    .analysisStyles button {
        max-width: 80px;
        display: inline-block;
        margin-right: 10px;
    }

        .analysisStyles button:last-child {
            margin-right: 0px;
        }

    .analysisStyles th:last-child, .analysisStyles td:last-child {
        width: 200px;
    }
</style>
<script>import $ from 'jquery'
    import LoadingControl from './LoadingControl.vue'
    export default {
        name: 'DataTablesTemplates',
        props: ["userSelection", "userDetails"],
        components: {
            LoadingControl
        },
        data: function () {
            return {
                Templates: null
            }
        },
        methods: {
            displayDate: function (d) {
                if (d === null)
                    return "-";
                return d.substr(8, 2) + "/" + d.substr(5, 2) + "/" + d.substr(0, 4);
            },
            LoadTemplate: function (template) {
                var thisControl = this;
                $.ajax({
                    type: 'GET',
                    url: 'DataTableTemplate/' + this.UserSelection.tool.toolReference + "/" + template.dataTableReference,
                    contentType: 'application/json',
                    success: function (fullTemplate) {
                        thisControl.$emit('template-selected', fullTemplate);
                    },
                    error: function (xhr) {
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                        //templates not working so lets skip this step...
                        thisControl.$message.error("Error getting template: " + xhr.responseText);
                    }
                });

            },
            DeleteTemplate: function (template) {
                var thisControl = this;
                this.Templates = null;
                $.ajax({
                    type: 'DELETE',
                    url: 'DataTableTemplate/' + this.UserSelection.tool.toolReference + "/" + template.dataTableReference,
                    success: function () {

                        thisControl.DownloadTemplates(function () { });
                    },
                    error: function (xhr) {
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                        //templates not working so lets skip this step...
                        thisControl.$message.error("Error deleting template: " + xhr.responseText);
                        thisControl.DownloadTemplates(function () { });
                    }
                });
            },
            DownloadTemplates: function (actionIfEmpty) {
                var thisControl = this;
                $.ajax({
                    type: 'GET',
                    url: 'DataTableTemplate/' + this.UserSelection.tool.toolReference,
                    contentType: 'application/json',
                    success: function (Templates) {
                        thisControl.Templates = Templates;
                        if (thisControl.Templates.length == 0) {
                            actionIfEmpty()
                        }
                    },
                    error: function (xhr) {
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                        //templates not working so lets skip this step...
                        actionIfEmpty();
                        //alert("Error User Details: " + xhr.responseText);
                    }
                });
            },
            RefreshTemplates: function () {
                this.DownloadTemplates(function () { });
            }
        },
        created: function () {
            var thisControl = this;
            thisControl.DownloadTemplates(function () { thisControl.$emit('skip-templates'); });
        },
        computed: {
            UserSelection: function () { return this.userSelection; },
            UserDetails: function () { return this.userDetails; },
            maxProviders: {
                get: function () {
                    if (this.UserSelection.analysisType == "DataTables")
                        return this.UserDetails.maxProvidersPerDataTable;
                    else
                        return this.UserDetails.maxProvidersPerComparison;

                }
            }
        }
    }</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

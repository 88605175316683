<template>
    <div class="ModalBackground" style="display:block;">
        <div class="ModalBackGroundPanel elPopup" style="overflow: hidden">
            <div v-if="document == 'terms'" class="header">
                <h4 class="Title">Terms of Service</h4>
                <div class="elPopupBody Paragraph2TC">
                    <div>
                        <p>These Terms of Use are designed to explain our obligations as a service provider and Your obligations as a customer. Please read them carefully.&nbsp;</p>
                        <p>These terms are binding on any use of the Service and apply to You from the time that we switch on your access to the Service.&nbsp;</p>
                        <p>By registering to use this Service you acknowledge that you have read and understood these Terms and have the authority to act on behalf of any person for whom You are using the Service. You are deemed to have agreed to these Terms on behalf of any entity for whom you use the Service.&nbsp;</p>
                        <h4>Agreement:</h4>
                        <ol start="1">
                            <li>
                                <p>We&nbsp;are Financial Technology Research Centre Limited incorporated and registered in England and Wales with company number 02484495 whose registered office is at&nbsp;14 Halifax Court, Ferndown Business Park,&nbsp;Balderton, Newark NG24 3JP&nbsp;(&quot;we&quot;, &quot;us&quot;, &quot;our&quot;).&nbsp;</p>
                            </li>

                            <li>
                                <p>This agreement sets out the terms on which we will allow you to use Protection Guru Pro.&nbsp;</p>
                            </li>

                            <li>
                                <p>You may access Protection Guru Pro and produce comparison reports from it about Products and Services but only within the terms of this Agreement.&nbsp;</p>
                            </li>

                            <li>
                                <p>You must keep your login credentials for Protection Guru Pro secret and confidential. We will not be liable for misuse of your login credentials unless we have caused them to become public.&nbsp;</p>
                            </li>

                            <li>
                                <p>You will ensure your employees, contractors and agents do not use Protection Guru Pro for any purpose other than to produce reports about Products and Services for use with your retail customers. You are not allowed to use ProtectionGuruPro for other any other purpose.&nbsp;</p>
                            </li>

                            <li>
                                <p>You will ensure you do not enter any information for any purpose contrary to law and/or regulation which includes but is not limited to uploading viruses, interfering with users or other persons using Protection Guru Pro, ensuring you do not enter pornographic, racist or comments that incite religious hatred or violence through use of Protection Guru Pro.&nbsp;</p>
                            </li>

                            <li>
                                <p>For access to Protection Guru Pro You will pay the Fees set out in the Order Forms. We may amend our Fees by giving you three months' notice in writing. If you do not want to pay a fee increase you may terminate this Agreement by giving us one month's notice in writing.&nbsp;</p>
                            </li>

                            <li>
                                <p>You may purchase additional services and products from us apart from those set out in Order Forms, if you do so we will provide you with a written quote ahead of providing the services. You will accept additional services by countersigning and sending back to us the written quote we have sent to you.&nbsp;</p>
                            </li>

                            <li>
                                <p>We will provide Services to you with reasonable care and skill. If we cannot perform the Services by the date we agree with you (the &quot;Original Performance Date&quot;), we will agree with you a new date and time for delivery.&nbsp;</p>
                            </li>

                            <li>
                                <p>You will use your skill and judgement and your best endeavours to ensure that data you enter into and reports you create using Protection Guru Pro for your customers are not accurate and not misleading.&nbsp;</p>
                            </li>

                            <li>
                                <p>We may reuse data entered into Protection Guru Pro that is not identified by you as confidential.&nbsp;</p>
                            </li>

                            <li>
                                <p>We try to provide Protection Guru Pro continuously but there may be times when it is unavailable for planned and unplanned maintenance and service. We will use all reasonable endeavours to resolve any maintenance and service issues as soon as possible.&nbsp;</p>
                            </li>

                            <li>
                                <p>If you request that we carry out extra work or provide additional services not set out in an Order Form, we will charge you at our usual hourly rates.&nbsp;</p>
                            </li>

                            <li>
                                <p>We may terminate this Agreement immediately upon written notice to you upon the occurrence of a material breach of this Agreement (and if such breach is capable of remedy, which has not been remedied within 30 days of written notice to the party of the breach).&nbsp;</p>
                            </li>

                            <li>
                                <p>You may terminate this Agreement by giving us up to 90 days written notice such notice to take effect on the next anniversary of the Commencement Date. Agreement.&nbsp;</p>
                            </li>

                            <li>
                                <p>Subject to Clause 17, and after the Term, this Agreement will continue for a subsequent term of 12 months, unless we agree otherwise.&nbsp;</p>
                            </li>

                            <li>
                                <p>Neither you nor we exclude or limit liability to the other for:&nbsp;</p>
                                <ol type="a">

                                    <li>
                                        <p>fraud or fraudulent misrepresentation;&nbsp;</p>
                                    </li>
                                    <li>
                                        <p>death or personal injury caused by negligence;&nbsp;</p>
                                    </li>
                                    <li>
                                        <p>a breach of any obligations implied by section 12 of the Sale of Goods Act 1979 or section 2 of the Supply of Goods and Services Act 1982; or&nbsp;</p>
                                    </li>
                                    <li>
                                        <p>any matter in respect of which it would be unlawful for the parties to exclude liability for respectively.&nbsp;</p>
                                    </li>
                                </ol>
                            </li>

                            <li>
                                <p>Subject to clause 17, we shall not in any circumstances be liable whether in contract (by way of indemnity or otherwise), tort (including for negligence and breach of statutory duty howsoever arising), misrepresentation (whether innocent or negligent), restitution or otherwise, for:&nbsp;</p>
                                <ol type="a">
                                    <li>
                                        <p>any consequential or indirect loss or damage; or&nbsp;</p>
                                    </li>
                                    <li>
                                        <p>loss of profits, business, business opportunities, revenue, turnover, reputation, goodwill, loss or corruption of data or information, anticipated savings or wasted expenditure (including management time) (all however so arising).&nbsp;</p>
                                    </li>
                                </ol>
                            </li>

                            <li>
                                <p>Subject to clause 17, our total aggregate liability in contract (by way of indemnity or otherwise), tort (including negligence and breach of statutory duty howsoever arising), misrepresentation (whether innocent or negligent), restitution or otherwise, arising in connection with the performance or contemplated performance of this Agreement or any collateral contract shall in all circumstances be limited to the aggregate licence fees paid by you to us in a year.&nbsp;</p>
                            </li>

                            <li>
                                <p>In performing its duties under this Agreement, each party will comply with all applicable anti-bribery and anti-corruption laws and Applicable Requirements. In particular, each party hereby acknowledges and agrees:&nbsp;</p>
                                <ol type="a">
                                    <li>
                                        <p>to comply with the UK Bribery Act 2010 and all anti-money laundering legislation and Applicable Requirements and that it shall not engage in any activity, practice or conduct which could be construed as a violation of said legislation and Applicable Requirements, including but not limited to offering a bribe or making a facilitation payment to any person or being involved in laundering the proceeds of crime;&nbsp;</p>
                                    </li>
                                    <li>
                                        <p>that it will use its reasonable endeavours to ensure that its activities in connection with or relating to its obligations under this Agreement will put the other party in breach of said legislation and Applicable Requirements;&nbsp;</p>
                                    </li>
                                    <li>
                                        <p>that it will ensure that it has and maintains throughout the duration of this Agreement appropriate internal policies and procedures within its organisation to prevent money laundering, bribery by its workforce and other people under its control.&nbsp;</p>
                                    </li>
                                </ol>
                            </li>

                            <li>
                                <p>Each party undertakes that to the best of its knowledge and belief having conducted reasonable investigations, none of its, agents, sub-agents, employees and sub-contractors have done any act or thing in connection with, or relating to, its obligations under or in connection with this Agreement that would violate any anti-money laundering, anti-bribery or anti-corruption laws.&nbsp;</p>
                            </li>

                            <li>
                                <p>Each party undertakes that it will comply with all relevant legislation relating to the prevention of money laundering and terrorist financing, including, but not limited to, the UK Money Laundering Regulations 2007.&nbsp;</p>
                            </li>

                            <li>
                                <p>Both parties agree to act in accordance with and comply with the provisions of the Data Protection Act&nbsp;2018&nbsp;(the &quot;Act&quot;) the General Data Protection Regulation (&quot;GDPR&quot;) as amended from time to time.&nbsp;</p>
                            </li>

                            <li>
                                <p>Both parties warrant that they have acquired and will continue to acquire Personal Data (as defined in the Act) of customers lawfully and fairly and have the rights to process them and transfer them to each other.&nbsp;</p>
                            </li>

                            <li>
                                <p>Both parties warrant and undertake that they have and will at all times have whilst this Agreement is in force appropriate technical and organisational measures in place to protect Personal Data against unauthorised or unlawful processing and against accidental loss or destruction of, or damage to, personal data and that they have taken all reasonable steps to ensure the reliability of any of their staff which will have access to personal data.&nbsp;</p>
                            </li>

                            <li>
                                <p>A person who is not a party to this Agreement may not enforce any of its terms under the Contracts (Rights of Third parties) Act 1999.&nbsp;</p>
                            </li>

                            <li>
                                <p>Neither You nor We may assign any of the rights under this Agreement or this Agreement itself without the prior written consent of the other party.&nbsp;</p>
                            </li>

                            <li>
                                <p>The exclusions and limitations in this Agreement apply only to the extent that they are permitted by law.&nbsp;</p>
                            </li>

                            <li>
                                <p>This Agreement and the Order Forms constitute the whole and entire agreement between you and us.&nbsp;</p>
                            </li>

                            <li>
                                <p>The Agreement is governed by the laws and England and Wales and you and we both submit to the exclusive jurisdiction of the English Courts in respect of all disputes that arise between you and us.&nbsp;</p>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>

            <div v-else-if="document == 'legal'" class="header">
                <h4 class="Title">Legal Agreement</h4>
                <div class="elPopupBody Paragraph2TC">
                    <h4>Agreement:</h4>
                    <ol>
                        <li>
                            <p>We are The Financial Technology Research Centre Limited incorporated and registered in England and Wales with company number 02484495 whose registered office is at 14 Halifax Court Fernwood Business Centre Newark Nottinghamshire NG24 3JP  ("we", "us", "our").</p>
                        </li>
                        <li>
                            <p>You are a financial adviser (you are the person signing this agreement) and provide financial and other advice to the general public ("you, "your"). You may also contract and/or employ living individuals to assist you by undertaking research, administration and other tasks associated with you giving advice to the general public ("Paraplanners").  You distribute the products and services of Product Providers and Insurers whose products and services ("Products and Services") are described on our PGPro research tool ("PG Pro") accessible via our website.</p>
                            <ol type="A">
                                <li>
                                    <p> It is your responsibility to make Paraplanners aware of and ensure they are validly licensed to use PG Pro by them signing the Agreement set out in Schedule 2 for their use of PGPro.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>Information about Product Providers, Insurers and their Products and Services is entered into PG Pro by us and Product Providers. We provide tools in PG Pro which allow you and Paraplanners (if applicable)  to undertake research and produce reports to assist you in deciding about the suitability and appropriateness of Products and Services for your customers.</p>
                        </li>
                        <li>
                            <p>This agreement sets out the terms on which we will allow you to access and use PG Pro.</p>
                        </li>
                        <li>
                            <p>We will provide the services set out in Order Forms ("the Services") to you.  Order Forms may be online and/or in paper form.</p>
                        </li>
                        <li>
                            <p>You and Paraplanners (if applicable) may access and use PG Pro, update data in it and produce reports from it about Products and Services but only within the terms of this Agreement.</p>
                        </li>
                        <li>
                            <p>You and your and Paraplanners (if applicable) must keep your login credentials for PG Pro secret and confidential.  Log-In credentials are for use with one living individual only.  You are not allowed to share your log-in credentials with other financial advisers, Paraplanners or any other person unless (i) we have expressly consented for you to do so and (ii) that use is for your own financial adviser practice, even if you work with other financial advisers and paraplanners. We will not be liable for misuse of your login credentials unless we have caused them to become public.  You will pay us additional licence fees that are due to us, if your log-in credentials are used for obtaining PG Pro data and information in connection with other financial adviser or paraplanning practices.  In the event that you have allowed your login credentials to be used by other financial advisers and paraplanner practices you indemnify us for all costs (including legal costs) we incur in recovering the additional licence fees we would have received had you not allowed your login credentials to be used by 3rd parties.</p>
                            <ol type="a">
                                <li>
                                    <p>Paraplanners must not use PGPro for any person who does not hold a paid-for, current and valid licence to use PGPro.  If they do, that is a fundamental breach of this Agreement and we terminate all licences you have with immediate effect as well as those of your Paraplanners if they do so.</p>
                                </li>
                            </ol>
                        </li>
                        <li><p>You will ensure your employees, Paraplanners, contractors and agents do not use PG Pro for any purpose other than to produce reports about Products and Services for use with your retail customers ("Purpose").  You are not allowed to use PG Pro for other any other purpose other than the Purpose.</p></li>
                        <li><p>You will ensure you and Paraplanners do not enter any information for any purpose contrary to law and/or regulation which includes but is not limited to uploading viruses, interfering with users or other persons using PG Pro, ensuring you do not enter pornographic, racist or comments that incite religious hatred or violence through use of PG Pro.</p></li>
                        <li>
                            <p>You will pay the Fees set out in the Order Forms and for any other additional services and products you purchase from us.  We may amend our Fees by giving you three months' notice in writing.  If you do not want to pay a fee increase you may terminate this Agreement by giving us three months' notice in writing.</p>
                            <ol type="a">
                                <li><p>In the event that login credentials are proven to have been used for by you or your Paraplanners for third parties who do not a paid for, valid and current licence to use PGPro, denying us licence fees, you indemnify us for the licence fees we have not charged due to your breach of our licence terms and all costs, liabilities (including legal costs) we incur in recovering the additional licence fees from you</p></li>
                            </ol>
                        </li>
                        <li><p>You may purchase additional services and products from us, if you do, we will provide you with a written quote ahead of providing the services.  You will accept additional services by countersigning and sending back to us the written quote we have sent to you or signing a new Order Form.</p></li>
                        <li><p>We will provide Services to you with reasonable care and skill. If we cannot perform the Services by the date we agree with you, we will agree with you a new date and time for delivery.</p></li>
                        <li><p>You will use your skill and judgement and your best endeavours to ensure that data you and Paraplanners enter into the reports you create using PG Pro for your customers is accurate and not misleading.</p></li>
                        <li><p>We may reuse data entered into PG Pro by you that is not identified by you as confidential and use it with other data we collect that we analyse and aggregate.  We offer this aggregated data to market participants to inform their businesses and improve the products and services on offer to the general public.</p></li>
                        <li><p>We try to provide PG Pro continuously but there may be times when it is unavailable for planned and unplanned maintenance. We will use all reasonable endeavours to resolve any maintenance issues as soon as possible.</p></li>
                        <li><p>If you request that we carry out extra work or provide additional services not set out in this Agreement or an Order Form, we will charge you at our usual hourly rates.</p></li>
                        <li><p>We may terminate this Agreement immediately upon written notice to you upon the occurrence of a material breach of this Agreement (and if such breach is capable of remedy, which has not been remedied within 30 days of written notice to the party of the breach).</p></li>
                        <li><p>You may terminate your access to PG Pro  by giving us up to 90 days written notice such notice to take effect on the next anniversary of  your signing of the Order Form associated with this Agreement.</p></li>
                        <li><p>Subject to clause 18, and after the Term, this Agreement will continue for a subsequent term of 12 months, unless we agree otherwise.</p></li>
                        <li><p>After the Term, this Agreement will continue for a subsequent term of 12 months, unless we agree otherwise.</p></li>
                        <li>
                            <p>Neither you nor we exclude or limit liability to the other for:</p>
                            <ol type="a">
                                <li><p>fraud or fraudulent misrepresentation;</p></li>
                                <li><p>death or personal injury caused by negligence;</p></li>
                                <li><p>a breach of any obligations implied by section 12 of the Sale of Goods Act 1979 or section 2 of the Supply of Goods and Services Act 1982; or</p></li>
                                <li><p>any matter in respect of which it would be unlawful for the parties to exclude liability for respectively.</p></li>
                            </ol>
                        </li>
                        <li>
                            <p>Subject to clause 21, we shall not in any circumstances be liable whether in contract (by way of indemnity or otherwise), tort (including for negligence and breach of statutory duty howsoever arising), misrepresentation (whether innocent or negligent), restitution or otherwise, for:</p>
                            <ol type="a">
                                <li><p>any consequential or indirect loss or damage; or</p></li>
                                <li><p>loss of profits, business, business opportunities, revenue, turnover, reputation, goodwill, loss or corruption of data or information, anticipated savings or wasted expenditure (including management time) (all however so arising).</p></li>
                            </ol>
                        </li>
                        <li><p>Subject to clause 21, our total aggregate liability in contract (by way of indemnity or otherwise), tort (including negligence and breach of statutory duty howsoever arising), misrepresentation (whether innocent or negligent), restitution or otherwise, arising in connection with the performance or contemplated performance of this Agreement or any collateral contract shall in all circumstances be limited to the aggregate licence fees paid by you to us in a year.</p></li>
                        <li>
                            <p>In performing its duties under this Agreement, each party will comply with all applicable anti-bribery and anti-corruption laws and Applicable Requirements. In particular, each party hereby acknowledges and agrees:</p>
                            <ol>
                                <li><p>to comply with the UK Bribery Act 2010 and all anti-money laundering legislation and Applicable Requirements and that it shall not engage in any activity, practice or conduct which could be construed as a violation of said legislation and Applicable Requirements, including but not limited to offering a bribe or making a facilitation payment to any person or being involved in laundering the proceeds of crime;</p></li>
                                <li><p>that it will use its reasonable endeavours to ensure that its activities in connection with or relating to its obligations under this Agreement will put the other party in breach of said legislation and Applicable Requirements;</p></li>
                                <li><p>that it will ensure that it has and maintains throughout the duration of this Agreement appropriate internal policies and procedures within its organisation to prevent money laundering, bribery by its workforce and other people under its control.</p></li>
                            </ol>
                        </li>
                        <li><p>Each party undertakes that to the best of its knowledge and belief having conducted reasonable investigations, none of its, agents, sub-agents, employees and sub-contractors have done any act or thing in connection with, or relating to, its obligations under or in connection with this Agreement that would violate any anti-money laundering, anti-bribery or anti-corruption laws.</p></li>
                        <li><p>Each party undertakes that it will comply with all relevant legislation relating to the prevention of money laundering and terrorist financing, including, but not limited to, the UK Money Laundering Regulations 2007 and the Money Laundering, Terrorist Financing and Transfer of Funds (Information on the Payer) Regulations 2017.</p></li>
                        <li><p>Both parties agree to act in accordance with and comply with the provisions of the Data Protection Act 2018 and UKGDPR (the "Act") as amended from time to time.</p></li>
                        <li><p>Both parties warrant that they have acquired and will continue to acquire Personal Data (as defined in the Act) of customers lawfully and fairly and have the rights to process them and transfer them to each other.</p></li>
                        <li><p>Both parties warrant and undertake that they have and will at all times have whilst this Agreement is in force appropriate technical and organisational measures in place to protect Personal Data against unauthorised or unlawful processing and against accidental loss or destruction of, or damage to, personal data and that they have taken all reasonable steps to ensure the reliability of any of their staff which will have access to personal data.</p></li>
                        <li><p>A person who is not a party to this Agreement may not enforce any of its terms under the Contracts (Rights of Third parties) Act 1999.</p></li>
                        <li><p>Neither you nor we may assign any of the rights under this Agreement or this Agreement itself without the prior written consent of the other party.</p></li>
                        <li><p>The exclusions and limitations in this Agreement apply only to the extent that they are permitted by law.</p></li>
                        <li><p>This Agreement and the Order Forms constitute the whole and entire agreement between you and us.</p></li>
                        <li><p>The Agreement is governed by the laws and England and Wales and you and we both submit to the exclusive jurisdiction of the English Courts in respect of all disputes that arise between you and us.</p></li>
                    </ol>

                    <h4>Schedule 1</h4>
                    <h4>Order Form</h4>
                    <p>The Term: 12 months from the date of signing of this Agreement.</p>
                    <p>Service Title: Protection Guru Pro Research System</p>
                    <p>Service Description: PG Pro Software delivered online, FTRC online support available 9am to 5pm on working days</p>
                    <p>Data: As specified in the technical specification as varied from time to time, which can be viewed at <a href="https://www.ProtectionGuru.co.uk" target="_blank">www.ProtectionGuru.co.uk</a>.</p>
                    <p>Site: www. ProtectionGuru.co.uk</p>
                    <p>User Contact:  Adam Higgs</p>
                    <p>Fees Payable: <b>&#163;40 per month per user plus VAT</b>. These will be payable via the 3rd party payment solution that is located on ProtectionGuru.co.uk.</p>

                    <h4>Schedule 2</h4>
                    <h4>Terms and Conditions for Paraplanners contracted to Financial Advisers to use PG Pro</h4>
                    <ol>
                        <li><p>You are a paraplanner and provide services in connection with the work of financial advisers.</p></li>
                        <li><p>Data about Product Providers, Insurers and their Products and Services is entered into PG Pro by us and Product Providers. We provide tools in PG Pro which allow you to undertake research and produce reports to assist Advisers with whom you work to decide about the suitability and appropriateness of Products and Services for their customers.</p></li>
                        <li><p>This agreement sets out the terms on which we will allow you to access and use PG Pro.</p></li>
                        <li><p>You may access and use PG Pro, update data in it and produce reports from it about Products and Services but only within the terms of this Agreement.</p></li>
                        <li><p>You must keep your login credentials for PG Pro secret and confidential.  Log-in credentials are for your use only.  You are not allowed to share your log-in credentials with other financial advisers, paraplanners or any other person unless (i) we have expressly consented for you to do so, that includes when you work with other financial advisers and other paraplanning practices.  All persons with whom you work must have paid-for, current and valid licences to use PGPro.</p></li>
                        <li><p>You will not use PG Pro for any purpose other than to produce reports about Products and Services for use with retail customers ("Purpose").</p></li>
                        <li><p>You must not use PGPro for Advisers and any person who does not hold a paid-for, current and valid licence to use PGPro.  If you do, that is a fundamental breach of this Agreement and we shall terminate all licences you have with immediate effect, as well as those of the Advisers you assist.</p></li>
                        <li><p>You will ensure you do not enter any information for any purpose contrary to law and/or regulation which includes but is not limited to uploading viruses, interfering with users or other persons using PG Pro, ensuring you do not enter pornographic, racist or comments that incite religious hatred or violence through use of PG Pro.</p></li>
                        <li><p>You will use your skill and judgement and your best endeavours to ensure that data you enter the reports you create using PG Pro for your customers are accurate and not misleading. For our part, we will use reasonable care and skill in providing our services to you.</p></li>
                        <li><p>We may reuse data entered into PG Pro by you that is not identified by you as confidential and use it with other data we collect that we analyse and aggregate.  We offer this aggregated data to market participants to inform their businesses and improve the Products and Services on offer to the general public.</p></li>
                        <li><p>We try to provide PG Pro continuously but there may be times when it is unavailable for planned and unplanned maintenance. We will use all reasonable endeavours to resolve any maintenance issues as soon as possible.</p></li>
                        <li>
                            <p>We may terminate your access to PG Pro with immediate effect if you breach the terms of this Agreement. Neither you nor we exclude or limit liability to the other for:</p>
                            <ol type="a">
                                <li><p>fraud or fraudulent misrepresentation;</p></li>
                                <li><p>death or personal injury caused by negligence;</p></li>
                                <li><p>a breach of any obligations implied by section 12 of the Sale of Goods Act 1979 or section 2 of the Supply of Goods and Services Act 1982; or</p></li>
                                <li><p>any matter in respect of which it would be unlawful for the parties to exclude liability for respectively.</p></li>
                            </ol>
                        </li>
                        <li>
                            <p>Subject to clause 12, we shall not in any circumstances be liable whether in contract (by way of indemnity or otherwise), tort (including for negligence and breach of statutory duty howsoever arising), misrepresentation (whether innocent or negligent), restitution or otherwise, for:</p>
                            <ol type="a">
                                <li><p>any consequential or indirect loss or damage; or</p></li>
                                <li><p>loss of profits, business, business opportunities, revenue, turnover, reputation, goodwill, loss or corruption of data or information, anticipated savings or wasted expenditure (including management time) (all however so arising).</p></li>
                            </ol>
                        </li>
                        <li><p>Neither you nor we may assign any of the rights under this Agreement or this Agreement itself without the prior written consent of the other party.</p></li>
                        <li><p>The exclusions and limitations in this Agreement apply only to the extent that they are permitted by law</p></li>
                        <li><p>This Agreement is governed by the laws and England and Wales and you and we both submit to the exclusive jurisdiction of the English Courts in respect of all disputes that arise between you and us.</p></li>
                    </ol>
                </div>

            </div>
            <div v-if="!readOnly" class="navigation" style="display:inline-flex;float:right">
                <el-button type="success" class="elConsent" :disabled="isLoading" v-on:click.stop="Accept">I consent</el-button>
            </div>
            <div v-else class="navigation" style="display:inline-flex;float:right">
                <el-button type="success" class="elConsent" v-on:click.stop="$emit('terms-closed')">Close</el-button>
            </div>
        </div>
    </div>

</template>
<style scoped>

    .header {
        border: none;
    }

        .header .Options {
            padding-top: 15px;
        }

    .Title {
        margin-bottom: 7px;
        margin-top: 10px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 34px;
        align-items: center;
        letter-spacing: -0.02em;
        color: #0F172A;
        padding-left: 15px;
        padding-right: 15px;
    }

    .el-container {
        background-color: #E0E0E0 !important;
    }

    .elConsent {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        position: static;
        width: 182px;
        height: 48px;
        left: 370px;
        top: 0px;
        background-color: #059669 !important;
        border-radius: 8px;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0px 14px;
        /**/
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #FFFFFF;
    }

    .elPopup {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
        padding-bottom: 48px;
        position: relative;
        width: 744px;
        height: 636px;
        background: #FFFFFF;
        border-radius: 8px;
    }

    .elPopupBody {
        display: inline-block;
        overflow-y: auto;
        width: 720px;
        height: 525px;
        margin-bottom: 16px;
    }

    .Paragraph2TC {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #191D23;
        padding-left: 15px;
        padding-right: 15px;
    }
</style>
<script>
    import $ from 'jquery';

    export default {
        name: 'TermsConditions',
        data: function () {
            return {
                loading: false,
                saving: false,
            }
        },
        computed: {
            userDetails: function () {
                return this.UserService.getUserDetails();
            },
            isLoading: function () {
                return this.UserService.isLoading() || this.saving;
            },
        },
        inject: ['UserService'],
        props: {
            readOnly: Boolean,
            document: String,
        },
        methods: {
            AcceptTandCs: function () {
                var thisControl = this;
                thisControl.saving = true;
                $.ajax({
                    type: 'GET',
                    url: '/api/AcceptTermsAndConditions',
                    contentType: 'application/json',
                    success: function () {
                        thisControl.loadUserDetails();
                        thisControl.saving = false;
                    },
                });
            },
            Accept: function () {
                if (this.document == 'terms') {
                    this.AcceptTandCs();
                } else if (this.document == 'legal') {
                    this.AcceptLegalAgreement();
                }
            },
            AcceptLegalAgreement: function () {
                var thisControl = this;
                thisControl.saving = true;
                $.ajax({
                    type: 'GET',
                    url: '/api/AcceptLegalAgreement',
                    contentType: 'application/json',
                    success: function () {
                        thisControl.loadUserDetails();
                        thisControl.saving = false;
                    },
                });
            },
            loadUserDetails: function () {
                this.UserService.update();
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<script setup>
import { computed, ref, watch } from 'vue';
import ToolSelector from './ToolSelector.vue';
import { useQuery, useMutation } from '@tanstack/vue-query';
import axios, { HttpStatusCode } from 'axios';
import iPipelineLogo from '../../assets/images/iPipelineLogo.png';
import ftrcLogo from '../../assets/images/FTRC-bubbles.png';
import underwriteMeLogo from '../../assets/images/underWriteMeLogo3.png';
import FilterMappings from './FilterMappings.vue';
import { ElDivider } from 'element-plus';
import { ElButton, ElMessage } from 'element-plus';

const toolReference = ref('');
const selectedService = ref('');

const queryKey = computed(() => [`tool-config`, selectedService.value, toolReference.value]);
const queryEnabled = computed(() => !!toolReference.value && !!selectedService.value);

const { data: toolConfig, isFetched, isFetching } = useQuery({
  queryFn: () => axios.get(`api/Configuration/GetToolConfiguration/${toolReference.value}`)
    .then(response => response.data),
  queryKey: queryKey,
  enabled: queryEnabled,
});

const mappingsQueryKey = computed(() => ['tool-mappings', selectedService.value, toolReference.value]);
const tmappingsQueryEnabled = computed(() => selectedService.value !== 'API' && !!toolReference.value);

const apiMappingsQueryKey = computed(() => ['api-tool-mappings', toolReference.value]);
const apiMappingsQueryEnabled = computed(() => selectedService.value === 'API' && !!toolReference.value);

const { data: mappings } = useQuery({
  queryKey: mappingsQueryKey,
  enabled: tmappingsQueryEnabled,
  queryFn: () => {
    return axios(`api/Configuration/ListFilterMappings/${selectedService.value}/${toolReference.value}`).then(res => res.data)
  },
});

const { data: filters } = useQuery({
  queryKey: apiMappingsQueryKey,
  enabled: apiMappingsQueryEnabled,
  queryFn: () => axios(`api/Configuration/ListProductFilters/${toolReference.value}`).then(res => res.data),
});

const isOptionChecked = (apiFilters, optionReference) => {
  return apiFilters.includes(optionReference);
};

const providers = ref();

watch(filters, (newValue) => {
  providers.value = newValue;
});

// Function to update data when a checkbox is changed
const updateData = (provider, optionReference) => {
  let providerFilterOptions =  [...provider.providerFilterOptions];

  if (providerFilterOptions.includes(optionReference)) {
    providerFilterOptions = providerFilterOptions.filter((ref) => ref !== optionReference);
  } else {
    providerFilterOptions = [...providerFilterOptions, optionReference];
  }

  providers.value = providers.value.map(p =>
    p.providerKey === provider.providerKey
      ? {
        ...provider,
        providerFilterOptions,
      }
      : p);

};

const { mutate: saveFilterMapping, isLoading: isSaving } = useMutation({
  mutationFn: async () => {
    try {
      const response = await axios.post(
        `api/Configuration/SaveProductFilters/${toolReference.value}`,
        JSON.stringify(providers.value),
        {
          headers: {
            'Accept': 'application/json, text/plain',
            'Content-Type': 'application/json;charset=UTF-8',
          },
        }
      );

      if (response.status !== HttpStatusCode.Ok) {
        throw new Error(response.statusText);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  },
  onSuccess: () => {
    ElMessage.success('Mapping saved.');
  },
  onError: () => {
    ElMessage.error('Mapping could not be saved');
  }
});

</script>
<template>
  <div class="container">
    <h1>Filters Configuration</h1>
    <div class="logos-container">
      <div @click="selectedService = 'IPIPELINE'" :class="{ 'logo': true, 'active': selectedService === 'IPIPELINE' }">
        <img alt="iPipeline" :src="iPipelineLogo" />
      </div>
      <div @click="selectedService = 'UNDERWRITEME'" :class="{ 'logo': true, 'active': selectedService === 'UNDERWRITEME' }">
          <img alt="iPipeline" :src="underwriteMeLogo" />
      </div>
      <div @click="selectedService = 'API'" :class="{ 'logo': true, 'active': selectedService === 'API' }">
        <img alt="FTRC" :src="ftrcLogo" />
      </div>
    </div>
    <div v-if="!!selectedService">
      <p class="tip">
        Select a tool for setting up the filters
        <ToolSelector v-model="toolReference" />
      </p>
      <div v-if="isFetching">
        <h4>Loading...</h4>
      </div>
      <div v-if="!!toolConfig?.tool.providerFilters?.length">
        <div v-if="selectedService === 'API'">
          <div class="list" v-for="provider in providers" :key="provider.providerKey">
            <div class="row">
              <div class="flex">
                <div class="title">{{ provider.providerDisplayName }}</div>
                <div class="subtitle">
                  <span>{{ provider.providerKey }}</span>
                </div>
              </div>
              <div class="row space-between gap">
                <div v-for="filter in toolConfig.tool.providerFilters" :key="filter.providerFilterReference">
                  <b>{{ filter.name }}</b>
                  <div v-for="option in filter.providerFilterOptions" :key="option.reference">
                      <input type="checkbox"
                             :checked="isOptionChecked(provider.providerFilterOptions, option.reference)"
                             @change="updateData(provider, option.reference)" />
                    {{ option.option }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-left: 25rem; margin-top: 1rem; margin-bottom: 0.5rem;">
            <ElButton type="primary" :loading="isSaving" @click="saveFilterMapping">Save</ElButton>
          </div>
        </div>
        <div  v-else-if="selectedService === 'IPIPELINE'">
          <div class="list" v-for="filter in toolConfig.tool.providerFilters" :key="filter.providerFilterReference">
            <div class="title">{{ filter.name }}</div>
            <div class="subtitle">{{ filter.description }}
              <div v-for="option in filter.providerFilterOptions" :key="option.reference">
                <FilterMappings :service="selectedService" :tool-reference="toolReference" :filter="filter"
                  :mappings="mappings" :option="option" />
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="list" v-for="filter in toolConfig.tool.providerFilters" :key="filter.providerFilterReference">
            <div class="title">{{ filter.name }}</div>
            <div class="subtitle">{{ filter.description }}
              <div v-for="option in filter.providerFilterOptions" :key="option.reference">
                <FilterMappings :service="selectedService" :tool-reference="toolReference" :filter="filter"
                  :mappings="mappings" :option="option" />
              </div>
            </div>
          </div>
        </div>
        <ElDivider class="divider" />
      </div>
      <div v-else-if="isFetched">
        <h4>No filters found for {{ toolReference }}</h4>
      </div>
    </div>

  </div>
</template>
<style scoped>
.container {
  padding: 0 1rem;
}

.logos-container {
  display: flex;
  gap: 1rem;
}

.logo {
  border: 1px solid #3c3c3c;
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 8rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  box-sizing: border-box;
  cursor: pointer;
}

.logo.active {
  border: 3px solid var(--el-color-primary);
}

.logo img {
  width: 100%;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: #f5f5f5;
  padding: 1rem;
}

.divider {
  margin: 0;
}

.subtitle .mapping {
  color: var(--el-color-primary);
  text-decoration: underline;
  cursor: pointer;
}

.title {
  font-weight: bold;
}
.row {
  display: flex;
  flex-direction: row;
}

.flex {
  flex: 1
}

.space-between {
  justify-content: space-between;
}

.gap {
  gap: 1rem;
}
</style>
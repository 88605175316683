<template>
    <div>
        <fieldset class="selectList" v-if="Templates !== null">
            <legend>
                Existing Templates
            </legend>
            <table class="analysisStyles">
                <thead>
                    <tr>
                        <th>
                            Template Name
                        </th>
                        <th>
                            Has Global Template
                        </th>
                        <th>
                            Has Child Require
                        </th>
                        <th>
                            Date Created
                        </th>
                        <th>Options</th>
                    </tr>
                </thead>
                <tbody v-if="Templates.length == 0">
                    <tr>
                        <td colspan="3">No Templates Found</td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr v-for="template in Templates" :key="template.dataTableReference">
                        <td>{{template.templateName}}</td>
                        <td>{{template.isGlobal}}</td>
                        <td>{{template.childRequire}} </td>
                        <td>{{displayDate(template.createdOn)}}</td>
                        <td>
                            <button class="CustomComparionButtons" v-on:click.stop="LoadTemplate(template, false)">Load</button>
                            <button v-if="UserDetails.canShareTemplate" class="CustomComparionButtons" :disabled="GlobalTemplates.includes(template.templateName)" @click="LoadTemplate(template, true)">Add to Global Template</button>
                            <button v-if="template.isGlobal == 'No'" class="CustomComparionButtons" @click="DeleteTemplate(template)">Delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </fieldset>
        <fieldset class="navigation analysisbottomMargin50" v-if="Templates !== null">
            <button v-on:click.stop="$emit('back')">Back</button>
            <button v-on:click.stop="$emit('select-no-template')">New Comparison</button>
        </fieldset>
        <loading-control v-else :loading-message="'Loading Comparisons...'" />
        <child-confirm v-if="IsShowingChild" v-on:returntopolicydetails="ReturnToPolicyClicked()" v-on:childconfirm-continue="$emit('template-selected', SelectedTemplate); IsShowingChild = false;" />

    </div>
</template>
<style type="text/css" scoped>
    .analysisStyles button {
        max-width: 80px;
        display: inline-block;
        margin-right: 10px;
    }

        .analysisStyles button:last-child {
            margin-right: 0px;
        }

    .analysisStyles th:last-child, .analysisStyles td:last-child {
        width: 200px;
    }
</style>
<script>import $ from 'jquery';
    import ChildConfirm from "./ChildConfirm.vue";
    import LoadingControl from './LoadingControl.vue';

    export default {
        name: 'ComparisonTemplates',
        props: ["userSelection", "userDetails"],
        components: {
            LoadingControl, ChildConfirm
        },
        data: function () {
            return {
                IsShowingChild: false,
                Templates: null,
                SelectedTemplate: null,
                GlobalTemplates: [],
                UserTemplateNames: []
            }
        },
        methods: {
            displayDate: function (d) {
                if (d === null)
                    return "-";
                return d.substr(8, 2) + "/" + d.substr(5, 2) + "/" + d.substr(0, 4);
            },
            LoadTemplate: function (template, isCopyToGlobal) {
                var thisControl = this;

                $.ajax({
                    type: 'GET',
                    url: 'ComparisonTemplate/' + this.UserSelection.tool.toolReference + "/" + template.comparisonReference,
                    data: { isGlobal: template.isGlobal },
                    contentType: 'application/json',
                    success: function (fullTemplate) {
                        if (isCopyToGlobal != true) {
                            thisControl.SelectedTemplate = fullTemplate;

                            var childFeature = fullTemplate.selection.features.filter(function (f) {
                                return fullTemplate.selection.extendedFeatures.some(function (ef) {
                                    return f.featureReference == ef.featureReference && ef.weight > 0
                                })
                            });

                            childFeature = childFeature.filter(function (f) {
                                return f.requiresChildrensDetails === true;
                            });

                            if (childFeature !== null && childFeature.length > 0) {
                                thisControl.UserSelection.hasChildRequire = 'Yes';
                                fullTemplate.selection.hasChildRequire = 'Yes';
                            }
                            else {
                                thisControl.UserSelection.hasChildRequire = 'No';
                                fullTemplate.selection.hasChildRequire = 'No';
                            }

                            if (fullTemplate.selection.analysisType !== "DataTables" && fullTemplate.selection.children.length === 0
                                && childFeature !== null && childFeature.length > 0
                                && fullTemplate.selection.extendedFeatures.filter(function (ef) { return ef.weight > 0; }).filter(function (sef) { return sef.featureReference === childFeature[0].featureReference }).length > 0) {
                                thisControl.IsShowingChild = true;
                            }
                            else
                                thisControl.$emit('template-selected', fullTemplate);
                        }
                        else
                            thisControl.AddToGobalTemplate(fullTemplate);
                    },
                    error: function (xhr) {
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                        //templates not working so lets skip this step...
                        thisControl.$message.error("Error getting template: " + xhr.responseText);
                    }
                });
            },
            ReturnToPolicyClicked: function () {
                this.$emit('backtopolicy-selected');
            },
            DeleteTemplate: function (template) {
                var thisControl = this;
                this.Templates = null;
                $.ajax({
                    type: 'DELETE',
                    url: 'ComparisonTemplate/' + this.UserSelection.tool.toolReference + "/" + template.comparisonReference,
                    data: { isGlobal: template.isGlobal },
                    success: function () {

                        thisControl.DownloadTemplates(function () { });
                    },
                    error: function (xhr) {
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                        //templates not working so lets skip this step...
                        thisControl.$message.error("Error deleting template: " + xhr.responseText);
                        thisControl.DownloadTemplates(function () { });
                    }
                });
            },
            DownloadTemplates: function (actionIfEmpty) {
                var thisControl = this;
                $.ajax({
                    type: 'GET',
                    url: 'ComparisonTemplate/' + this.UserSelection.tool.toolReference,
                    contentType: 'application/json',
                    success: function (Templates) {
                        thisControl.Templates = Templates;

                        thisControl.DownloadGlobalTemplates();

                        if (thisControl.Templates.length == 0) {
                            actionIfEmpty()
                        }
                    },
                    error: function (xhr) {
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                        //templates not working so lets skip this step...
                        actionIfEmpty();
                        //alert("Error User Details: " + xhr.responseText);
                    }
                });
            },
            RefreshTemplates: function () {
                this.DownloadTemplates(function () { });
            },
            AddToGobalTemplate: function (template) {
                var thisControl = this;

                $.ajax({
                    type: 'PUT',
                    url: "/ComparisonTemplate/" + this.UserSelection.tool.toolReference,
                    data: JSON.stringify({
                        templateName: template.templateName,
                        toolKey: thisControl.UserSelection.tool.toolReference,
                        selection: template.selection,
                        isGlobal: true,
                    }),
                    contentType: 'application/json',
                    success: function () {
                        thisControl.DownloadGlobalTemplates();

                        thisControl.$message.error("Template added to Global successfully");
                    },
                    error: function (xhr) {
                        thisControl.$message.error("Error adding template to Global:" + xhr.responseText);
                    },
                });
            },
            DownloadGlobalTemplates: function () {
                var thisControl = this;
                $.ajax({
                    type: 'GET',
                    url: 'ComparisonTemplate/' + this.UserSelection.tool.toolReference,
                    data: {
                        isGlobal: true,
                    },
                    contentType: 'application/json',
                    success: function (GTemplates) {
                        GTemplates.forEach(function (tem) { thisControl.GlobalTemplates.push(tem.templateName) });
                        thisControl.Templates.forEach(function (tem) { thisControl.UserTemplateNames.push(tem.templateName) });
                        GTemplates.forEach(function (temp) { if (!thisControl.UserTemplateNames.includes(temp.templateName)) thisControl.Templates.push(temp); });
                    },
                    error: function (xhr) {
                        thisControl.GlobalTemplates = [];
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                    }
                });
                if (thisControl.GlobalTemplates == null)
                    thisControl.GlobalTemplates = [];
            }
        },
        created: function () {
            var thisControl = this;

            thisControl.DownloadTemplates(function () {
                thisControl.$emit('skip-templates');
            });
        },
        computed: {
            UserSelection: function () { return this.userSelection; },
            UserDetails: function () { return this.userDetails; },
            maxProviders: {
                get: function () {
                    if (this.UserSelection.analysisType == "DataTables")
                        return this.UserDetails.maxProvidersPerDataTable;
                    else
                        return this.UserDetails.maxProvidersPerComparison;

                }
            }
        }
    }</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<template>
    <div class="ModalBackground" style="display:block;">
        <div class="ModalBackGroundPanel">
            <div class="header" style="border:none">
                <h4 class="Title contctusHeader">Can't find your policy? Tell us about it</h4>
                <div v-if="IsEmailSent">
                    <h5>
                        <span> {{ this.Message }}</span>
                    </h5>
                </div>
                <el-form name="ruleForm" v-if="!IsEmailSent" :model="MissingLegacyPolicy" :rules="rules" ref="ruleForm">
                    <el-form-item class="label" label="First name" prop="givenName">
                        <el-input v-model="MissingLegacyPolicy.givenName"></el-input>
                    </el-form-item>

                    <el-form-item class="label" label="Second name" prop="surname">
                        <el-input v-model="MissingLegacyPolicy.surname"></el-input>
                    </el-form-item>

                    <el-form-item class="label" label="Email address" prop="emailAddress">
                        <el-input v-model="MissingLegacyPolicy.emailAddress"></el-input>
                    </el-form-item>

                    <el-form-item class="label" label="Organisation name" prop="organisationName">
                        <el-input v-model="MissingLegacyPolicy.organisationName"></el-input>
                    </el-form-item>

                    <el-form-item class="label" label="Contact number" prop="contactNumber">
                        <el-input v-model="MissingLegacyPolicy.contactNumber"></el-input>
                    </el-form-item>

                    <el-form-item class="label" label="Existing policies insurer name" prop="policyInsurerName">
                        <el-input v-model="MissingLegacyPolicy.policyInsurerName"></el-input>
                    </el-form-item>

                    <el-form-item class="label" label="Product Name (if known)" prop="policyProductName">
                        <el-input v-model="MissingLegacyPolicy.policyProductName"></el-input>
                    </el-form-item>

                    <el-form-item class="labels" label="When was the policy taken out (approx. if not known)">
                        <el-form-item class="labels" label="Year" prop="legacyPolicyYear" style="width: 130px; margin-right: 10px">
                            <el-select placeholder="Select Year" v-model="MissingLegacyPolicy.legacyPolicyYear">
                                <el-option v-for="(year,i) in years" :key="i" :value="year" :label="year"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form-item>

                    <el-form-item class="label" label="Any further information?" prop="anyInformation">
                        <el-input type="textarea" v-model="MissingLegacyPolicy.anyInformation"></el-input>
                    </el-form-item>

                    <el-form-item v-if="false" class="label" label="Upload policy documents (if available)" prop="policyUploadDocument">
                        <el-upload drag multiple :limit="3"
                                   v-model:file-list="MissingLegacyPolicy.policyUploadDocument"
                                   :auto-upload="false"
                                   :on-change="beforeFileUpload"
                                   :on-exceed="handleExceed"
                                   accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf">
                            <div class="el-upload__text">
                                Click or drag files to this area to upload.
                                <br />
                                You can upload up to 3 files.
                            </div>
                        </el-upload>
                    </el-form-item>

                    <el-form-item class="navigation">
                        <el-button type="default" v-on:click.stop="$emit('back')">Cancel</el-button>
                        <el-button type="success" @click="SubmitPolicyForm('ruleForm')">Submit</el-button>
                    </el-form-item>
                </el-form>
                <div v-if="IsEmailSent">
                    <el-form-item class="navigation">
                        <el-button type="default" v-on:click.stop="$emit('back')">Close</el-button>
                    </el-form-item>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    .el-form-item__label {
        color: none !important;
        font-size: 14px !important;
    }

    .label {
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 14px !important;
        line-height: 24px !important;
        color: #0F172A !important;
    }

    .el-upload-dragger {
        width: 480px !important;
    }
</style>
<style scoped>
    .el-upload-dragger {
        width: 480px !important;
    }

    .el-form-item__content {
        display: flex !important;
        flex-wrap: nowrap !important;
        align-items: center !important;
    }

    .el-form-item__label {
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 24px !important;
        color: #0F172A !important;
    }

    .el-form-item {
        display: block !important;
        margin-bottom: 18px !important;
    }

    .navigation {
        margin-top: 5px !important;
        display: block;
        float: right;
    }

    .el-button--success, .el-button--success:focus, .el-button--success:hover {
        position: static !important;
        height: 48px !important;
        background: #1E228C !important;
        border-radius: 8px !important;
        border: none !important;
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        text-align: center !important;
        color: white !important;
        width: 100px !important;
    }

    .el-button--default, .el-button--default:focus, .el-button--default:hover {
        position: static !important;
        height: 48px !important;
        background: #FFFFFF !important;
        border: 1px solid #000000 !important;
        box-sizing: border-box !important;
        border-radius: 8px !important;
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        color: #000000 !important;
        width: 100px !important;
    }

    .inputTextArea {
        width: 500px !important;
        height: 66px !important;
        left: 32px !important;
        top: 525px !important;
        background: #FFFFFF !important;
        border: 1px solid #E0E0E0 !important;
        box-sizing: border-box !important;
        border-radius: 8px !important;
    }

    .input {
        width: 500px !important;
        height: 34px !important;
        left: 32px !important;
        top: 90px !important;
        background: #FFFFFF !important;
        border: 1px solid #E0E0E0 !important;
        box-sizing: border-box !important;
        border-radius: 8px !important;
        margin-bottom: 20px !important;
        padding-left: 10px !important;
    }

    .label {
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 14px !important;
        line-height: 24px !important;
        color: #0F172A !important;
    }

    .ModalBackGroundPanel {
        position: relative !important;
        width: 500px !important;
        background: #FFFFFF !important;
        border-radius: 8px !important;
        max-height: 800px !important;
        padding-top: 0px !important;
        overflow: scroll !important;
    }

    .contctusHeader {
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 19px !important;
        line-height: 23px !important;
        letter-spacing: -0.02em !important;
        color: #2D2D2D !important;
    }
</style>

<script>
    import $ from 'jquery';

    export default {
        components: {},
        name: 'FindYourPolicy',
        props: ["userDetails", "missingLegacyPolicy"],
        data: function () {
            return {
                MissingLegacyPolicy: {
                    givenName: '',
                    surname: '',
                    emailAddress: '',
                    contactNumber: '',
                    organisationName: '',
                    policyInsurerName: '',
                    policyProductName: '',
                    legacyPolicyYear: '',
                    anyInformation: '',
                    policyUploadDocument: [],
                },
                IsEmailSent: false,
                Message: "",
                years: [],
                ruleForm: {
                    givenName: '',
                    surname: '',
                    emailAddress: '',
                    organisationName: '',
                    contactNumber: '',
                    policyInsurerName: '',
                    policyProductName: '',
                },
                rules: {
                    givenName: [
                        { required: true, message: 'Please input First name', trigger: 'change' }
                    ],
                    surname: [
                        { required: true, message: 'Please input Second name', trigger: 'change' }
                    ],
                    emailAddress: [
                        { required: true, message: 'Please input Email address', trigger: 'change' }
                    ],
                    organisationName: [
                        { required: true, message: 'Please input Organisation name', trigger: 'change' }
                    ],
                    policyInsurerName: [
                        { required: true, message: 'Please input Existing policies insurer name', trigger: 'change' }
                    ],
                    legacyPolicyYear: [
                        { required: true, message: 'Please select Year', trigger: 'change' }
                    ]
                }
            }
        },
        methods:
        {
            IsDisabled: function () {
                if (this.MissingLegacyPolicy.givenName === null || this.MissingLegacyPolicy.givenName === "")
                    return true;
                else if (this.MissingLegacyPolicy.surname === null || this.MissingLegacyPolicy.surname === "")
                    return true;
                else if (this.MissingLegacyPolicy.emailAddress === null || this.MissingLegacyPolicy.emailAddress === "")
                    return true;
                else if (this.MissingLegacyPolicy.organisationName === null || this.MissingLegacyPolicy.organisationName === "")
                    return true;
                else if (this.MissingLegacyPolicy.policyInsurerName === null || this.MissingLegacyPolicy.policyInsurerName === "")
                    return true;
                else if (this.MissingLegacyPolicy.legacyPolicyYear === null || this.MissingLegacyPolicy.legacyPolicyYear === "")
                    return true;
                else
                    return false;
            },
            SubmitPolicyForm: function (formName) {
                var thisControl = this;
                const {
                    emailAddress,
                    contactNumber,
                    givenName,
                    surname,
                    organisationName,
                    policyInsurerName,
                    policyProductName,
                    legacyPolicyYear,
                    anyInformation,
                    policyUploadDocument: files
                } = thisControl.MissingLegacyPolicy;

                var data = new FormData();

                for (var file of files) {
                    data.append("file", file.raw);
                }

                data.append("givenName", givenName);
                data.append("surname", surname);
                data.append("emailAddress", emailAddress);
                data.append("contactNumber", contactNumber);
                data.append("organisationName", organisationName);
                data.append("policyInsurerName", policyInsurerName);
                data.append("policyProductName", policyProductName);
                data.append("legacyPolicyYear", legacyPolicyYear);
                data.append("anyInformation", anyInformation);

                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        $.ajax({
                            type: 'POST',
                            url: "/ContactUs/FindLegacyPolicySubmit/",
                            processData: false,
                            contentType: false,
                            data,
                            success: function (data) {
                                thisControl.IsEmailSent = true;
                                thisControl.Message = data.message;
                            },
                            error: function (xhr) {
                                thisControl.$message.error(xhr.responseText);
                            }
                        });
                    }
                    else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            beforeFileUpload: function (rawFile) {
                var thisControl = this;
                if (rawFile.raw.type !== 'application/pdf') {
                    thisControl.$message.error('File must be PDF format!')
                    return false
                }
                else if (rawFile.raw.size / 1024 / 1024 / 1024 / 1024 / 1024 > 5) {
                    thisControl.$message.error('File size can not exceed 5MB!')
                    return false
                }
                return true
            },
            handleExceed: function (files, uploadFiles) {
                var thisControl = this;
                thisControl.$message.warning(`The limit is 3, you selected ${files.length} files this time, add up to ${files.length + uploadFiles.length} totally`)
            }
        },
        created: function () {
            this.years = [];

            for (var i = 1985; i <= new Date().getFullYear(); i++)
                this.years.push(i);

            this.MissingLegacyPolicy.surname = this.UserDetails.surname;
            this.MissingLegacyPolicy.givenName = this.UserDetails.givenName;
            this.MissingLegacyPolicy.emailAddress = this.UserDetails.emailAddress;
            this.MissingLegacyPolicy.contactNumber = this.UserDetails.contactNumber;
            this.MissingLegacyPolicy.organisationName = this.UserDetails.organisationName;
        },
        computed: {
            UserDetails: function () { return this.userDetails }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
import { createWebHistory, createRouter } from 'vue-router';
import ToolSelectionPage from './pages/ToolSelectionPage.vue';
import PolicyDetailsPage from './pages/PolicyDetailsPage.vue';
import ExpressQuestionPage from './pages/ExpressQuestionPage.vue';
import CategorySelectionPage from './pages/CategorySelectionPage.vue';
import FeatureSelectionPage from './pages/FeatureSelectionPage.vue';
import TemplateSelectionPage from './pages/CompareCriticalIllnessPolicyPage.vue';
import ExpressQuestionsWithoutPricePage from './pages/ExpressQuestionsWithoutPricePage.vue';
import InfoVerificationPage from './pages/InfoVerificationPage.vue';
import AnalysisPage from './pages/AnalysisPage.vue';
import UnderwritingAnalysisPage from './pages/UnderwritingAnalysisPage.vue';
import DataTableToolSelection from './components/DataTableToolSelection.vue';
import DataTableSelection from './components/DataTableSelection.vue';
import ViewDataTables from './components/ViewDataTables.vue';

const routes = [
    { name: "ToolSelectionPage", path: '/ToolSelection', component: ToolSelectionPage },
    { name: "PolicyDetailsPage", path: '/PolicyDetails', component: PolicyDetailsPage },
    { name: "ExpressQuestionPage", path: '/ExpressQuestion', component: ExpressQuestionPage },
    { name: "CategorySelectionPage", path: '/CategorySelection', component: CategorySelectionPage },
    { name: "FeatureSelectionPage", path: '/FeatureSelection', component: FeatureSelectionPage },
    { name: "TemplateSelectionPage", path: '/TemplateSelection', component: TemplateSelectionPage },
    { name: "ExpressQuestionsWithoutPricePage", path: '/AdditionalInfo', component: ExpressQuestionsWithoutPricePage },
    { name: "InfoVerificationPage", path: '/InfoVerification', component: InfoVerificationPage },
    { name: "AnalysisPage", path: '/Analysis/:customerReference?/:analysisReferecnce?', component: AnalysisPage, props: true },
    { name: "UnderwritingAnalysisPage", path: '/UnderwritingAnalysis', component: UnderwritingAnalysisPage },
    { name: "DataTableToolSelection", path: '/DataTableToolSelection', component: DataTableToolSelection },
    { name: "DataTableSelection", path: '/DataTableSelection', component: DataTableSelection },
    { name: "ViewDataTables", path: '/ViewDataTables', component: ViewDataTables },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router;
<script setup>
import { ref, computed, inject, defineEmits } from "vue";
import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { ElInput, ElButton } from "element-plus";
import { useQuery } from "@tanstack/vue-query";
import axios from "axios";
import underWriteMeLogo from "../../assets/images/underWriteMeLogo3.png";

const userService = inject('UserService');

const queryClient = useQueryClient();

const { data: currentIntegration, isLoading: isLoadingCurrent } = useQuery({
  queryKey: ["underWriteMe-integration"],
  queryFn: () =>
    axios("api/Integration/GetUnderWriteMeIntegration").then((res) => res.data),
});

const { mutate: deleteUserIntegration, isLoading: isDeleting } = useMutation({
  mutationFn: () =>
    axios.delete("api/Integration/DeleteUserIntegration/UNDERWRITEME"),
  onSuccess: () => {
    underWriteMeCredentials.value.userName = "";
    queryClient.invalidateQueries(["underWriteMe-integration"]);
    queryClient.invalidateQueries(["user-integrations"]);
    userService.update();
  },
});

const underWriteMeCredentials = ref({
  userName: "",
});

const emit = defineEmits(["saveCredentials"]);

const { mutate: saveCredentials, isLoading: isSaving, isError } = useMutation({
  mutationFn: () =>
    axios
      .post(
        "api/Integration/SaveUnderWriteMeCredentials",
        underWriteMeCredentials.value
      ),
  onSuccess: async () => {
    queryClient.invalidateQueries(["underWriteMe-integration"]);
    queryClient.invalidateQueries(["user-integrations"]);
    await userService.update();
    emit("saveCredentials", "UNDERWRITEME");
  }
});

const isLoading = computed(
  () => isSaving.value || isLoadingCurrent.value || isDeleting.value
);
const disabled = computed(() => !!currentIntegration.value || isLoading.value);

</script>

<template>
  <div class="container">
    <div class="content">
      <div class="tip">
        <p>
          To ensure the prices and quotes we obtain from UnderwriteMe are the prices and products you
          have access to, please provide us with the <b>email address</b> you use to log in to
          <b>UnderwriteMe’s Protection Platform</b>.
        </p>
      </div>
      <div>
        <div class="form">
          <img alt="underwriteMe" :src="underWriteMeLogo" />
          <fieldset>
            <label for="email">
              Email address
            </label>
            <ElInput v-if="!!currentIntegration" id="WebID" v-model="currentIntegration.userName" disabled />
            <ElInput v-else id="WebID" v-model="underWriteMeCredentials.userName" :min-length="1" type="text" :class="{ error : isError}" />
            <span v-if="isError" class="errorText"> Sorry, we are unable to match your email to an UnderwriteMe account, please check and try again.</span>
          </fieldset>
          <ElButton v-if="!!currentIntegration" type="danger" @click="deleteUserIntegration" :loading="isLoading">
            Unlink account
          </ElButton>
          <ElButton v-else type="primary" @click="saveCredentials" :loading="isLoading" :disabled="disabled">
            Link account
          </ElButton>
        </div>
        <div class="links">
          <P>
            <strong>Don’t have an UnderWriteMe account? </strong>
            <a target="_blank" href="https://www.underwriteme.co.uk/adviser-registration-form/">
              Click Here
            </a>
            to set up an account.
          </P>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.errorText {
  color: red;
  font-size: small;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
  border: var(--el-border);
  border-radius: 0.2rem;
  width: 20rem;
}

.form fieldset {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.form img {
  width: 50%;
}

.form fieldset label {
  display: flex;
  justify-content: space-between;
}

.content {
  display: flex;
  gap: 1rem;
}

.links {
  font-size: small;
  text-align: center;
}

.ext {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.tip {
  background-color: #eebb92;
  border-left-color: #ee7219;
}

.tip p {
  color: #595959;
}
</style>

<style>
.error .el-input__wrapper {
  border: 1px solid red;
}
</style>

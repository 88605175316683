<template>
    <div class="header">
        <img class="logo" src="/Images/PGProBlue.svg" @click="$emit('go-to-home')" />


        <div class="QOptions" style="padding-right:150px;">

            <el-popover placement="bottom" width="160" trigger="click">
                <el-link v-if="subscription !== null && subscription !== undefined && userDetails !== null" @click="visible = false" v-on:click.stop="$emit('contact-us')">Contact
                    us</el-link>
                <hr />
                <span v-if="subscription !== null && subscription !== undefined && userDetails !== null && (userDetails.hasSetNoPricing || userDetails.integrations?.length > 0)">
                    <el-link>
                        <span style="font-weight:bold;">Settings</span>
                    </el-link>
                    <el-link @click="visible = false" v-on:click.stop="$emit('subscription-setting')">
                        Subscription Settings
                    </el-link>
                    <el-link @click="visible = false" v-on:click.stop="$emit('commission-setting')">
                        Commission settings
                    </el-link>
                    <el-link @click="visible = false" v-on:click.stop="$emit('price-setting')">
                        Price settings
                    </el-link>
                    <hr />
                </span>
                <div v-if="!!userDetails?.isAdminUser">
                    <el-link><strong>Admin</strong></el-link>
                    <el-link @click="$emit('config-products')">Configure Product Mappings</el-link>
                    <el-link @click="$emit('config-filters')">Configure Product Filters</el-link>
                    <el-link @click="$emit('popup-message')">Configure Popup Message</el-link>
                </div>
                <hr />
                <el-link v-on:click.stop="Logout()" style="font-weight:700">Logout</el-link>
                <template #reference>
                    <el-button type="primary" class="accountButton" style="height: 40px;" round>
                        My account&nbsp;
                        <el-icon :size="16">
                            <User />
                        </el-icon>
                    </el-button>
                </template>
            </el-popover>
            <div v-if="subscription !== null && subscription !== undefined && (subscription.subscriptionType === 'SingleUser' || subscription.subscriptionType === 'FirmAdmin')">
                <el-button :type="remainingCreditsType"
                           size="large"
                           v-loading="loading"
                           :round="true"
                           style="margin-right:10px;"
                           @click="SubscriptionService.getAdditionalCredits()">
                    <span style="font-size: 21px; font-weight: bold;">{{totalCredits}}</span>&nbsp;
                    <span style="font-weight:normal">Available {{totalCredits == 1 ? "Credit" : "Credits"}}</span>
                    &nbsp;<u style="font-weight:bold;">Add</u>
                </el-button>
            </div>
        </div>
    </div>
</template>
<style scoped>
/* ipad Mini Landscape */
/*@media only screen and (width:1024px) {
        .header {
            background: #181F6A !important;
            position: fixed !important;
            width: 100% !important;
            height: 84px !important;
            left: 0px !important;
            top: 0px !important;
        }
    }*/
button.el-button.el-button--customborder {
    border: 2px solid white;
    background: none;
    color: white;
}

.creditsButton {
    margin-right: 20px;
}

.accountButton.el-button.is-round,
.el-button.is-round {
    border-radius: 20px !important;
    padding: 12px 23px !important;
}

.logo {
    position: absolute;
    left: 114px;
    top: 0%;
    bottom: 43.3%;
    cursor: pointer;
    z-index: 5;
}

.header {
    background: #181F6A;
    position: fixed;
    width: 100%;
    height: 84px;
    left: 0px;
    top: 0px;
    padding-right: 400px;
    /*padding:25px 32px 25px 32px;*/
    /*margin-bottom: 120px;*/
}

.QOptions {
    position: relative;
    height: 20px;
    right: 16px;
    top: 30px;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    /* Card colours */
    /*background: #353C8E;*/
    flex-direction: row-reverse;
    color: #FFFFFF;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
}

hr {
    border-bottom: 1px solid #D8D8D8 !important;
}

.el-link {
    display: block;
}

.el-link.el-link--default:after {
    color: #0F172A !important;
}

.el-link.el-link--default {
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 34px !important;
    color: #000A34 !important;
}

.el-link.is-underline:hover:after {
    border-bottom: none !important;
}

.el-link.el-link--default:hover {
    color: #0F172A !important;
}

.accountButton.el-button,
.accountButton.el-button--primary:focus,
.accountButton.el-button--primary:hover {
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    border-radius: 46px !important;
    color: #fff !important;
    background: #353C8E !important;
    margin-left: unset;
    margin-right: unset;
    display: inline !important;
}

.accountButton.el-button:focus,
.accountButton.el-button:hover,
.accountButton.el-button--primary {
    color: #FFFFFF;
    border-color: #353C8E !important;
    background-color: #353C8E !important;
    margin-left: unset;
    margin-right: unset;
    display: inline;
}
</style>
<script>
export default {
    name: 'HeaderControl',
    props: ["userDetails", "currentScreen"],
    inject: ["SubscriptionService"],
    computed: {
        remainingCreditsType: function () {
            if (this.totalCredits === null)
                return null;
            if (this.totalCredits > 4)
                return "customborder"; /*green*/
            if (this.totalCredits > 2)
                return "warning"; /*amber*/
            return "danger"; /*red*/
        },
        totalCredits: function () {
            if (this.subscription === null) {
                return null;
            }
            return this.subscription.totalCredits;
        },
    },
    data() {
        return {
            visible: false,
            subscription: null,
            loading: false
        };
    },
    mounted: function () {
        this.subscription = this.SubscriptionService.getSubscription();
        this.loading = this.SubscriptionService.isloading();
    },
    methods: {
        Logout: function () {
            sessionStorage.clear();
            window.location = "/Api/signout";
        }
    }
}
</script>

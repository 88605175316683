<template>
    <div class="layoutDiv">
        <div class="mainDiv">
            <div v-if="Tools === null">
                <loading-control :loading-message="'Loading Tools'" />
            </div>

            <div>
                <p>
                    <span>
                        <label class="numbers">2</label>
                        <label class="policyDetails">Policy type</label>
                    </span>

                    <span style="float:right">
                        <label class="percentageCompleted">40% completed</label>
                    </span>
                </p>

                <p>
                    <el-progress style="padding-top: 5px;" class="el-progress--without-text" :percentage="40"></el-progress>
                </p>

                <div class="item">
                    <p class="Heading4">What type of policy would you like to compare?</p>
                </div>

                <!--<div class="radioMargin" v-if="Tools.includes('LifePro')">
                    <el-radio class="radioElement Paragraph2" label="LifePro" border v-model="SelectToolReference" v-on:click.stop="SelectTool('LifePro')">Life Protection</el-radio>
                </div>

                <div class="radioMargin" v-if="Tools.includes('CIPlus')">
                    <el-radio class="radioElement Paragraph2" label="CIPlus" border v-model="SelectToolReference">Critical Illness</el-radio>
                </div>-->

                <div class="radioMargin" v-if="Tools.includes('IPPro')">
                    <el-radio class="radioElement Paragraph2" label="IPPro" border v-model="SelectToolReference">Income Protection</el-radio>
                </div>
                <div class="radioMargin" v-if="Tools.includes('MPCIPro') && Tools.includes('MPPro') && Tools.includes('CIPlus') && Tools.includes('LifePro')">
                    <el-radio class="radioElement Paragraph2" label="LifePro" border v-model="SelectToolReference">Term Assurance </el-radio>
                </div>
                <div class="radioMargin" v-if="Tools.includes('FIBPro') && Tools.includes('FIBCIPro')">
                    <el-radio class="radioElement Paragraph2" label="FIBPro" border v-model="SelectToolReference">Family Income Benefit</el-radio>
                </div>
                <div class="radioMargin" v-if="Tools.includes('KeyPersonPro') && Tools.includes('KeyPersonCIPro')">
                    <el-radio class="radioElement Paragraph2" label="KeyPersonPro" border v-model="SelectToolReference">Business Protection</el-radio>
                </div>
                <!--<div class="radioMargin" v-if="Tools.includes('MPPro')">
                    <el-radio class="radioElement Paragraph2" label="MPPro" border v-model="SelectToolReference">Mortgage Protection</el-radio>
                </div>
                <div class="radioMargin" v-if="Tools.includes('MPCIPro')">
                    <el-radio class="radioElement Paragraph2" label="MPCIPro" border v-model="SelectToolReference">Mortgage Protection with Critical Illness</el-radio>
                </div>-->
                <div class="radioMargin" v-if="Tools.includes('IPPro - Pricing')">
                    <el-radio class="radioElement Paragraph2" label="IPPro - Pricing" border v-model="SelectToolReference">Income Protection</el-radio>
                </div>
                <div class="radioMargin" v-if="Tools.includes('MPCIPro - Pricing') && Tools.includes('MPPro - Pricing') && Tools.includes('CIPlus - Pricing')  && Tools.includes('LifePro - Pricing')">
                    <el-radio class="radioElement Paragraph2" label="CIPlus - Pricing" border v-model="SelectToolReference">Term Assurance </el-radio>
                </div>
                <div class="radioMargin" v-if="Tools.includes('FIBPro - Pricing') && Tools.includes('FIBCIPro - Pricing')">
                    <el-radio class="radioElement Paragraph2" label="FIBPro - Pricing" border v-model="SelectToolReference">Family Income Benefit</el-radio>
                </div>
                <div class="radioMargin" v-if="Tools.includes('KeyPersonPro - Pricing') && Tools.includes('KeyPersonCIPro - Pricing')">
                    <el-radio class="radioElement Paragraph2" label="KeyPersonPro - Pricing" border v-model="SelectToolReference">Business Protection</el-radio>
                </div>
                <!--<div class="radioMargin" v-if="Tools.includes('KeyPersonPro')">
                    <el-radio class="radioElement Paragraph2" label="KeyPersonPro" border v-model="SelectToolReference">Key Person Cover</el-radio>
                </div>
                <div class="radioMargin" v-if="Tools.includes('KeyPersonCIPro')">
                    <el-radio class="radioElement Paragraph2" label="KeyPersonCIPro" border v-model="SelectToolReference">Key Person Cover with Critical Illness</el-radio>
                </div>
                <div class="radioMargin" v-if="Tools.includes('ShareholderPro')">
                    <el-radio class="radioElement Paragraph2" label="ShareholderPro" border v-model="SelectToolReference">Shareholder Protection</el-radio>
                </div>
                <div class="radioMargin" v-if="Tools.includes('ShareholderCIPro')">
                    <el-radio class="radioElement Paragraph2" label="ShareholderCIPro" border v-model="SelectToolReference">Shareholder Protection with Critical Illness</el-radio>
                </div>
                <div class="radioMargin" v-if="Tools.includes('RelevantLifePro')">
                    <el-radio class="radioElement Paragraph2" label="RelevantLifePro" border v-model="SelectToolReference">Relevant Life</el-radio>
                </div>-->
                <!--<div class="radioMargin" v-for="tool in UserSelection.tools" :key="tool.toolReference">
                    <el-radio class="radioElement Paragraph2" label="tool.toolReference" border v-model="SelectToolReference">{{tool.toolName}} <br /></el-radio>
                </div>-->

                <div class="btnNextPosition">
                    <el-button type="default" v-on:click.stop="$emit('back')"> <el-icon> <ArrowLeft /> </el-icon>  Back</el-button>
                    
                    <el-button :disabled="SelectToolReference === ''" type="success" v-on:click.stop="SelectTool(SelectToolReference)">Next</el-button>
                </div>

            </div>
        </div>

        <div class="summary" v-if="Customers != undefined && Customers != null">
            <label class="summaryTitle">Summary</label> <br />

            <div style="display:inline-flex">
                <label class="summaryLabels">Client details</label>
                <el-link v-if="UserSelection.primaryClient !== undefined && UserSelection.primaryClient !== null && false" v-on:click.stop="$emit('edit-customer')" class="summaryLabels" style="margin-left: 120px; text-decoration: underline">Edit</el-link>
            </div>

            <div v-if="UserSelection.primaryClient !== undefined && UserSelection.primaryClient !== null && false">
                <label class="summaryDetail">{{UserSelection.primaryClient.forename + " " + UserSelection.primaryClient.surname}}</label>
                <label class="summaryDetail">
                    {{UserSelection.primaryClient.dateOfBirth}} , {{UserSelection.primaryClient.classification}}
                </label>
            </div>

            <div v-if="UserSelection.secondaryClient !== undefined &&  UserSelection.secondaryClient !== null && false">
                <label class="summaryDetail">{{UserSelection.secondaryClient.forename + " " + UserSelection.secondaryClient.surname}}</label>
                <label class="summaryDetail">
                    {{UserSelection.secondaryClient.dateOfBirth}} , {{UserSelection.secondaryClient.classification}}
                </label>
            </div>

            <hr style="border: 1px solid #A0A0A0;" />

            <label class="summaryLabels">Policy type</label>
            <hr style="border: 1px solid #A0A0A0;" />

            <label class="summaryLabels">Policy details</label>
            <hr style="border: 1px solid #A0A0A0;" />

            <label class="summaryLabels">Quality of plans</label>
            <hr style="border: 1px solid #A0A0A0;" />

            <label class="summaryLabels">Additional info</label>
        </div>

    </div>
</template>
<style>

    /* .el-radio {
        position: static;
        width: 331px;
        height: 33px;
    }*/

    .el-radio span .el-radio__inner {
        display: none;
    }

    /*   .el-radio__input.is-checked .el-radio__original {
            background: #46A3F8;
        }*/
    .el-radio__original {
        position: absolute !important;
        width: 20px !important;
        height: 20px !important;
        left: 10px !important;
        top: 13px !important;
        background: #FFFFFF !important;
        box-sizing: border-box !important;
        opacity: unset !important;
        outline: unset !important;
        position: unset !important;
        z-index: unset !important;
        top: unset !important;
        left: unset !important;
        right: unset !important;
        bottom: unset !important;
        margin: unset !important;
    }
</style>
<style scoped>

    .layoutDiv {
        position: relative;
        margin-top: 24px;
        max-width: 1144px;
        min-width: 1044px;
        margin-left: auto;
        margin-right: auto;
    }

    .el-progress-bar > div {
        padding-right: 0px !important;
        margin-right: 0px !important;
    }

    .el-link.el-link--default:hover {
        color: black !important;
    }

    .el-link.is-underline:hover:after {
        border: none !important;
    }

    .el-link.el-link--default:after {
        border: none !important;
    }

    .summaryDetail {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;
        width: 194px;
        height: 40px;
    }

    .summaryLabels {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 36px;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;
        color: #A0A0A0;
    }

    .summary {
        position: absolute;
        top: 0;
        right: 150px;
        width: 232px;
        height: 572px;
        display: block;
    }

    .summaryTitle {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 41px;
        letter-spacing: -0.04em;
        color: #0F172A;
    }

    .btnNextPosition {
        display: inline-flex;
        padding-top: 20px;
        padding-left: 10px;
    }

    .el-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        position: static;
        width: 167px;
        height: 44px;
        left: 199px;
        top: 0px;
        border-radius: 8px;
    }

    .el-button--success {
        color: #fff !important;
        background-color: #059669 !important;
        /*border-color: #059669 !important;*/
    }

        .el-button--success:focus, .el-button--success:hover {
            background: #059669 !important;
            border-radius: 8px;
            /*border-color: #059669 !important;*/
        }

        .el-button--success.is-disabled, .el-button--success.is-disabled:active, .el-button--success.is-disabled:focus, .el-button--success.is-disabled:hover {
            background: #E7EAEE !important;
            border-radius: 8px;
            /*border-color: #E7EAEE !important;*/
            font-style: normal;
            color: #A0ABBB;
        }

    .el-button--default {
        color: black !important;
        background: white !important;
        border: 1px solid #191D23 !important;
    }

    /*.el-button--default:focus, .el-button--default:hover {
            background-color: white;
            border-radius: 8px;
            border: 1px solid #191D23;
            border-radius: 8px;
            color: black;
        }*/

    .el-radio {
        width: 350px;
        box-sizing: border-box;
        border-radius: 8px !important;
    }

    .radioMargin {
        padding-bottom: 20px;
        padding-right: 15px;
        flex: none;
        order: 1;
        flex-grow: 0;
        background: #FFFFFF !important;
        border-radius: 8px !important;
        float: right;
    }

    .el-radio__input.is-checked + .el-radio__label {
        color: black !important;
    }

    .el-radio__input.is-checked .el-radio__inner {
        border-color: none !important;
        background: none !important;
    }

    .el-radio.is-bordered.is-checked {
        color: black !important;
        background: #E8EDFB !important;
        border: 1px solid #6183E4 !important;
        box-sizing: border-box;
        border-radius: 8px !important;
    }

    .el-radio__input.is-checked + .el-radio__label {
        color: black !important;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
    }

    .el-card.is-always-shadow {
        box-shadow: none;
    }


    .Paragraph2TC {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #191D23;
        padding-left: 15px;
        padding-right: 15px;
    }

    .Heading4 {
        width: 366px;
        height: 74px;
        left: 0px;
        top: 66px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -0.02em;
        color: #0F172A;
    }

    .el-progress__text {
        display: none !important;
    }

    .numbers {
        background: #EEEEEE;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        background: #EEEEEE;
        border-radius: 15px;
        padding: 2px;
    }

    .policyDetails {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        align-items: center;
        letter-spacing: -0.04em;
        padding-left: 10px;
    }

    .mainDiv {
        padding: 82px;
        width: 530px;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 8px;
    }
</style>

<script>
    import LoadingControl from '../components/LoadingControl.vue';

    export default {
        name: 'ToolSelection',
        props: ["userSelection", "userDetails"],
        components: { LoadingControl },
        data: function () {
            return {
                Tools: [],
                SelectToolReference: '',
                Customers: [],
            }
        },
        computed: {
            UserSelection: function () { return this.userSelection; },
            UserDetails: function () { return this.userDetails; },
        },
        methods: {
            SelectTool: function (toolReference) {

                var thisControl = this;
                /*if (thisControl.UserSelection.tool !== null && thisControl.UserSelection.tool.toolReference !== toolReference) {*/

                thisControl.UserSelection.selectedCustomerType = null;
                thisControl.UserSelection.ipBenefitBasis = "Monthly";

                //if (thisControl.UserSelection.tool !== null)
                {
                    thisControl.UserSelection.amountAssured = 0;
                    thisControl.UserSelection.policyTerm = null;
                    thisControl.UserSelection.toAge = null;
                    thisControl.UserSelection.children = [];
                    thisControl.UserSelection.childNames = [];
                    thisControl.UserSelection.isTemplateSelected = false;
                    thisControl.UserSelection.primaryClient = { termFractureCover: false, salary: 0, personEmployedStatus: "Employed", forename: "", surname: "", occupation: null, classification: "Male_NonSmoker", dateOfBirth: '1990-01-01' };
                    //thisControl.UserSelection.secondaryClient = null;
                    thisControl.UserSelection.includedProviders = [];
                    thisControl.UserSelection.includeFeatureInDataTable = [];
                    thisControl.UserSelection.includeCategoryInDataTable = [];
                    thisControl.UserSelection.selectedProviders = [];
                    thisControl.UserSelection.primaryPersonName = '';
                    thisControl.UserSelection.secondaryPersonName = '';

                    if (thisControl.UserSelection.categoryWeightings !== null && thisControl.UserSelection.categoryWeightings !== undefined && thisControl.UserSelection.categoryWeightings.length > 0)
                        thisControl.UserSelection.categoryWeightings.forEach(function (category) { category.weight = 0 });

                    if (thisControl.UserSelection.extendedFeatures !== null && thisControl.UserSelection.extendedFeatures !== undefined && thisControl.UserSelection.extendedFeatures.length > 0)
                        thisControl.UserSelection.extendedFeatures.forEach(function (feature) { feature.weight = 0 });

                    thisControl.UserSelection.ipBenefitAmount = 0;
                    thisControl.UserSelection.ipBenefitBasis = "Monthly";
                    thisControl.UserSelection.mortgageInterestRate = 8;
                    thisControl.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount = null;
                    thisControl.UserSelection.termCriticalIllnessCoverAmount = null;
                    thisControl.UserSelection.termBenefitAmount = null;
                    thisControl.UserSelection.termTotalPermanentDisability = false;
                    thisControl.UserSelection.termIndexation = "Level";
                    thisControl.UserSelection.decreasingPolicy = false;
                    thisControl.UserSelection.customFields = {};
                    thisControl.UserSelection.clientBudget = null;
                    thisControl.UserSelection.ipIncludePaymentPlans = true;
                    thisControl.UserSelection.ipInitialDeferredPeriod = 0;
                    thisControl.UserSelection.ipAdditionalDeferredPeriod = null;
                    thisControl.UserSelection.ipIndexation = "Level";
                    thisControl.UserSelection.ipBenefitIncreaseBasis = "PolicyInception";
                    thisControl.UserSelection.benefitAmountRequested = 0;
                    thisControl.UserSelection.ipAdditionalDeferredBenefitAmount = null;
                    thisControl.UserSelection.reportTitle = "";
                    thisControl.UserSelection.journeyReference = null;
                    thisControl.UserSelection.legacyPolicyProvider = '';
                    thisControl.UserSelection.legacyPolicyProviderReference = '';
                    thisControl.UserSelection.legacyUniquePolicyProvider = '';
                    thisControl.UserSelection.legacyPolicyProviderPeriod = '';
                    thisControl.UserSelection.legacyMonthYear = '';
                    thisControl.UserSelection.legacyYear = '';
                    thisControl.UserSelection.showCIPolicy = '';
                    thisControl.UserSelection.legacyPolicyProviderPrice = 0;
                    thisControl.UserSelection.legacyBetterSubSections = [];
                    thisControl.UserSelection.productsInFocus = [];
                    thisControl.UserSelection.coverBasis = "LEVEL";
                }
                this.$emit("tool-selected", toolReference);
            },
        },
        created: function () {
            /*this.UserSelection.selectedCustomerType = null;*/

            var thisControl = this;
            thisControl.Tools = [];

            //if (this.UserSelection.primaryClient === null)
            //    this.UserSelection.primaryClient = { classification: "Male_NonSmoker", dateOfBirth: '1990-01-01', title: '', surname: '', forename: '', salary: 0, personEmployedStatus: 'Employed' };

            if (thisControl.UserSelection != null) {
                if (thisControl.UserSelection.analysisType === 'DataTables' && !thisControl.UserDetails.hasSetNoPricing) {
                    if (thisControl.UserSelection.nonPricingTools !== null)
                        thisControl.UserSelection.nonPricingTools.forEach(function (tool) { thisControl.Tools.push(tool.toolReference) });
                }
                else {
                    if (thisControl.UserSelection.tools !== null)
                        thisControl.UserSelection.tools.forEach(function (tool) { thisControl.Tools.push(tool.toolReference) });
                }
            }

            //this.GetCustomer();
        }
    }</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

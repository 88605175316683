<script setup>
import { useQuery } from '@tanstack/vue-query';
import { computed, ref } from 'vue';
import ProductMappings from './ProductMappings.vue';
import ToolSelector from './ToolSelector.vue';
import { ElDivider } from 'element-plus';
import { serviceName } from '../../utils/strings';
import axios from 'axios';

const props = defineProps({
    service: {
        type: String,
        required: true,
    },
});

const toolReference = ref('');

const toolQueryKey = computed(() => ['tool-products', toolReference.value]);
const toolQueryEnabled = computed(() => !!toolReference.value);

const { data: toolProducts } = useQuery({
    queryKey: toolQueryKey,
    enabled: toolQueryEnabled,
    queryFn: () => axios(`api/Configuration/ListProducts/${toolReference.value}`).then(res => res.data),
});

const mappingsQueryKey = computed(() => ['tool-mappings', props.service, toolReference.value]);
const tmappingsQueryEnabled = computed(() => !!toolReference.value);

const { data: mappings, isSuccess } = useQuery({
    queryKey: mappingsQueryKey,
    enabled: tmappingsQueryEnabled,
    queryFn: () => axios(`api/Configuration/ListProductMappings/${props.service}/${toolReference.value}`).then(res => res.data),
});

</script>
<template>
    <div>
        <h2>{{ serviceName(service) }} products mapping</h2>
        <p class="tip">
            Select a tool for mapping API products to iPipeline products
            <ToolSelector v-model="toolReference" />
        </p>
        <div v-if="toolProducts?.length && isSuccess">
            <h3>{{ toolReference }}</h3>
            <div class="list">
                <div v-for="product in toolProducts" v-bind:key="product.providerReference">
                    <ProductMappings :product="product" :tool-reference="toolReference" :mappings="mappings"
                        :service="service" />
                    <ElDivider class="divider" />
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background-color: #f5f5f5;
    padding: 1rem;
}

.divider {
    margin: 0;
}
</style>
<template>

    <!--<div class="ModalBackground" style="display: block;">
        <div class="ModalBackGroundPanel" style="height: auto; width: 600px; height: 171px; background: #FFFFFF; border-radius: 8px;overflow:hidden;">-->
    <!--<div class="Options" style="padding-top:20px;">
        <i v-on:click.stop="$emit('back')" class="el-icon-close" style="width: 24px; height: 54px; font-size: xx-large;"></i>
    </div>-->
    <!--<div class="header" style="border-bottom:none">

        <h3 class="Title">
            Add more credits ?
            <i v-on:click.stop="$emit('back')" class="el-icon-close" style="float:right;margin-left:125px;"></i>
        </h3>
    </div>-->
    <!--style="font-family: 'Inter'; font-style: normal; font-weight: 400; font-size: 14px; line-height: 19px; color: #191D23;"-->
    <!--<p>
                Do you want to add more credits to your account? we will direct to you to a page where you will be able to purchase some more credits.
            </p>
            <div class="navigation" style="float:right;">
                <el-button type="default" class="elDecline" v-on:click.stop="DeclineTandCsLogout">Cancel</el-button>
                <el-button type="success" class="elConsent" v-on:click.stop="ResetPricingSettings" style="background: #181F6A; border-radius: 8px; color: white; width: 250px;">Yes I want to Add more credits</el-button>
            </div>
        </div>
    </div>-->

    <div class="ModalBackground" style="display:block;">
        <div class="ModalBackGroundPanel" style="overflow: hidden">

            <p class="PricingHeader Title">Add more credits ? <i v-on:click.stop="$emit('back')" class="el-icon-close" style="float:right;margin-left:350px;"></i></p>
            <p>
                Do you want to add more credits to your account? we will direct to you to a page where you will be able to purchase some more credits.
            </p>

            <div class="navigation" style="float:right;">
                <el-button type="default" style="width:100px;" v-on:click.stop="$emit('back')">Cancel</el-button>
                <el-button style="background: #181F6A; border-radius: 8px;color:white;width:250px;" v-on:click.stop="">Yes I want to Add more credits</el-button>
            </div>



        </div>
    </div>
</template>

<style scoped>

    /* ipad Mini Landscape */
    @media only screen and (width:1024px) {

        .ModalBackGroundPanel {
            margin-top: 20px !important;
            padding-top: 0px !important;
            width: 450px !important;
        }
    }

    .ModalBackGroundPanel {
        position: relative;
        width: 550px;
        background: #FFFFFF;
        border-radius: 8px;
        overflow: hidden;
        height: 180px;
    }

    .navigation button {
        max-width: 266px !important;
        padding: 10px !important;
        padding-right: 20px !important;
    }

    .el-button--success, .el-button--success:focus, .el-button--success:hover {
        background: #1E228C;
        border-radius: 8px;
        border: none;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: white;
        width: max-content;
    }

    .el-button--default, .el-button--default:focus, .el-button--default:hover {
        background: #FFFFFF;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 8px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #000000;
    }

    .PricingHeader {
        /*position: static;*/
        /*width: 189px;*/
        height: 23px;
        left: 0px;
        top: 0px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 19px;
        line-height: 23px;
        letter-spacing: -0.02em;
        color: #2D2D2D;
        /*flex: none;
        order: 0;
        flex-grow: 0;*/
    }

    .DropdownControl {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 12px 10px 16px;
        position: absolute;
        width: 544px;
        height: 44px;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .label {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #0F172A;
    }

    .input {
        width: 544px;
        height: 44px;
        left: 32px;
        top: 90px;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 8px;
        margin-bottom: 20px;
        padding: 10px 12px 10px 16px;
    }
</style>
<script>
    import $ from 'jquery';

    export default {
        components: {},

        name: 'IncomeProtectionCommissionSetting',
        props: ["userDetails", "userSelection"],
        methods:
        {
            ResetPricingSettings: function () {
                window.location = "/api/reset";
            },
            SaveUserCommissionSettings: function () {
                var thisControl = this;

                if (thisControl.UserDetails.commissionType == "Full")
                    thisControl.UserDetails.commissionPercentageRequired = 0;

                if (thisControl.UserDetails.commissionType == "Nil") {
                    thisControl.UserDetails.indemnityRequired = false;
                    thisControl.UserDetails.commissionPercentageRequired = 0;
                }

                thisControl.UserSelection.ipCommissionType = thisControl.UserDetails.commissionType;
                thisControl.UserSelection.ipIndemnityRequired = thisControl.UserDetails.indemnityRequired;
                thisControl.UserSelection.ipCommissionPercentageRequired = thisControl.UserDetails.commissionPercentageRequired;

                $.ajax({
                    type: 'POST',
                    url: '/api/Home/SaveUserSettings/',
                    contentType: 'application/json',
                    data: JSON.stringify(thisControl.UserDetails),
                    success: function () {
                        thisControl.$emit('back');
                    },
                    error: function (e) {
                        thisControl.$message.error("Error:\n" + e.responseText);
                    }
                });
            }
        },
        created: function () {
            if (this.UserDetails.commissionType === null || this.UserDetails.commissionType === 0)
                this.UserDetails.commissionType = "Full";

            if (this.UserDetails.commissionType !== null && this.UserDetails.commissionType === 1)
                this.UserDetails.commissionType = "Modified";

            if (this.UserDetails.commissionType !== null && this.UserDetails.commissionType === 2)
                this.UserDetails.commissionType = "Nil";

            if (this.UserDetails.indemnityRequired === null)
                this.UserDetails.indemnityRequired = true;
        },
        computed: {
            UserDetails: function () {
                return this.userDetails;
            },
            UserSelection: function () {
                return this.userSelection;
            },
            HasPrice: function () {
                if (this.UserDetails.hasSetNoPricing)
                    return "No";
                else
                    return "Yes";
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

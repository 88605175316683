<template>
    <p>
        <label v-if="mode === 'display'">Occupation: </label>
        <label v-if="mode === 'display'">{{ displayValue }}</label>

        <label v-if="mode !== 'display'">Please Select Occupation: </label>
        <input v-if="mode !== 'display'" type="text" v-model="displayValue" list="occupations">

        <datalist id="occupations">
            <option v-for="(occupation, i) in occupations" :key="i">{{ occupation.description }}</option>
        </datalist>
    </p>
</template>

<script>
    import $ from 'jquery';
    export default {
        name: 'OccupationsControl',
        props: ["value", "mode"],
        data: function () {
            return {
                occupations: [],
                displayValue: ""
            }
        },
        components: {
        },
        watch:
        {
            displayValue: function (newValue) {
                var thisControl = this;

                var matches = thisControl.occupations.filter(function (v) {
                    return v.description === newValue
                });

                if (matches.length > 0)
                    this.$emit('input', matches[0].code);
                else if (this.value !== "")
                    this.$emit('input', "");
            },
            value: function (newValue) {
                var thisControl = this;

                if (this.occupations.length > 0) {
                    var matches = thisControl.occupations.filter(function (v) {
                        return v.code === newValue
                    });

                    if (matches.length > 0)
                        thisControl.displayValue = matches[0].description;
                }
            }
        },
        methods:
        {
            GetOccupations: function () {
                var thisControl = this;

                $.ajax({
                    type: 'GET',
                    url: '/api/Pricing/Occupations/',
                    contentType: 'application/json',
                    success: function (data) {
                        thisControl.occupations = data;
                        var matches = thisControl.occupations.filter(function (v) {
                            return v.code === thisControl.value
                        });

                        if (matches.length > 0)
                            thisControl.displayValue = matches[0].description;

                        //data.forEach((entry) => {
                        //    thisControl.occupations.push(entry.description);
                        //});
                    }
                });
            }
        },
        created: function () {
            this.GetOccupations();
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

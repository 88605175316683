<template>
    <div class="ModalBackground" style="display:block;">
        <div class="ModalBackGroundPanel">
            <div v-if="IsLoading" class="spinner-container">
                <div class="spinner"></div>
            </div>
            <div v-else>
                <div class="header" style="border:none; position: relative;">
                    <h2 class="Title contctusHeader">Contact Us</h2>
                    <div v-if="IsEmailSent">
                        <button class="close-button" v-on:click.stop="back">x</button>
                        <div class='message'>
                            <p>
                                Thank you for your enquiry. We aim to respond to you within 24 hours.
                            </p>
                        </div>
                        <div class="hours">
                            <p> <b>Please note, our office opening hours are:</b></p>
                            <p><span>Monday - Friday:</span><span>9.00am to 5.30pm</span></p>
                            <p><span>Weekends & Public Holidays:</span><span>Closed</span></p>
                        </div>
                    </div>

                    <el-form v-if="!IsEmailSent" :model="UserDetails" :rules="rules" ref="ruleForm">
                        <el-form-item class="label" label="First name" prop="givenName">
                            <el-input v-model="UserDetails.givenName"></el-input>
                        </el-form-item>

                        <el-form-item class="label" label="Second name" prop="surname">
                            <el-input v-model="UserDetails.surname"></el-input>
                        </el-form-item>

                        <el-form-item class="label" label="Email address" prop="emailAddress">
                            <el-input v-model="UserDetails.emailAddress"></el-input>
                        </el-form-item>

                        <el-form-item class="label" label="Organisation name" prop="organisationName">
                            <el-input v-model="UserDetails.organisationName"></el-input>
                        </el-form-item>

                        <el-form-item class="label" label="Contact number" prop="contactNumber">
                            <el-input v-model="UserDetails.contactNumber"></el-input>
                        </el-form-item>

                        <el-form-item class="label" label="Your message" prop="bodyMessage">
                            <el-input type="textarea" v-model="UserDetails.bodyMessage"></el-input>
                        </el-form-item>

                        <p style="font-size:15px; margin-block-end:25px">
                            By choosing to submit your personal information through this website, you consent to us
                            handling it confidentially and in compliance with the Data Protection Act 2018.
                        </p>

                        <el-form-item class="navigation">
                            <el-button type="default" v-on:click.stop="back">Cancel</el-button>
                            <el-button type="default" @click="resetForm('ruleForm')">Reset</el-button>
                            <el-button type="success" v-on:click.stop="SubmitContactForm('ruleForm')">Submit</el-button>
                        </el-form-item>
                    </el-form>

                </div>
            </div>
        </div>
    </div>
</template>
<style>
    .el-form-item__label {
        color: none !important;
        font-size: 14px !important;
    }

    .label {
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 14px !important;
        line-height: 24px !important;
        color: #0F172A !important;
    }
</style>
<style scoped>
    .el-form-item__content {
        display: flex !important;
        flex-wrap: nowrap !important;
        align-items: center !important;
    }

    .el-form-item__label {
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 24px !important;
        color: #0F172A !important;
    }

    .el-form-item {
        display: block !important;
        margin-bottom: 18px !important;
    }
    /* ipad Mini Landscape */
    /* @media only screen and (width:1024px) {
                        .ModalBackGroundPanel {
                            margin-top: 20px !important;
                            height: 600px !important;
                            padding-top: 0px !important;
                        }
                    }*/
    .navigation {
        margin-top: 5px !important;
        margin-left: 268px !important;
    }

    .el-button--success, .el-button--success:focus, .el-button--success:hover {
        position: static !important;
        height: 40px !important;
        background: #1E228C !important;
        border-radius: 8px !important;
        border: none !important;
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        text-align: center !important;
        color: white !important;
        width: 100px !important;
    }

    .el-button--default, .el-button--default:focus, .el-button--default:hover {
        position: static !important;
        height: 40px !important;
        background: #FFFFFF !important;
        border: 1px solid #000000 !important;
        box-sizing: border-box !important;
        border-radius: 8px !important;
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        color: #000000 !important;
        width: 100px !important;
    }

    .inputTextArea {
        width: 600px !important;
        height: 66px !important;
        left: 32px !important;
        top: 525px !important;
        background: #FFFFFF !important;
        border: 1px solid #E0E0E0 !important;
        box-sizing: border-box !important;
        border-radius: 8px !important;
    }

    .input {
        width: 600px !important;
        height: 34px !important;
        left: 32px !important;
        top: 90px !important;
        background: #FFFFFF !important;
        border: 1px solid #E0E0E0 !important;
        box-sizing: border-box !important;
        border-radius: 8px !important;
        margin-bottom: 20px !important;
        padding-left: 10px !important;
    }

    .label {
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 14px !important;
        line-height: 24px !important;
        color: #0F172A !important;
    }

    .ModalBackGroundPanel {
        position: relative !important;
        width: 600px !important;
        background: #FFFFFF !important;
        border-radius: 8px !important;
        max-height: 660px !important;
        padding-top: 0px !important;
        overflow: hidden !important;
    }

    .contctusHeader {
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 19px !important;
        line-height: 23px !important;
        letter-spacing: -0.02em !important;
        color: #2D2D2D !important;
    }

    .hours p {
        display: flex;
        justify-content: space-between;
        tab-size: 2;
    }

        .hours p span {
            flex: 1;
        }

    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    .spinner {
        border: 8px solid rgba(0, 0, 0, 0.1);
        border-left-color: rgb(30, 34, 140);
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
        color: #0F172A;
        font-weight: bold;
    }
</style>
<script>
    import $ from 'jquery';

    export default {
        components: {},
        name: 'ContactUs',
        props: ["userDetails", "userSelection"],
        data: function () {
            return {
                IsLoading: false,
                IsEmailSent: false,
                Message: "",

                ruleForm: {
                    givenName: '',
                    surname: '',
                    emailAddress: '',
                    organisationName: '',
                    contactNumber: '',
                    bodyMessage: ''
                },
                rules: {
                    givenName: [
                        { required: true, message: 'Please input First name', trigger: 'change' },
                    ],
                    surname: [
                        { required: true, message: 'Please input Second name', trigger: 'change' }
                    ],
                    emailAddress: [
                        { required: true, message: 'Please input Email address', trigger: 'change' }
                    ],
                    organisationName: [
                        { required: true, message: 'Please input Organisation name', trigger: 'change' }
                    ],
                    contactNumber: [
                        { required: true, message: 'Please input Contact number', trigger: 'change' }
                    ],
                    bodyMessage: [
                        { required: true, message: 'Please input Message', trigger: 'change' }
                    ],
                }
            }
        },
        methods:
        {
            IsDisabled: function() {
                if (this.UserDetails.givenName === null || this.UserDetails.givenName === "")
                    return true;
                else if (this.UserDetails.emailAddress === null || this.UserDetails.emailAddress === "")
                    return true;
                else if (this.UserDetails.organisationName === null || this.UserDetails.organisationName === "")
                    return true;
                else if (this.UserDetails.contactNumber === null || this.UserDetails.contactNumber === "")
                    return true;
                else if (this.UserDetails.bodyMessage === null || this.UserDetails.bodyMessage === "")
                    return true;
                else
                    return false;
            },
            SubmitContactForm: function (formName) {
                var thisControl = this;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        thisControl.IsLoading = true;
                        $.ajax({
                            type: 'POST',
                            url: "/ContactUs/ContactUsSubmit/",
                            contentType: 'application/json',
                            data: JSON.stringify(
                                thisControl.UserDetails
                            ),
                            success: function () {
                                thisControl.IsLoading = false;
                                thisControl.IsEmailSent = true;
                            },
                            error: function (data) {
                                thisControl.IsLoading = false;
                                thisControl.$message.error(data.message);
                            }
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            back() {
                this.UserDetails.bodyMessage = '';
                this.$emit('back');
            }
        },
        created: function () {
        },
        computed: {
            UserDetails: function () { return this.userDetails },
            UserSelection: function () { return this.userSelection; }
        }

    }</script>
<template>
    <el-dialog custom-class="advertdialog" :close-on-click-modal="false" width="928px" :close-on-press-escape="false" :show-close="false" v-model="IsVisible">
        <div style="display:inline-block; vertical-align: top; max-width: 350px; padding:60px;">
            <h1>{{LoadingMessage}}</h1>

            <h2>{{advert.title}}</h2>
            <p>{{advert.description}}</p>
            <br />
            <el-button type="info" style="infoButton" v-on:click.stop="clickOnLink">{{advert.callToActionText}}</el-button>
        </div>

        <img :src="'/api/advert/image/' + advert.imageLocation" style="display:inline-block;margin:0;width:457px;" />



    </el-dialog>
    <!-- <div class="modalBackground" v-if="false">
         <div class="Loading">
             <div id="loadingMessages">
                 <h1>{{LoadingMessage}}</h1>
             </div>
             <p>
                 <img :src="'/api/advert/image/' + advert.imageLocation" width="300" />
             </p>
             <h2>{{advert.title}}</h2>
             <p>{{advert.description}}</p>
             <button v-on:click.stop="clickOnLink">{{advert.callToActionText}}</button>
         </div>
     </div>-->
</template>
<style>
    .infoButton {
        width: unset !important;
    }

    .advertdialog .el-dialog__header {
        display: none !important;
    }

    .advertdialog .el-dialog__body {
        padding: 0 0 0 0 !important;
    }
</style>
<style scoped>

    .Loading {
        background: #ddd !important;
        border: 1px solid black;
        padding: 10px;
    }

    h1 {
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        color: #48494D !important;
    }

    h2 {
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 900 !important;
        font-size: 34px !important;
        color: #0F172A !important;
        line-height: 41px !important;
    }

    p {
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 21px !important;
        color: #48494D !important;
        line-height: 29px !important;
    }
</style>
<script>import $ from 'jquery';
    export default {
        name: 'AdvertControl',
        props: ["loadingMessage", "advert", "userSelection", "screen"],
        computed: {
            LoadingMessage: { get: function () { return this.loadingMessage; } },
            UserSelection: { get: function () { return this.userSelection; } },
            IsVisible: {
                get: function () { return true; }, set: function() { }
            },

        },
        methods: {

            clickOnLink: function () {
                window.open(this.advert.linkUri);
                $.ajax({
                    type: 'GET',
                    url: '/api/Advert/click/' + this.UserSelection.sessionReference + "/" + this.UserSelection.journeyReference + "/" + this.advert.campaignReference + "/" + this.advert.advertisementPlacementReference + "/" + this.screen,

                });
            }
        },
        mounted: function () {
            $.ajax({
                type: 'GET',
                url: '/api/Advert/view/' + this.UserSelection.sessionReference + "/" + this.UserSelection.journeyReference + "/" + this.advert.campaignReference + "/" + this.advert.advertisementPlacementReference + "/" + this.screen,

            });
        },
    }</script>
<template>
    <div>
        <fieldset class="selectList">
            <legend>
                Select Providers
            </legend>
            <button class="button250" v-on:click.stop="SelectAllProviders();$emit('provider-selection-completed');">Use whole of market</button>
            <button class="button250" v-if="isInsurersHidden == true" v-on:click="isInsurersHidden = false">Select Products (Insurers)</button>

            <div v-if="!isInsurersHidden">
                <p>
                    Select an insurer to see a list of the available products.
                    <br />
                    <br />
                    Please select/deselect the products you would like to include in your comparison (products highlighted in blue will be included):
                </p>

                <!--<div v-for="provider in FilteredProviders.filter(function(p) {return !p.disabled;})" :key="provider.ap.quoteID">
                    <input v-bind:id="'Provider' + provider.ap.quoteID" type="checkbox" hidden="hidden"
                           v-model="UserSelection.selectedQuotes" v-bind:value="provider.ap.quoteID" />
                    <label v-bind:for="'Provider' + provider.ap.quoteID" :title="provider.tooltip">{{provider.ap.displayName}}<span v-if="1 === 0 && provider.errors">&#x1F6AB;</span><span v-if="1 === 0 && srovider.warnings">&#x26A0;&#xFE0F;</span></label>
                </div>-->

                <div v-for="groupProvider in GroupProviderList" :key="groupProvider.key" class="groupedDiv">

                    <div>
                        <input v-bind:id="'Provider' + groupProvider.key" type="checkbox" hidden="hidden" @change="groupProviderCheck(groupProvider)" :checked="groupProvider.values.some(function(p) { return UserSelection.selectedQuotes.indexOf(p.ap.quoteID) >= 0;  })" />
                        <label v-bind:for="'Provider' + groupProvider.key" class="providerGroup"> {{ groupProvider.key }}</label>
                    </div>
                    <div :style="groupProvider.values.some(function(p) { return UserSelection.selectedQuotes.indexOf(p.ap.quoteID) >= 0;  }) ? '' : 'display:none;' ">
                        <div v-for="provider in groupProvider.values" :key="provider.ap.quoteID">
                            <input v-bind:id="'Quote' + provider.ap.quoteID" type="checkbox" hidden="hidden" v-model="UserSelection.selectedQuotes" v-bind:value="provider.ap.quoteID" />
                            <label class="childLabel" v-bind:for="'Quote' + provider.ap.quoteID" :title="provider.tooltip">{{provider.ap.displayName}}<span v-if="1 === 0 && provider.errors">&#x1F6AB;</span><span v-if="1 === 0 && srovider.warnings">&#x26A0;&#xFE0F;</span></label>
                        </div>
                    </div>

                </div>

            </div>
            <br v-if="isInsurersHidden" />
        </fieldset>
        <fieldset class="navigation analysisbottomMargin50">
            <button v-on:click.stop="$emit('back')">Back</button>
            <button :disabled="UserSelection.selectedProviders.length === 0" class="button250" v-on:click.stop="$emit('provider-selection-completed')">Next</button>
        </fieldset>
    </div>
</template>
<style scoped>
    .groupedDiv {
        width: 441px;
    }

    .providerGroup {
        /*display:inline-table;*/
    }
</style>
<script>import helper from './HelperMethods.vue';

    export default {
        name: 'ProviderSelection',
        props: ["userSelection", "userDetails", "datatableTemplate"],
        data: function () {
            return {
                isInsurersHidden: true,
                GroupProviderList: []
            }
        },
        methods:
        {
            groupProviderCheck: function (provider) {
                var thisControl = this;

                if (!provider.values.some(function (sq) { return thisControl.UserSelection.selectedQuotes.indexOf(sq.ap.quoteID) >= 0; }))//no quotes in the provider are selected
                {
                    thisControl.UserSelection.selectedQuotes.push(...provider.values.map(function (q) { return q.ap.quoteID; }));
                }
                else if (!provider.values.some(function (sq) { return thisControl.UserSelection.selectedQuotes.indexOf(sq.ap.quoteID) < 0; })) //All quotes are selected
                {
                    thisControl.UserSelection.selectedQuotes = thisControl.UserSelection.selectedQuotes.filter(function (sq) { return !provider.values.some(function (q) { return q.ap.quoteID === sq; }) });
                }
                else {
                    thisControl.UserSelection.selectedQuotes = thisControl.UserSelection.selectedQuotes.filter(function (sq) { return !provider.values.some(function (q) { return q.ap.quoteID === sq; }) });
                }

            },
            SelectAllProviders: function () {
                this.UserSelection.selectedQuotes = this.FilteredProviders.filter(function (provider) { return !provider.disabled; }).map(function (provider) { return provider.ap.quoteID; });
                var thisControl = this;
                this.UserSelection.selectedProviders = this.UserSelection.selectedQuotes.map(
                    function (q) {
                        return thisControl.UserSelection.availableLifeProducts.filter(function (ap) {
                            return ap.quoteID === q;
                        })[0].productID;
                    }).filter(helper.distinct);
            },
            /*RemainingProviders: function () {
                return this.maxProviders - this.UserSelection.selectedProviders.length;
            },*/
        },
        mounted: function () {
            var thisControl = this;
            console.log("checking products");
            var unmatchedProducts = thisControl.UserSelection.availableLifeProducts.filter(function (av) { return !thisControl.UserSelection.providers.some(function (p) { return av.productID === p.providerReference; }); });
            unmatchedProducts.forEach(function (av) { console.log("Unmatched Product: " + av.productID + " - " + av.displayName); })
            this.UserSelection.selectedProviders = this.UserSelection.selectedQuotes.map(
                function (q) {
                    return thisControl.UserSelection.availableLifeProducts.filter(function (ap) {
                        return ap.quoteID === q;
                    })[0].productID;
                }).filter(helper.distinct);
        },
        created: function () {
            /*if (this.UserSelection.selectedProviders.length == 0 && this.DatatableTemplate !== null && typeof (this.DatatableTemplate) !== 'undefined' ) {
                this.UserSelection.selectedProviders = this.DatatableTemplate.includedProviders;
            }*/

            var thisControl = this;
            thisControl.GroupProviderList = helper.groupByArray(thisControl.FilteredProviders.filter(function (p) { return !p.disabled }), "provider");
            this.isInsurersHidden = true;

            //this.SelectAllProviders();
            //this.isInsurersHidden = this.UserSelection.selectedProviders.length == 0;
        },
        watch: {
            "UserSelection.selectedQuotes": {
                deep: true,
                handler: function () {
                    var thisControl = this;
                    this.UserSelection.selectedProviders = this.UserSelection.selectedQuotes.map(
                        function (q) {
                            return thisControl.UserSelection.availableLifeProducts.filter(function (ap) {
                                return ap.quoteID === q;
                            })[0].productID;
                        }).filter(helper.distinct);
                }
            }
        },
        computed: {
            UserSelection: function () { return this.userSelection; },
            UserDetails: function () { return this.userDetails; },
            DatatableTemplate: function () { return this.datatableTemplate; },
            FilteredProviders: {
                get: function () {
                    var thisControl = this;
                    return this.UserSelection.availableLifeProducts.filter(function (ap) {
                        return thisControl.UserSelection.providers.some(function (p) { return ap.productID === p.providerReference; });
                    }).map(function (ap) {
                        return {
                            p: thisControl.UserSelection.providers.filter(function (p) { return ap.productID === p.providerReference; })[0],
                            ap: ap,
                            warnings: ap.warnings.length > 0,
                            errors: ap.status == "BusinessError",
                            disabled: ap.quoteID == "00000000-0000-0000-0000-000000000000",
                            tooltip: ((ap.warnings.length > 0 ? "Warnings:\n" + ap.warnings.join("\n") : "\n") + (ap.errors.length > 0 ? "Errors:\n" + ap.errors.join("\n") : "\n")).trim(),
                            provider: ap.provider

                        }
                    }).sort(function (a, b) { return a.ap.displayName.localeCompare(b.ap.displayName); });
                }
            },
        }
    }</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<template>
    <div class="ModalBackground" style="display:block">
        <div class="ModalBackGroundPanel">
            <div class="header">
                <h1 class="Title">{{FeatureName}}</h1>
                <div class="Options">
                    <button v-on:click.stop="$emit('subfeature-cancelled')" class="button150">Cancel</button>
                    <button v-on:click.stop="$emit('subfeature-committed')" :disabled="!CanClose()" class="button150">Continue</button>
                </div>
            </div>
            <table class="analysisStyles" style="width:100%">
                <thead>
                    <tr>
                        <th>Subfeature</th>
                        <th>Weight</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="subfeature in SortedSubfeatures" :key="subfeature.subfeatureReference">
                        <td>
                            {{subfeature.subfeatureName}}
                        </td>
                        <td>
                            <select v-model.number="subfeature.weight">
                                <option value="0">Excluded</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import helper from './HelperMethods.vue';
    export default {
        name: 'SubFeatureSelection',
        props: ["featureName", "subFeatures"],
        data: function () {
            return {
            }
        },
        methods: {
            CanClose: function () {
                return this.SubFeatures.some(function (ef) { return ef.weight > 0 })
            },
        },
        created: function () {
        },
        computed: {
            FeatureName: function () { return this.featureName; },
            SubFeatures: function () { return this.subFeatures;},
            SortedSubfeatures: function(){
                return this.SubFeatures.filter(function () { return true; }).sort(helper.customSort("subfeatureName"))
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<script setup>
import { ElDialog, ElButton, ElMessage } from 'element-plus';
import MultiSelect from '../ui/MultiSelect.vue';
import { defineProps, computed, ref, watch } from 'vue';
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import axios, { HttpStatusCode } from 'axios';

const props = defineProps({
    service: String,
    toolReference: String,
    filter: Object,
    option: Object,
    mappings: Array,
});

    //const toolReference = ref('');

const queryClient = useQueryClient();
const productsQueryKey = computed(() => ['pricing-products', props.service, props.toolReference]);
const showDialog = ref(false);


const {
    data: products,
} = useQuery({
    queryKey: productsQueryKey.value,
    queryFn: () => {
        return axios(`api/Configuration/ListPricingProducts/${props.service}/${props.toolReference}`).then(res => res.data);
    },
}); 

const { mutate, isLoading: isSaving } = useMutation({
    mutationFn: async (request) => {
        return await axios.post('api/Configuration/SaveFilterMapping', JSON.stringify(request), {
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8',
            },
        }).then(res => {
            if (res.status === HttpStatusCode.Ok) {
                return res.data;
            }
            throw new Error(res.statusText);
        });
    },
    onSuccess: () => {
        ElMessage.success('Mapping saved.');
        queryClient.invalidateQueries({ queryKey: ['tool-mappings', props.service, props.toolReference] });
        showDialog.value = false;
    },
    onError: () => {
        ElMessage.error('Mapping could not be saved');
    }
});

const options = computed(() => {
    //  const mappedProducts = props.mappings?.filter(m => m.apiProductIds != products.value.productId)
    //      .flatMap(m => m.apiProductIds);
    return products.value?.map(product => ({
        key: String(product.productId),
        label: product.productName,
        //disabled: mappedProducts.includes(String(product.productId))
    })) || []
})

const mapping = computed(() => props.mappings?.find(m => m.apiProductId == products.value?.productId && m.option == props.option.option));
const value = ref(mapping?.value?.apiProductIds || []);
const mapCount = computed(() => mapping.value?.apiProductIds?.length || 0);

watch(() => mapping.value, (newValue) => {
    value.value = newValue?.apiProductIds || [];
})


function saveFilterMapping() {
    mutate({
        serviceType: props.service,
        apiProductIds: value.value,
        toolReference: props.toolReference,
        filter: props.filter.name,
        option: props.option.option
    });
}

const titles = { left: "Products", right: props.option.option };

</script>
<template>
    <div class="option">    
        <span>{{ props.option.option }}</span>
        <span class="mapping" @click="showDialog = true">{{ mapCount }} {{ mapCount == 1 ? 'product' : 'products' }}</span>
        <ElDialog v-model="showDialog" destroy-on-close>
            <p class="tip"><strong>{{ props.filter.name }} <br> </strong> Select the products from iPipeline to be mapped to
                the <strong>{{ props.option.option }}</strong> option.</p>
            <MultiSelect v-model="value" :data="options" :titles="titles" />
            <template #footer>
                <div class="dialog-footer">
                    <ElButton type="primary" link @click="showDialog = false">Cancel</ElButton>
                    <ElButton type="primary" :loading="isSaving" @click="saveFilterMapping">Save and close</ElButton>
                </div>
            </template>
        </ElDialog>
    </div>
</template>
<style scoped>
.dialog-footer {
    display: inline-flex;
    gap: 2rem;
}

.dialog-footer button {
    width: unset;
}

.divider {
    margin: 0;
}

.mapping {
    color: var(--el-color-primary);
    text-decoration: underline;
    cursor: pointer;
}


.option {
    display: flex;
    gap: 1rem;
    align-items: center;
}
</style>
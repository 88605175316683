<template>
    <div>
        <analysis :adverts="Adverts"
                  v-if="userSelection !== null && userSelection.tool != null && !(['IPPro - Pricing', 'CIPlus - Pricing', 'LifePro - Pricing', 'MPCIPro - Pricing', 'MPPro - Pricing', 'FIBCIPro - Pricing', 'FIBPro - Pricing', 'KeyPersonCIPro - Pricing', 'KeyPersonPro - Pricing'].includes(userSelection.tool.toolReference))"
                  v-on:back="$emit('back')"
                  v-bind:user-selection="userSelection"
                  v-bind:user-details="UserDetails"
                  @refine-selection="$emit('refine-selection')"
                  @amend-quality-analysis="$emit('amend-quality-analysis')"
                  v-on:edit-policy="$emit('edit-policy')" />

        <income-protection-analysis :adverts="Adverts"
                                    v-if="currentScreen == 'Analysis' && userSelection.tool?.toolReference === 'IPPro - Pricing'"
                                    v-on:back="$emit('back')" v-bind:user-selection="userSelection" v-bind:user-details="UserDetails"
                                    v-on:edit-policy="$emit('edit-policy')" @refine-selection="$emit('refine-selection')"
                                    v-on:amend-quality-analysis="$emit('amend-quality-analysis')" />

        <life-protection-analysis :adverts="Adverts"
                                  v-if="currentScreen == 'Analysis' && ['CIPlus - Pricing', 'LifePro - Pricing'].includes(userSelection.tool?.toolReference)"
                                  v-on:back="$emit('back')" v-bind:user-selection="userSelection" v-bind:user-details="UserDetails"
                                  @refine-selection="$emit('refine-selection')" @amend-quality-analysis="$emit('amend-quality-analysis')"
                                  v-on:edit-policy="$emit('edit-policy')" />

        <family-income-benefit-analysis :adverts="Adverts"
                                        v-if="currentScreen == 'Analysis' && ['FIBPro - Pricing', 'FIBCIPro - Pricing'].includes(userSelection.tool?.toolReference)"
                                        v-on:back="$emit('back')" v-bind:user-selection="userSelection" v-bind:user-details="UserDetails"
                                        @refine-selection="$emit('refine-selection')" @amend-quality-analysis="$emit('amend-quality-analysis')"
                                        v-on:edit-policy="$emit('edit-policy')" />

        <business-protection-analysis :adverts="Adverts"
                                      v-if="currentScreen == 'Analysis' && ['KeyPersonPro - Pricing', 'KeyPersonCIPro - Pricing'].includes(userSelection.tool?.toolReference)"
                                      v-on:back="$emit('back')" v-bind:user-selection="userSelection" v-bind:user-details="UserDetails"
                                      @refine-selection="$emit('refine-selection')" @amend-quality-analysis="$emit('amend-quality-analysis')"
                                      v-on:edit-policy="$emit('edit-policy')" />

        <mortgage-protection-analysis :adverts="Adverts"
                                      v-if="currentScreen == 'Analysis' && ['MPCIPro - Pricing', 'MPPro - Pricing'].includes(userSelection.tool?.toolReference)"
                                      v-on:back="$emit('back')" v-bind:user-selection="userSelection" v-bind:user-details="UserDetails"
                                      @refine-selection="$emit('refine-selection')" @amend-quality-analysis="$emit('amend-quality-analysis')"
                                      v-on:edit-policy="$emit('edit-policy')" />
    </div>
</template>

<script>

    import Analysis from "../components/Analysis.vue";
    import IncomeProtectionAnalysis from "../components/IncomeProtectionAnalysis.vue";
    import MortgageProtectionAnalysis from "../components/MortgageProtectionAnalysis.vue";
    import LifeProtectionAnalysis from "../components/LifeProtectionAnalysis.vue";
    import FamilyIncomeBenefitAnalysis from "../components/FamilyIncomeBenefitAnalysis.vue";
    import BusinessProtectionAnalysis from "../components/BusinessProtectionAnalysis.vue";

    export default {
        name: 'AnalysisPage',
        inject: ["SubscriptionService"],
        props: ["userSelection", "userDetails", "adverts", "currentScreen"],
        data: function () {
            return {
                advert: null,
            }
        },
        components: {
            Analysis,
            IncomeProtectionAnalysis,
            MortgageProtectionAnalysis,
            LifeProtectionAnalysis,
            FamilyIncomeBenefitAnalysis,
            BusinessProtectionAnalysis
        },
        computed: {
            productType: {
                get: function () {
                    switch (this.UserSelection.tool.toolReference) {
                        case "IPPro":
                            return "Income Protection";
                        case "LifePro":
                        case "MPPro":
                        case "CIPlus":
                        case "MPCIPro":
                        case "FIBPro":
                        case "FIBCIPro":
                        case "KeyPersonPro":
                        case "KeyPersonCIPro":
                            return "Term Assurance";
                        default:
                            throw Error("Unknown tool");
                    }
                }
            },
            UserSelection: {
                get: function () { return this.userSelection; }
            },
            UserDetails: {
                get: function () { return this.userDetails; }
            },
        },
        methods: {

        },
        created: function () {
        },
        watch: {
        },
        mounted: function () {
            
        }
    }
</script>
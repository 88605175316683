<template>
    <tr style="width:400px">
        <el-collapse v-model="activeNames" style="width:400px">
            <el-collapse-item :title="category.category" :name="category.category">
                <td :title="CategoryTooltip" style="margin-top:8px;">
                    Select level of importance
                </td>
                <td v-if="!IsDataTable" style="width: 100%; float: left; clear: left">

                    <select v-if="includCategories.includes(category.category)" v-model.number="Category.weight">
                        <option v-for="priority in GetPrioritiesForCategory()" :value="priority" :key="category.category + priority"> {{ priority == 0 ? "Excluded" :  priority }}</option>
                    </select>

                    <!--<el-select v-if="includCategories.includes(category.category)" v-model.number="category.weight" placeholder="Please select" style="width: 186px;">
                        <el-option :label="{{ priority == 0 ? "Excluded" :  priority}}" v-for="priority in GetPrioritiesForCategory()" value="priority" key="category.category + priority"></el-option>
                    </el-select>-->

                    <!--<select v-else-if="!includCategories.includes(category.category)" v-model.number="category.weight">
                        <option value="0">Excluded</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>-->

                    <el-select v-else-if="!includCategories.includes(category.category)" v-model.number="Category.weight" placeholder="Please select" style="width: 186px;margin-bottom:25px;">
                        <el-option label="Excluded" value="0"></el-option>
                        <el-option label="1" value="1"></el-option>
                        <el-option label="2" value="2"></el-option>
                        <el-option label="3" value="3"></el-option>
                        <el-option label="4" value="4"></el-option>
                        <el-option label="5" value="5"></el-option>
                    </el-select>
                </td>
                <td v-else>

                    <!--<select v-model.number="category.includedInDataTable">
                        <option value="0">Excluded</option>
                        <option value="1">Included</option>
                    </select>-->

                    <el-select  v-model.number="Category.includedInDataTable" placeholder="Please select" style="width: 186px;margin-bottom:25px;">
                        <el-option label="Excluded" value="0"></el-option>
                        <el-option label="Included" value="1"></el-option>
                    </el-select>
                </td>
            </el-collapse-item>
        </el-collapse>
        <!--<td :title="CategoryTooltip">
            {{category.category}}
        </td>
        <td v-if="!IsDataTable">
            <select v-if="includCategories.includes(category.category)" v-model.number="category.weight">
                <option v-for="priority in GetPrioritiesForCategory()" :value="priority" :key="category.category + priority"> {{ priority == 0 ? "Excluded" :  priority }}</option>
            </select>
            <select v-else-if="!includCategories.includes(category.category)" v-model.number="category.weight">
                <option value="0">Excluded</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
            </select>
        </td>
        <td v-else>
            <select v-model.number="category.includedInDataTable">
                <option value="0">Excluded</option>
                <option value="1">Included</option>
            </select>
        </td>-->
    </tr>

</template>

<style scoped>
    .el-collapse-item__header {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
    }

    .el-icon {
        content: "+";
    }
</style>

<script>

    export default {
        name: 'CategoryRow',
        props: ["category", "isDataTable", "includCategories", "expressTemplate", "categoryTooltip"],
        computed: {
            Category: function () { return this.category;},
            IsDataTable: function () { return this.isDataTable; },
            CategoryTooltip: function () { var a = this.categoryTooltip; return a;}
        },
        data() {
            return {
                activeNames: ['1']
            };
        },
        methods:
        {
            GetPrioritiesForCategory: function () {
                var thisControl = this;
                if (thisControl.expressTemplate != null) {
                    return (thisControl.expressTemplate.includedCategories.filter(function (ic) {
                        return ic.categoryReference === thisControl.category.category
                    })[0].availablePriorities).map(function (a) { return a; }).sort();
                }
            },
            SetDefaulPriorityForCategory: function () {
                var thisControl = this;
                var cat = this.category;
                if (thisControl.expressTemplate != null) {
                    thisControl.expressTemplate.includedCategories.forEach(function (ic) {
                        if (ic.categoryReference === thisControl.category.category)
                            cat.weight = ic.defaultPriority;
                    });
                }
                if (this.category.weight > 0)
                    this.activeNames = [this.category.category];
            },
        },
        mounted: function () {
            this.SetDefaulPriorityForCategory();
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

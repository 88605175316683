<script setup>
import { computed} from "vue";
import helper from '../components/HelperMethods.vue';

const props = defineProps(["userSelection", 'editCustomer']);
const UserSelection = computed(() => props.userSelection);

</script>

<template>
    <div class="summary">
        <label class="summaryTitle">Summary</label> <br />

        <div style="display:inline-flex">
            <label class="summaryLabels">Client details</label>
            <el-link v-on:click.stop="$emit('edit-customer')" class="summaryLabels"
                style="margin-left: 120px; text-decoration: underline">Edit</el-link>
        </div>

        <div v-if="UserSelection.primaryClient !== null">
            <label class="summaryDetail">{{ UserSelection.primaryClient.forename + " " +
                UserSelection.primaryClient.surname }}</label>
            <label class="summaryDetail">
                {{ helper.GetDisplayableDate(UserSelection.primaryClient.dateOfBirth) }} ,
                {{ helper.GetDisplayableClassification(UserSelection.primaryClient.classification) }}
            </label>
        </div>

        <div v-if="UserSelection.secondaryClient !== null">
            <label class="summaryDetail">{{ UserSelection.secondaryClient.forename + " " +
                UserSelection.secondaryClient.surname }}</label>
            <label class="summaryDetail">
                {{ helper.GetDisplayableDate(UserSelection.secondaryClient.dateOfBirth) }} ,
                {{ helper.GetDisplayableClassification(UserSelection.secondaryClient.classification) }}
            </label>
        </div>

        <hr style="border: 1px solid #A0A0A0;" />
        <label class="summaryLabels">Policy type</label>
        <label class="summaryDetail">{{ UserSelection.tool.toolName }}</label>
        <hr style="border: 1px solid #A0A0A0;" />

        <label class="summaryLabels">Policy details</label><br />
        <label class="summaryDetail">
            {{ (UserSelection.decreasingPolicy == true ? 'Decreasing Policy ' + 'Yes' : 'Decreasing Policy ' +  'No')  }}
                {{ (UserSelection.policyTerm !== null ? ', Term length ' + UserSelection.policyTerm : ', To age ' + UserSelection.toAge)  }}
                {{ (UserSelection.ipBenefitAmount !== 0 ? ', Benefit Amount ' + UserSelection.ipBenefitAmount : '') }}
                {{ (UserSelection.termBenefitAmount !== null ? UserSelection.tool.toolReference === 'FIBPro - Pricing' || UserSelection.tool.toolReference === 'FIBCIPro - Pricing' ? ', Annual life cover amount ' + UserSelection.termBenefitAmount : ', Life cover amount ' + UserSelection.termBenefitAmount : '') }}
                {{ (UserSelection.termCriticalIllnessCoverAmount !== null ? UserSelection.tool.toolReference === 'FIBCIPro - Pricing' ? ', Annual critical illness cover amount ' + UserSelection.termCriticalIllnessCoverAmount : ', Critical illness cover amount ' + UserSelection.termCriticalIllnessCoverAmount : '') }}
                {{ (UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount !== null ? UserSelection.tool.toolReference === 'FIBCIPro - Pricing' ? ', Annual life or earlier CI cover amount ' : ', Life or earlier CI cover amount ' + UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount : '') }}
        </label> <br />
        <hr style="border: 1px solid #A0A0A0;" />

        <div v-if="UserSelection.legacyPolicyProvider !== '' && this.UserSelection.showCIPolicy !== 'No'">
            <label class="summaryLabels">Type of analysis</label><br />
            <label class="summaryDetail">
                Historic policy comparison
                <br />
                {{ UserSelection.legacyPolicyProvider }}
            </label><br />
            <hr style="border: 1px solid #A0A0A0;" />
        </div>

        <label class="summaryLabels">Quality of plans</label>
        <label class="summaryDetail">{{ UserSelection.planType }}</label>
        <hr style="border: 1px solid #A0A0A0;" />

        <label class="summaryLabels">Additional info</label>
    </div>
</template>

<style scoped>
.summary {
    position: absolute;
    top: 30px;
    right: 150px;
    width: 232px;
    height: 572px;
}

.summaryTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 41px;
    letter-spacing: -0.04em;
    color: #0F172A;
}

.summaryLabels {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 36px;
    display: flex;
    align-items: center;
    letter-spacing: -0.04em;
    color: #A0A0A0;
}

.summaryDetail {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: -0.04em;
    width: 194px;
    height: 40px;
}
</style>
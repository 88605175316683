<template>
    <div v-if="isLoading" class="spinner-container">
        <div class="spinner"></div>
    </div>
    <div v-else class="subscription-settings">
        <h2 style="color:#1e228c"> Subscription settings </h2>
        <p class="section-title"> Subscription Status: </p>
        <div v-if="!data.cancelAt">
            <p v-if="data.status === 'active'" class="status-tag active"> Active subscriber </p>
            <p v-if="data.status === 'trialing'" class="status-tag trialing"> Trial </p>
        </div>
        <div v-else>
            <p class="status-tag canceled"> Canceled. </p>
            <p> Your plan ends on {{ formattedCcancelAt }}.</p>
        </div>

        <div v-if="!data.cancelAt">
            <div v-if="data.showSubscriptionDetails">
                <p class="section-title">Monthly license </p>
                <p> {{ formatCurrency(data.total) }} per month </p>
                <p class="section-title"> Next collection date: </p>
                <p> Your plan renews on {{ formattedCurrentPeriodEnd }}. </p>
                <p class="section-title">Options:</p>
                <p><a href="#" @click.prevent="cancelSubscription">Cancel license</a></p>
                <p><a href="#" @click.prevent="updatePaymentDetails">Update payment details</a></p>

            </div>
            <div v-else>
                <p v-if="showContactReference"> For more information about your subscription please contact to your Firm
                    administrator: <b>{{ subscription.contactEmail }}</b> </p>
                <p v-else> For more information about your subscription please contact to your Firm administrator. </p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject, onMounted, ref, computed } from 'vue';

const subscriptionService = inject('SubscriptionService');
const userService = inject('UserService');

const userId = computed(() => userService.getUserDetails()?.userIdentifier);
const pgpEmailAddress = computed(() => userService.getUserDetails()?.emailAddress);
const findSubscriptionDetails = computed(() => userService.getUserDetails()?.isFirmAdminUser || subscription.value.subscriptionType === "SingleUser");
const showContactReference = computed(() => subscription.value.subscriptionType !== "Grouped");

const formattedCurrentPeriodEnd = computed(() => {
    return formatDate(data.value.currentPeriodEnd);
});

const formattedCcancelAt = computed(() => {
    return formatDate(data.value.cancelAt);
});

const formatDate = (rawDate) => {
    if (!rawDate) return '';

    const date = new Date(rawDate);

    const formattedDate = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    return formattedDate;
};

const updatePaymentDetails = async () => {
    try {
        isLoading.value = true;
        var url = await subscriptionService.getCustomerPortalUrl(data.value.customerId);
        //window.open(url, '_blank');
        window.location.href = url;
    } catch (error) {
        console.error('Error updating payment details:', error);
    }
};

const cancelSubscription = async () => {
    try {
        isLoading.value = true;
        //console.log(userId);
        var url = await subscriptionService.getCancellationUrl(userId.value, subscription.value.pgSubscriptionId);
        //window.open(url, '_blank');
        window.location.href = url;
        isLoading.value = false;
    } catch (error) {
        console.error('Error cancelling subscription:', error);
    }
};

const data = ref({});
const subscription = ref({});
const isLoading = ref(false)

onMounted(async () => {
    try {
        isLoading.value = true;
        const subscriptionData = await subscriptionService.getSubscription();
        subscription.value = subscriptionData.value;
        //console.log(subscription);

        if (findSubscriptionDetails.value) {
            data.value = await subscriptionService.getSubscriptionSummary(subscription.value.stripeSubscriptionId);
            data.value.showSubscriptionDetails = pgpEmailAddress.value == data.value.customerEmail;
            //console.log(data.value);
        }

    } catch (error) {
        console.error('Error fetching subscription details:', error);
    }
    finally {
        isLoading.value = false;
    }
});

function formatCurrency(amount) {
    if (amount === null || amount === undefined) {
        return null;
    }
    const formattedAmount = (amount / 100).toFixed(2);
    // I am harcoding '£' but the currency code (gbp) is returning from backend. 
    return '£' + formattedAmount;
}

</script>

<style scoped>
.subscription-settings {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 550px;
    margin: 50px auto;
}

.subscription-settings h2 {
    margin-top: 0;
}

.subscription-settings p {
    margin: 10px 0;
}

.subscription-settings .section-title {
    font-weight: bold;
    margin-top: 20px;
}

.subscription-settings a {
    color: blue;
    text-decoration: none;
}

.subscription-settings a:hover {
    text-decoration: underline;
}

.status-tag.active {
    color: green;
}

.status-tag.canceled {
    color: dimgrey;
}

.status-tag.trialing {
    color: royalblue;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Ajusta según tus necesidades */
}

.spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-left-color: rgb(30, 34, 140);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
</style>

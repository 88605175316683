<template>

    <el-dialog title="Download Illustrations" :width="'750px'" v-model="IsVisible">
        <div style="max-height:400px; overflow-y:auto;">
            <p style="text-align:center;text-decoration:underline">
                Which product would you like to download the iIllustrations for?
            </p>

            <span>
                <collapsable-bar-chart :max-selection="1" chart-type="Selectable" :selection="selectedProduct" :chart-data="ChartData" :expanded-keys="ExpandedKeys" :alternate-value-title="ChartDataHeadings" />
            </span>
        </div>
        <p style="text-align:right">
            <el-button :type="selectedProduct.length !== 1 ? null : 'primary'" style="width:120px;" @click="$emit('download-illustration', selectedProduct[0])" :disabled="selectedProduct.length !== 1">
                Download Now
                <i class="el-icon-arrow-right"></i>
            </el-button>
        </p>
    </el-dialog>

</template>
<style>
    .el-dialog__headerbtn {
        width: unset !important;
        margin-right: 15px !important;
    }
</style>
<style scoped>
    .el-button--primary, .el-button--primary:hover {
        background: #1E228C !important;
    }

    .myRadio {
        text-indent: -12px;
        padding-left: 35px !important;
        width: 100%;
        min-height: 60px;
        margin-bottom: 10px;
    }

    .selectList {
        text-align: center;
    }

        .selectList .dtButton label {
            width: 350px;
        }
</style>
<script>
    import CollapsableBarChart from './CollapsableBarChart.vue';

    export default {
        name: 'ApplyNowModal',
        components: {
            CollapsableBarChart
        },
        props: ["chartData", "chartDataHeadings", "userSelection"],
        data: function () {
            return {
                selectedProduct: [],
                ExpandedKeys: { 'keys': [] },
                IsVisible: true
            }
        },
        computed: {
            ChartData: function () {
                var thisControl = this;
                var legacyProduct = thisControl.userSelection.filteredQuotes.find(function (p) { return p.productID === thisControl.userSelection.legacyPolicyProviderReference; });

                return {
                    ...thisControl.chartData,
                    items: thisControl.chartData.items.filter(function (p) { return !legacyProduct || p.key !== legacyProduct.reference; })
                };
            },
            ChartDataHeadings: function () { return this.chartDataHeadings; },
            UserSelection: function () { return this.userSelection; },
        },
        watch: {
            IsVisible: function (newValue) {
                if (newValue === false) {
                    this.$emit('cancelled');
                }
            }
        }
    }</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

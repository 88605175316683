import { ref } from 'vue';

var localSingleton = ref(null);
var localIsLoading = ref(false);

export default new class {
    userService;

    isloading() {
        return localIsLoading;
    }
    getSubscription() {
        return localSingleton;
    }
    async update() {
        var thisService = this;
        if (!localIsLoading.value) {
            localIsLoading.value = true;
            const searchParams = new URLSearchParams(window.location.search);
            let attempt = 0;
            let result;
            let isFirmAdmin;
            while (attempt < 10 && !result) {
                attempt++;
                result = await fetch("/api/Subscription")
                    .then(response => response.status === 204 ? null : response.json());

                localSingleton.value = result ? {
                    ...result,
                    totalCredits: result.credits + result.additionalCredits,
                } : undefined;

                if (!result && searchParams.has('PostPayment')) {
                    await new Promise(r => setTimeout(r, 1500));
                    continue;
                } else if (!searchParams.has('PostPayment')) {
                    break;
                }
            }

            isFirmAdmin = true;

            if (isFirmAdmin)
                thisService.getSubscriptionEmails();
            else
            {
                if (!localSingleton.value) {
                    thisService.redirectToSubscriptionPayment();
                } else {
                    window.history.replaceState({}, document.title, window.location.pathname);
                }
            }
            localIsLoading.value = false;
        }
    }
    hasCredit(customerRef, product, person) {
        return fetch("/api/Subscription/HasCredit/" + customerRef + "/" + product + "/" + person)
            .then(function (response) { return response.json(); })
    }
    purchaseCredit(customerRef, product, person, analysisRef, sessionRef) {
        var thisService = this;
        return fetch("/api/Subscription/PurchaseCredit/" + customerRef + "/" + product + "/" + person + "/" + analysisRef + "/" + sessionRef)
            .then(function (response) {
                thisService.update(); //Update Remaining Credits;
                if (response.status === 204)
                    return;
                else {
                    return response.json().then(function (e) { throw new Error(e.detail); });
                }
            })
    }

    getCustomerLockedStatus(customerRef) {
        return fetch("/api/Subscription/GetCustomerLockedStatus/" + customerRef)
            .then(function (response) { return response.text(); })
    }

    getAdditionalCredits() {
        var thisService = this;
        return fetch("/api/Subscription/AdditionalCredits")
            .then(function (response) { return response.text(); })
            .then(function (uri) {
                var w = window.open(uri);
                var timer = setInterval(function () {
                    if (w.closed) {
                        //wait for the window to close and the update the subscription status...
                        clearInterval(timer);
                        thisService.update();
                    }
                }, 1000);
            })
    }

    redirectToSubscriptionPayment() {
        return fetch(`/api/Subscription/SubscriptionPayment/${encodeURIComponent(window.location.href)}`)
            .then(function (response) { return response.text(); })
            .then(function (uri) {
                window.location.href = uri;
            })
    }

    getSubscriptionEmails() {
        //var thisService = this;
        return fetch(`/api/Subscription/SubscriptionEmails/${encodeURIComponent(window.location.href + '/api/signout')}`)
            .then(function (response) { return response.text(); })
            .then(function (uri) {
                if (!(uri == "" || uri == undefined)) {
                    window.location.href = uri;
                }
            })
    }

    getSubscriptionSummary(stripeSubscriptionId) {
        return fetch("/api/Subscription/GetSubscriptionSummary/" + stripeSubscriptionId)
            .then(function (response) { return response.json(); })
    }
    getCustomerPortalUrl(customerRef) {
        return fetch("/api/Subscription/GetCustomerPortalUrl/" + customerRef)
            .then(function (response) { return response.text(); })
    }
    getCancellationUrl(userId, subscriptionId) {
        return fetch("/api/Subscription/GetCancellationUrl/" + userId +"/" + subscriptionId )
            .then(function (response) { return response.text(); })
    }
}
<template>
    <div>
        <div v-if="IsGettingQuotes">
            <loading-control :loading-message="'Loading'" />
        </div>

        <div class="holder">
            <div class="mainPolicyDiv" v-if="!IsGettingQuotes">
                <p>
                    <span>
                        <label class="numbers">3</label>
                        <label class="clientDetails">Policy detail</label>
                    </span>

                    <span style="float:right">
                        <label class="percentageCompleted">60% completed</label>
                    </span>
                </p>
                <p>
                    <el-progress style="padding-top:5px;" class="el-progress--without-text" :percentage="60"></el-progress>
                </p>
                <div class="item">
                    <h1 class="Title">What are the policy details?</h1>
                </div>
                <el-form label-position="top" ref="ruleFormUserSelection" :model="UserSelection" :rules="rules">
                    <el-form-item v-if="Customers !== null && Customers.isLifeTwo" class="labels" label="Lives Assured Basis" prop="selectedCustomerType">
                        <el-select v-model="UserSelection.selectedCustomerType" placeholder="Please select">
                            <el-option :value="0" :label="Customers.life1FirstName + ' ' + Customers.life1LastName">{{Customers.life1FirstName + " " + Customers.life1LastName}}</el-option>
                            <el-option v-if="Customers.isLifeTwo" :value="1" :label="Customers.life2FirstName + ' ' + Customers.life2LastName">{{Customers.life2FirstName + " " + Customers.life2LastName}}</el-option>
                            <el-option :value="2" label="Both">Both</el-option>
                        </el-select>
                    </el-form-item>
                    <div style="display: inline-flex;">
                        <el-form-item label="Term length(years)" prop="policyTerm">
                            <el-input :min="5" step="1" :integer="true" style="width: 186px;" units="years" v-model.number="UserSelection.policyTerm"></el-input>
                        </el-form-item>

                        <el-form-item v-if="!(PolicyType !== '' && PolicyType === 'Joint')" label="To age" class="labels" style="padding-left:10px;" prop="toAge">
                            <el-input :min="21" step="1" :integer="true" :max="70" style=" width: 166px;" type="age" v-model.number="UserSelection.toAge"></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item label="Annual life cover amount" class="labels" prop="termBenefitAmount">
                        <el-input v-model.number="UserSelection.termBenefitAmount" type="number" min="5" max="99999.99" id="termBenefitAmount"></el-input>
                    </el-form-item>
                    <el-form-item v-if="UserDetails.defaultIntegration !== 'UNDERWRITEME'" label="Annual Critical illness cover amount" class="labels" prop="termCriticalIllnessCoverAmount">
                        <el-input v-model.number="UserSelection.termCriticalIllnessCoverAmount" type="number" min="5" max="99999.99" id="termBenefitAmount"></el-input>
                    </el-form-item>
                    <el-form-item v-if="UserDetails.defaultIntegration !== 'UNDERWRITEME'" label="Annual life or earlier CI cover amount" class="labels" prop="termLifeOrEarlierCriticalIllnessCoverAmount">
                        <el-input v-model.number="UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount" type="number" min="5" max="99999.99" id="termLifeOrEarlierCriticalIllnessCoverAmount"></el-input>
                    </el-form-item>
                    <el-form-item label="Waiver of premium" class="labels" prop="waiverOfPremium">
                        <el-select id="WaiverOfPremium" placeholder="Please select" v-model="UserSelection.waiverOfPremium">
                            <el-option label="None" value="None"></el-option>
                            <el-option label="First Life" value="FirstLife"></el-option>
                            <el-option label="Second Life" value="SecondLife" v-if="UserSelection.selectedCustomerType == 2"></el-option>
                            <el-option label="Both Lives" value="BothLives" v-if="UserSelection.selectedCustomerType == 2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Total permanent disability" prop="termTotalPermanentDisability" v-if="UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount !== null && UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount > 0 || UserSelection.termCriticalIllnessCoverAmount !== null && UserSelection.termCriticalIllnessCoverAmount > 0">
                        <el-select placeholder="Please select" v-model="UserSelection.termTotalPermanentDisability">
                            <el-option label="Yes" :value="true"></el-option>
                            <el-option label="No" :value="false"></el-option>
                        </el-select>
                    </el-form-item>
                    <div v-for="(child,childindex) in UserSelection.children" :key="childindex">
                        <div style="margin-bottom:10px;">
                            <label style="font-family: 'Inter'; font-style: normal; font-weight: 600; font-size: 14px; line-height: 24px;color: #0F172A;"> Child {{ childindex + 1 }} </label>
                            <el-link type="primary" @click="RemoveChild(child)" style="float:right;font-family: 'Inter'; font-style: normal; font-weight: 600; font-size: 14px; line-height: 24px; text-decoration-line: underline; color: #0F172A;"> Remove </el-link>
                        </div>
                        <el-form-item for="'Child + childindex + 1 + 'DOB'" class="labels" label="Date of birth">
                            <age-control v-model="child.dateOfBirth" />
                        </el-form-item>
                        <el-form-item for="'Child'+ childindex + 1 + 'Gender'" label="Child gender">
                            <el-select placeholder="Please select" v-bind:id="'Child' + childindex + 1 + 'Gender'" v-model="child.gender">
                                <el-option label="Male" value="Male"></el-option>
                                <el-option label="Female" value="Female"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div>
                        <el-link type="primary" @click="AddChild()" style="font-family: 'Inter'; font-style: normal; font-weight: 600; font-size: 14px; line-height: 24px; text-decoration-line: underline; color: #0F172A;"> {{ UserSelection.children.length == 0 ? 'Add child' :  'Add another child' }} </el-link>
                    </div>
                    <div v-if="UserSelection.children.length == 0 && false">
                        No children added
                    </div>
                    <div class="btnNextPosition">
                        <el-button type="default" v-on:click.stop="$emit('back')"> <span class="el-icon-arrow-left"></span>Back</el-button>
                        <el-button type="success" @click.stop="GetQuotes('ruleFormUserSelection')">Next</el-button>
                    </div>
                </el-form>
            </div>
            <div class="summary" v-if="Customers != null">
                <label class="summaryTitle">Summary</label> <br />

                <div style="display:inline-flex">
                    <label class="summaryLabels">Client details</label>
                    <el-link v-on:click.stop="$emit('edit-customer')" class="summaryLabels" style="margin-left: 120px; text-decoration: underline">Edit</el-link>
                </div>

                <div v-if="UserSelection.primaryClient !== null">
                    <label class="summaryDetail">{{UserSelection.primaryClient.forename + " " + UserSelection.primaryClient.surname}}</label>
                    <label class="summaryDetail">
                        {{helper.GetDisplayableDate(UserSelection.primaryClient.dateOfBirth)}} , {{helper.GetDisplayableClassification(UserSelection.primaryClient.classification)}}
                    </label>
                </div>

                <div v-if="UserSelection.secondaryClient !== null">
                    <label class="summaryDetail">{{UserSelection.secondaryClient.forename + " " + UserSelection.secondaryClient.surname}}</label>
                    <label class="summaryDetail">
                        {{helper.GetDisplayableDate(UserSelection.secondaryClient.dateOfBirth)}} , {{helper.GetDisplayableClassification(UserSelection.secondaryClient.classification)}}
                    </label>
                </div>

                <hr style="border: 1px solid #A0A0A0;" />
                <label class="summaryLabels">Policy type</label>

                <label class="summaryDetail">{{ UserSelection.tool.toolName }}</label>
                <hr style="border: 1px solid #A0A0A0;" />

                <label class="summaryLabels">Policy details</label>
                <hr style="border: 1px solid #A0A0A0;" />

                <label class="summaryLabels">Quality of plans</label>
                <hr style="border: 1px solid #A0A0A0;" />

                <label class="summaryLabels">Additional info</label>
            </div>
        </div>
    </div>
</template>
<style type="text/css" scoped>
    .Title {
        width: 366px;
        height: 20px;
        left: 0px;
        top: 66px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -0.02em;
        color: #0F172A;
    }

    .TitleInputs {
        width: 166px;
        height: 48px;
        /*left: 283px;
            top: 229px;*/
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -0.02em;
        color: #0F172A;
    }

    .el-input__inner {
        color: black;
    }

    .el-form--label-top .el-form-item__label {
        float: none;
        display: inline-block;
        text-align: left;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        color: #0F172A;
    }

    .summaryDetail {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        display: flex;
        align-items: center;
        /*letter-spacing: -0.04em;*/
        width: 194px;
        height: 40px;
    }

    .summaryLabels {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 36px;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;
        color: #A0A0A0;
    }

    .summary {
        position: absolute;
        width: 232px;
        height: 572px;
        top: 0;
        right: 150px;
    }

    .summaryTitle {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 41px;
        letter-spacing: -0.04em;
        color: #0F172A;
    }

    .el-link.el-link--default:hover {
        color: black !important;
    }

    .el-link.is-underline:hover:after {
        border: none;
    }

    .el-link.el-link--default:after {
        border: none;
    }

    .el-button {
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        align-items: center !important;
        padding: 10px !important;
        position: static !important;
        width: 167px !important;
        height: 44px !important;
        left: 199px !important;
        top: 0px !important;
        border-radius: 8px !important;
    }

    .el-button--success {
        color: #fff !important;
        background-color: #059669 !important;
        /*border-color: #059669 !important;*/
    }

        .el-button--success:focus, .el-button--success:hover {
            background: #059669 !important;
            border-radius: 8px !important;
            /*border-color: #059669 !important;*/
        }

        .el-button--success.is-disabled, .el-button--success.is-disabled:active, .el-button--success.is-disabled:focus, .el-button--success.is-disabled:hover {
            background: #E7EAEE !important;
            border-radius: 8px !important;
            /*border-color: #E7EAEE !important;*/
            font-style: normal !important;
            color: #A0ABBB !important;
        }

    .el-button--default {
        color: black !important;
        background-color: white !important;
        border: 1px solid #191D23 !important;
    }

    /*    .el-button--default:focus, .el-button--default:hover {
            background: white;
            border-radius: 8px;
            border: 1px solid #191D23;
            border-radius: 8px;
            color: black;
        }*/

    .btnNextPosition {
        display: inline-flex;
        padding-top: 20px;
        padding-left: 10px;
    }

    .labels {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 24px;
        color: #0F172A;
    }

    .numbers {
        background: #EEEEEE;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        background: #EEEEEE;
        border-radius: 15px;
        padding: 2px;
    }

    .clientDetails {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        align-items: center;
        letter-spacing: -0.04em;
        padding-left: 10px;
    }

    .percentageCompleted {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: -0.04em;
    }

    .holder {
        max-width: 1144px;
        min-width: 1044px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 24px;
        position: relative;
    }

    .mainPolicyDiv {
        padding: 82px;
        position: absolute;
        width: 531px;
        left: 0;
        top: 0;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 8px;
    }
    /*.noBorder {
        border: none;
    }

    .ddlLabel {
        width: 115%*/ /* This hides the arrow icon */ /*;
        background-color: transparent*/ /* This hides the background */ /*;
        background-image: none;
        -webkit-appearance: none*/ /* Webkit Fix */ /*;
        border: none;
        box-shadow: none;
        padding: 0.3em 0.5em;
    }*/
</style>
<script>
import $ from 'jquery';
import LoadingControl from './LoadingControl.vue';
import AgeControl from './AgeControl.vue';
import helper from './HelperMethods.vue';
export default
    {
        components: { LoadingControl, AgeControl },
        name: 'PolicyDetails',
        props: ["userSelection", "userDetails"],
        watch: {
            'UserSelection.policyTerm': function (newValue) {
                if (newValue !== null)
                    this.UserSelection.toAge = null;
            },
            'UserSelection.toAge': function (newValue) {
                if (newValue !== null)
                    this.UserSelection.policyTerm = null;
            },
            'UserSelection.termBenefitAmount': function (newValue) {
                if (newValue !== null) {
                    if (this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount !== null)
                        this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount = null;

                    if (newValue == 0)
                        this.UserSelection.termBenefitAmount = null;
                }
                this.CheckTool();
            },
            'UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount': function (newValue) {
                if (newValue !== null) {
                    if (this.UserSelection.termBenefitAmount !== null)
                        this.UserSelection.termBenefitAmount = null;
                    if (this.UserSelection.termCriticalIllnessCoverAmount !== null)
                        this.UserSelection.termCriticalIllnessCoverAmount = null;

                    if (newValue == 0)
                        this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount = null;
                }
                this.CheckTool();
            },
            'UserSelection.termCriticalIllnessCoverAmount': function (newValue) {
                if (newValue !== null) {
                    if (this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount !== null)
                        this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount = null;

                    if (newValue == 0)
                        this.UserSelection.termCriticalIllnessCoverAmount = null;
                }
                this.CheckTool();
            },
            'UserSelection.selectedCustomerType': function () {

                this.PolicyType = "Single";

                if (this.UserSelection.selectedCustomerType === 0 && this.Customers !== null && this.Customers !== undefined) {
                    this.PrimaryClientGender = this.Customers.life1Gender;
                    this.PrimaryClientSmokerStatus = this.Customers.life1SmokingStatus;

                    this.UserSelection.primaryClient.dateOfBirth = this.Customers.life1DateOfBirth;
                    this.UserSelection.primaryClient.forename = this.Customers.life1FirstName;
                    this.UserSelection.primaryClient.personEmployedStatus = "Employed";
                    this.UserSelection.primaryClient.salary = this.Customers.life1Salary;
                    this.UserSelection.primaryClient.surname = this.Customers.life1LastName;
                    this.UserSelection.primaryClient.title = this.Customers.life1Title;
                    this.UserSelection.primaryClient.occupation = this.Customers.life1Occupation;
                }
                else if (this.UserSelection.selectedCustomerType === 1 && this.Customers !== null && this.Customers !== undefined) {

                    this.PrimaryClientGender = this.Customers.life2Gender;
                    this.PrimaryClientSmokerStatus = this.Customers.life2SmokingStatus;

                    this.UserSelection.primaryClient.dateOfBirth = this.Customers.life2DateOfBirth;
                    this.UserSelection.primaryClient.forename = this.Customers.life2FirstName;
                    this.UserSelection.primaryClient.personEmployedStatus = "Employed";
                    this.UserSelection.primaryClient.salary = this.Customers.life2Salary;
                    this.UserSelection.primaryClient.surname = this.Customers.life2LastName;
                    this.UserSelection.primaryClient.title = this.Customers.life2Title;
                    this.UserSelection.primaryClient.occupation = this.Customers.life2Occupation;
                }
                else if (this.UserSelection.selectedCustomerType === 2 && this.Customers !== null && this.Customers !== undefined) {
                    this.PrimaryClientGender = this.Customers.life1Gender;
                    this.PrimaryClientSmokerStatus = this.Customers.life1SmokingStatus;

                    this.UserSelection.primaryClient.dateOfBirth = this.Customers.life1DateOfBirth;
                    this.UserSelection.primaryClient.forename = this.Customers.life1FirstName;
                    this.UserSelection.primaryClient.personEmployedStatus = "Employed";
                    this.UserSelection.primaryClient.salary = this.Customers.life1Salary;
                    this.UserSelection.primaryClient.surname = this.Customers.life1LastName;
                    this.UserSelection.primaryClient.title = this.Customers.life1Title;
                    this.UserSelection.primaryClient.occupation = this.Customers.life1Occupation;

                    if (this.Customers.isLifeTwo) {
                        this.PolicyType = "Joint";
                    }

                    this.SecondaryClientGender = this.Customers.life2Gender;
                    this.SecondaryClientSmokerStatus = this.Customers.life2SmokingStatus;

                    this.UserSelection.secondaryClient.dateOfBirth = this.Customers.life2DateOfBirth;
                    this.UserSelection.secondaryClient.forename = this.Customers.life2FirstName;
                    this.UserSelection.secondaryClient.personEmployedStatus = "Employed";
                    this.UserSelection.secondaryClient.salary = this.Customers.life2Salary;
                    this.UserSelection.secondaryClient.surname = this.Customers.life2LastName;
                    this.UserSelection.secondaryClient.title = this.Customers.life2Title;
                    this.UserSelection.secondaryClient.occupation = this.Customers.life2Occupation;
                }
                this.UserSelection.waiverOfPremium = "None";
            },
        },
        data: function () {
            var thisControl = this;
            return {
                helper: helper,
                IsGettingQuotes: false,
                showingSmokerDefinitions: false,
                Customers: [],
                ruleFormUserSelection: {
                    selectedCustomerType: '',
                    policyTerm: null,
                    toAge: null,
                    termTotalPermanentDisability: '',
                    termLifeOrEarlierCriticalIllnessCoverAmount: '',
                    termCriticalIllnessCoverAmount: '',
                    termBenefitAmount: '',
                    waiverOfPremium: '',
                },
                rules: {
                    selectedCustomerType: [
                        { required: true, message: 'Please select customer type', trigger: 'change' }
                    ],
                    policyTerm: [
                        {
                            validator: function (rule, value, callback) {
                                if (thisControl.UserSelection.policyTerm === null || thisControl.UserSelection.policyTerm === "")
                                    if (thisControl.UserSelection.toAge === null || thisControl.UserSelection.toAge === "") {
                                        callback(new Error("Required if to age is not supplied."))

                                    }
                                if (value !== null && value !== "" && typeof (value) !== "number") {
                                    callback(new Error("Term length must be a number."))
                                }
                                callback();//all good here
                            },
                            trigger: 'change'
                        }
                    ],
                    toAge: [
                        {
                            validator: function (rule, value, callback) {
                                if (thisControl.UserSelection.policyTerm === null || thisControl.UserSelection.policyTerm === "")
                                    if (thisControl.UserSelection.toAge === null || thisControl.UserSelection.toAge === "") {
                                        callback(new Error("Required if term length is not supplied."))

                                    }
                                if (value !== null && value !== "" && typeof (value) !== "number") {
                                    callback(new Error("To age must be a number."))
                                }
                                callback();//all good here
                            },
                            trigger: 'change'
                        }
                    ],
                    termTotalPermanentDisability: [
                        { required: true, message: 'Please select Total permanent disability', trigger: 'change' },
                    ],
                    termBenefitAmount: [
                        {
                            validator: function (rule, value, callback) {
                                if (thisControl.UserSelection.termBenefitAmount === null || thisControl.UserSelection.termBenefitAmount === "")
                                    if (thisControl.UserSelection.termCriticalIllnessCoverAmount === null || thisControl.UserSelection.termCriticalIllnessCoverAmount === "") {
                                        if (thisControl.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount === null || thisControl.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount === "") {
                                            callback(new Error("Required if 'Critical illness amount' or 'Life or earlier CI cover amount' is not supplied."))

                                        }
                                    }
                                if (value !== null && value !== "" && typeof (value) !== "number") {
                                    callback(new Error("life cover amount must be a number."))
                                }
                                callback();//all good here
                            },
                            trigger: 'change'
                        }
                    ],
                    termCriticalIllnessCoverAmount: [
                        {
                            validator: function (rule, value, callback) {
                                if (thisControl.UserSelection.termCriticalIllnessCoverAmount === null || thisControl.UserSelection.termCriticalIllnessCoverAmount === "")
                                    if ((thisControl.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount === null || thisControl.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount === "")
                                        && (thisControl.UserSelection.termTotalPermanentDisability === null || thisControl.UserSelection.termTotalPermanentDisability === "")) {
                                        callback(new Error("Required if Life or earlier CI cover amount is not supplied and select Total permanent disability."))
                                    }
                                if (value !== null && value !== "" && typeof (value) !== "number") {
                                    callback(new Error("Critical illness cover amount must be a number."))
                                }
                                callback();//all good here
                            },
                            trigger: 'change'
                        }
                    ],
                    termLifeOrEarlierCriticalIllnessCoverAmount: [
                        {
                            validator: function (rule, value, callback) {
                                if (thisControl.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount === null || thisControl.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount === "")
                                    if ((thisControl.UserSelection.termBenefitAmount === null || thisControl.UserSelection.termBenefitAmount === "")
                                        && (thisControl.UserSelection.termCriticalIllnessCoverAmount === null || thisControl.UserSelection.termCriticalIllnessCoverAmount === "")
                                        && (thisControl.UserSelection.termTotalPermanentDisability === null || thisControl.UserSelection.termTotalPermanentDisability === "")) {
                                        callback(new Error("Required if Life cover amount & Critical illness cover amount is not supplied and select Total permanent disability"))
                                    }
                                if (value !== null && value !== "" && typeof (value) !== "number") {
                                    callback(new Error(" Life or earlier CI cover amount must be a number."))
                                }
                                callback();//all good here
                            },
                            trigger: 'change'
                        }
                    ],
                    waiverOfPremium: [
                        { required: false, message: 'Please select Waiver of premium', trigger: 'change' }
                    ]
                },
            }
        },
        created: function () {
            var thisControl = this;
            thisControl.UserSelection.ipCommissionType = thisControl.UserDetails.commissionType;
            thisControl.UserSelection.ipIndemnityRequired = thisControl.UserDetails.indemnityRequired;
            thisControl.UserSelection.ipCommissionPercentageRequired = thisControl.UserDetails.commissionPercentageRequired;

            if (thisControl.UserSelection.primaryClient === null) {
                this.UserSelection.primaryClient = {
                    classification: "Male_NonSmoker", dateOfBirth: '1990-01-01', title: '', surname: '', forename: '', salary: 0, personEmployedStatus: 'Employed', termFractureCover: false
                };
            }
            if (this.UserSelection.selectedCustomerType == null) {
                thisControl.UserSelection.primaryClient = { classification: "", dateOfBirth: '1990-01-01', title: '', surname: '', forename: '', salary: 0, personEmployedStatus: 'Employed', termFractureCover: false };
                thisControl.PrimaryClientGender = '';
                thisControl.PolicyType = '';
            }
            else
                thisControl.UserSelection.selectedCustomerType = parseInt(thisControl.UserSelection.selectedCustomerType);

            thisControl.GetCustomer();

            if (thisControl.UserSelection.children === null) {
                this.UserSelection.children = [];
                this.UserSelection.childNames = [];
            }
        },
        mounted: function () {
            this.UserSelection.expressTemplate = null;
        },
        methods: {
            GetQuotes: function (formName) {
                var thisControl = this;
                this.$refs[formName].validate(function (valid) {
                    if (valid) {
                        thisControl.CheckTool();
                        thisControl.$emit('policy-details-added');
                    } else {
                        alert("Please correct errors before continuing.")
                    }
                });
            },
            CanProceed: function () {
                return this.UserSelection.policyTerm > 0 && this.UserSelection.amountAssured > 0;
            },
            CheckTool: function () {
                var hasCI = this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount !== null || this.UserSelection.termCriticalIllnessCoverAmount;
                let selectedTool = this.UserSelection.tool.toolReference;
                if (hasCI) {
                    selectedTool = 'FIBCIPro - Pricing';
                } else {
                    selectedTool = 'FIBPro - Pricing';
                }

                if (
                    (this.UserSelection.termCriticalIllnessCoverAmount === null || this.UserSelection.termCriticalIllnessCoverAmount <= 0)
                    && (this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount === null || this.UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount <= 0)
                ) {
                    this.UserSelection.termTotalPermanentDisability = false;
                }

                this.$emit("tool-updated", selectedTool);
            },
            GetSmokerToolTip: function () {
                var tooTips =
                    ["Current smoker - Any adult who smokes currently on a regular basis.",
                        "Ex-regular smokers - Any adult that has smoked regularly in the past, but not currently.",
                        "Non-smokers - Any adult that has never smoked on a regular basis."];

                return tooTips.reduce(function (sofar, tooTip) { return sofar + "\n" + tooTip; }, "").trim();
            },
            AddChild: function () {
                this.UserSelection.children.push({
                    gender: "Male",
                    dateOfBirth: '2015-01-01'
                });
                this.UserSelection.childNames.push("");
            },
            RemoveChild: function (child) {
                this.UserSelection.childNames.splice(this.UserSelection.children.indexOf(child), 1);
                this.UserSelection.children.splice(this.UserSelection.children.indexOf(child), 1);
            },
            GetCustomer: function () {
                var thisControl = this;
                this.Customers = null;

                $.ajax({
                    type: 'GET',
                    url: '/Customer/' + thisControl.UserSelection.customerReference,
                    data: {},
                    success: function (customer) {
                        if (customer.customerReference === null)
                            customer.customerReference = thisControl.UserSelection.customerReference;

                        thisControl.Customers = customer;

                        if (thisControl.Customers.life1DateOfBirth !== null)
                            thisControl.Customers.life1DateOfBirth = customer.life1DateOfBirth.slice(0, 10);

                        if (thisControl.Customers.life2DateOfBirth !== null)
                            thisControl.Customers.life2DateOfBirth = customer.life2DateOfBirth.slice(0, 10);

                        if (!customer.isLifeTwo)
                            thisControl.UserSelection.selectedCustomerType = 0;
                    },
                    error: function (xhr) {
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                        thisControl.$message.error("Error updating customer: " + xhr.responseText);
                    }
                });
            },
        },
        computed: {
            DecreasingPolicy: function () { return true; },
            UserSelection: function () { return this.userSelection; },
            UserDetails: function () { return this.userDetails; },
            ShouldAskForChildren: {
                get: function () {
                    return this.UserSelection.features.some(function (f) { return f.requiresChildrensDetails === true; });
                }
            },
            PrimaryClientGender: {
                get: function () {
                    if (this.UserSelection.primaryClient.classification.startsWith("Male"))
                        return "Male";
                    else if (this.UserSelection.primaryClient.classification.startsWith("Female"))
                        return "Female";
                    else
                        return "";
                },
                set: function (value) {
                    this.UserSelection.primaryClient.classification = value + this.PrimaryClientSmokerStatus
                }
            },
            PrimaryClientSmokerStatus: {
                get: function () {
                    return this.UserSelection.primaryClient.classification.replace(this.PrimaryClientGender, "")
                },
                set: function (value) {
                    this.UserSelection.primaryClient.classification = this.PrimaryClientGender + value;
                }
            },
            SecondaryClientGender: {
                get: function () {
                    if (this.UserSelection.secondaryClient.classification.startsWith("Male"))
                        return "Male";
                    return "Female";
                },
                set: function (value) {
                    this.UserSelection.secondaryClient.classification = value + this.SecondaryClientSmokerStatus
                }
            },
            SecondaryClientSmokerStatus: {
                get: function () {
                    return this.UserSelection.secondaryClient.classification.replace(this.SecondaryClientGender, "")
                },
                set: function (value) {
                    this.UserSelection.secondaryClient.classification = this.SecondaryClientGender + value;
                }
            },
            PolicyType: {
                get: function () {

                    if (this.UserSelection.selectedCustomerType === null)
                        return '';

                    return this.UserSelection.secondaryClient === null && this.UserSelection.selectedCustomerType !== null ? "Single" : "Joint";
                },
                set: function (value) {
                    if (value == "Joint") {
                        this.UserSelection.secondaryClient = { occupation: null, classification: "Male_NonSmoker", dateOfBirth: '1990-01-01', title: '', surname: '', forename: '', salary: 0, personEmployedStatus: 'Employed', termFractureCover: false };
                    }
                    else {
                        this.UserSelection.secondaryClient = null;
                    }
                }
            },
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<script setup>
import PricingServiceMapping from './PricingServiceMapping.vue'
import { ref } from 'vue';
import iPipelineLogo from '../../assets/images/iPipelineLogo.png';
import ExcludedProducts from './ExcludedProducts.vue';

const selectedService = ref('');

</script>
<template>
    <div class="container">
        <div>{{ isLoadingToolProducts }}</div>
        <div v-if="isLoading">Loading....</div>
        <div v-else>
            <h1>Products Configuration</h1>
            <div class="logos-container">
                <div @click="selectedService = 'non-pricing'"
                    :class="{ 'logo': true, 'active': selectedService === 'non-pricing' }">
                    <h4>Non-Pricing</h4>
                </div>
                <div @click="selectedService = 'IPIPELINE'"
                    :class="{ 'logo': true, 'active': selectedService === 'IPIPELINE' }">
                    <img alt="iPipeline" :src="iPipelineLogo" />
                </div>
            </div>           
        </div>
        <ExcludedProducts v-if="selectedService=== 'non-pricing'" />
        <PricingServiceMapping v-else-if="!!selectedService" :service="selectedService" />
    </div>
</template>
<style scoped>
.container{
    padding: 0 1rem;
}
.logos-container {
    display: flex;
    gap: 1rem;
}

.logo {
    border: 1px solid #3c3c3c;
    border-radius: 0.5rem;
    padding: 0.5rem;
    width: 8rem;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    box-sizing: border-box;
    cursor: pointer;
}

.logo.active {
    border: 2px solid var(--el-color-primary);
}

.logo img {
    width: 100%;
}
</style>

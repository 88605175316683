<template>
    <div>
        <fieldset class="selectList">
            <legend>
                Select Providers <span v-if="maxProviders != null">(Maximum {{maxProviders}}) - {{RemainingProviders()}} Remaining</span>
            </legend>
            <button class="button250" v-if="maxProviders === null" v-on:click.stop="SelectAllProviders();$emit('provider-selection-completed');">Use whole of market</button>
            <button class="button250" v-if="isInsurersHidden == true" v-on:click="isInsurersHidden = false">Select Products (Insurers)</button>
            <div v-if="!isInsurersHidden">
                <p>Please select/deselect the products you would like to include in your comparison (products highlighted in blue will be included):</p>
                <div v-for="provider in UserSelection.providers" :key="provider.providerReference">
                    <input :disabled="IsDisabled(provider.providerReference)" v-bind:id="'Provider' + provider.providerReference" type="checkbox" hidden="hidden"
                           v-model="UserSelection.selectedProviders" v-bind:value="provider.providerReference" />
                    <label v-bind:for="'Provider' + provider.providerReference">{{provider.providerName}}</label>
                </div>
            </div>
            <br v-if="isInsurersHidden" />
        </fieldset>
        <fieldset class="navigation analysisbottomMargin50">
            <button v-on:click.stop="$emit('back')">Back</button>
            <button :disabled="isInsurersHidden || UserSelection.selectedProviders.length === 0" class="button250" v-on:click.stop="$emit('provider-selection-completed')">Next</button>
        </fieldset>
    </div>
</template>

<script>export default {
        name: 'ProviderSelection',
        props: ["userSelection", "userDetails", "datatableTemplate"],
        data: function () {
            return {
                isInsurersHidden: true
            }
        },
        methods: {
            SelectAllProviders: function () {
                this.UserSelection.selectedProviders = this.UserSelection.providers.map(function (provider) { return provider.providerReference; });
            },
            DeselectAllProviders: function () {
                this.UserSelection.selectedProviders = [];
            },
            RemainingProviders: function () {
                return this.maxProviders - this.UserSelection.selectedProviders.length;
            },
            IsDisabled: function (providerID) {
                return this.maxProviders !== null && (this.maxProviders - this.UserSelection.selectedProviders.length) <= 0 && !this.UserSelection.selectedProviders.some(function (id) { return id === providerID });
            }
        },
        created: function () {
            if (this.UserSelection.selectedProviders.length == 0 && this.DatatableTemplate !== null && typeof (this.DatatableTemplate) !== 'undefined') {
                this.UserSelection.selectedProviders = this.DatatableTemplate.includedProviders;
            }
            this.isInsurersHidden = this.maxProviders === null && this.UserSelection.selectedProviders.length == 0;
        },
        computed: {
            UserSelection: function () { return this.userSelection; },
            UserDetails: function () { return this.userDetails; },
            DatatableTemplate: function () { return this.datatableTemplate; },
            maxProviders: {
                get: function () {
                    if (this.UserSelection.analysisType == "DataTables")
                        return this.UserDetails.maxProvidersPerDataTable;
                    else
                        return this.UserDetails.maxProvidersPerComparison;

                }
            }
        }
    }</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<template>
    <div class="main" style="width:100%;max-width:1144px;margin-left:auto; margin-right:auto; ">
        
        <div class="box-card" v-if="!userDetails.isFirmAdminBuyLicenceHerSelf">
            <i>
                You don't have access to ProtectionGuruPro, please contact us at <a href="mailto:info@protectionguru.co.uk">info@protectionguru.co.uk</a>
            </i>
        </div>

        <div class="box-card" v-if="userDetails.isFirmAdminBuyLicenceHerSelf">
            <div class="item">
                <p class="Heading4">What analysis would you like to run?</p>
            </div>
            <div class="radioMargin">
                <el-radio v-model="analysisType" class="radioElement Paragraph2" label="Comparison" border>Client specific analysis</el-radio>
            </div>
            <div class="radioMargin">
                <el-radio v-model="analysisType" class="radioElement Paragraph2" label="DetailedProductData" border>View product data</el-radio>
            </div>
            <div class="btnNextPosition">
                <el-button :disabled="analysisType === ''" type="success" v-on:click.stop="Next">Next</el-button>
            </div>
        </div>

        <div v-if="IsDeclinedTandCs" class="ModalBackground" style="display: block;">
            <div class="ModalBackGroundPanel" style="height: auto; width: 600px; height: 171px; background: #FFFFFF; border-radius: 8px;overflow:hidden;">
                <!--<div class="Options" style="padding-top:20px;">
                    <i v-on:click.stop="$emit('back')" class="el-icon-close" style="width: 24px; height: 54px; font-size: xx-large;"></i>
                </div>-->
                <div class="header" style="border-bottom:none">

                    <h3 class="Title">
                        Are you sure your want to decline?
                        <i v-on:click.stop="$emit('back')" class="el-icon-close" style="float:right;margin-left:125px;"></i>
                    </h3>
                </div>
                <p style="font-family: 'Inter'; font-style: normal; font-weight: 400; font-size: 14px; line-height: 19px; color: #191D23;">
                    Please not if you decline you wont be able to use the service
                </p>
                <div class="navigation" style="display:inline-flex;float:right">
                    <el-button type="default" class="elDecline" v-on:click.stop="DeclineTandCsLogout">I want to decline</el-button>
                    <el-button type="success" class="elConsent" v-on:click.stop="AcceptTandCs">Consent and continue</el-button>
                </div>
            </div>
        </div>

    </div>

</template>
<style>
    .el-button--success {
        border:none !important;
        border-color: none !important;
    }
</style>
<style scoped>
    /* ipad Mini Landscape */
    @media only screen and (width:1024px) {
        .box-card {
            margin-left: -90px;
        }
    }

    .Decline {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 19px;
        line-height: 23px;
        letter-spacing: -0.02em;
        color: #2D2D2D;
    }

    .btnNextPosition {
        padding-left: 180px;
        padding-top: 20px;
        padding-right: 30px;
        flex: none;
        order: 1;
        flex-grow: 0;
        background: #FFFFFF;
        border-radius: 8px;
    }

    .Title {
        margin-bottom: 7px;
        margin-top: 10px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 34px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #0F172A;
        /*padding-left: 15px;*/
        padding-right: 15px;
    }

    .el-container {
        background-color: #E0E0E0 !important;
    }

    .Heading4 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -0.02em;
        color: #0F172A;
    }

    .el-radio {
        width: 350px;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .radioMargin {
        padding-top: 20px;
        padding-right: 30px;
        flex: none;
        order: 1;
        flex-grow: 0;
        background: #FFFFFF;
        border-radius: 8px;
        float: right;
    }


    .box-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 82px;
        width: 530px;
        height: 450px;
        left: 350px;
        margin-top: 24px;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .el-radio__input.is-checked + .el-radio__label {
        color: black;
    }

    .el-radio__input.is-checked .el-radio__inner {
        border-color: none !important;
        background: none !important;
    }

    .el-radio.is-bordered.is-checked {
        color: black;
        background: #E8EDFB;
        border: 1px solid #6183E4;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .el-radio__input.is-checked + .el-radio__label {
        color: black;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
    }

    .el-card.is-always-shadow {
        box-shadow: none;
    }

    .el-button--success {
        color: #fff !important;
        background-color: #059669 !important;
        /*border-color: #059669 !important;*/
    }

    .el-button--default {
        color: black !important;
        background-color: white !important;
        border: 1px solid #191D23 !important;
    }

    .el-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        position: static;
        width: 167px;
        height: 44px;
        left: 199px;
        top: 0px;
        background: #059669 !important;
        border-radius: 8px;
    }

        .el-button:not(.is-text,.is-link,.el-button--text) {
            background-color: #059669 !important;
            border: 8px;
            /*border-color: #059669 !important;*/
        }

    .el-button--success:focus, .el-button--success:hover {
        background: #059669 !important;
        border-radius: 8px;
        /*border-color: #059669 !important;*/
    }

    .el-button--success.is-disabled, .el-button--success.is-disabled:active, .el-button--success.is-disabled:focus, .el-button--success.is-disabled:hover {
        background: #E7EAEE;
        border-radius: 8px;
        /*border-color: #E7EAEE;*/
        font-style: normal;
        color: #A0ABBB;
    }

    .elPopup {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
        position: relative;
        width: 744px;
        height: 636px;
        background: #FFFFFF;
        border-radius: 8px;
    }

    .elPopupBody {
        display: inline-block;
        overflow-y: auto;
        width: 720px;
        height: 466px;
        margin-bottom: 16px;
    }

    .elDecline, .elDecline:hover, .elDecline:focus {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        position: static;
        color: black;
        width: 143px;
        height: 48px;
        left: 219px;
        top: 0px;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 8px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 14px;
        background: #FFFFFF;
        /**/
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #4B5768;
    }

    .elConsent {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        position: static;
        width: 182px;
        height: 48px;
        left: 370px;
        top: 0px;
        background: #059669;
        border-radius: 8px;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0px 14px;
        /**/
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #FFFFFF;
    }

    .Paragraph2TC {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #191D23;
        padding-left: 15px;
        padding-right: 15px;
    }
</style>



<script>
    import $ from 'jquery';

    export default {
        name: 'ReportSelection',
        props: ["userDetails", "isFromFooter"],
        data: function () {
            return {
                analysisType: '',
                IsOpenTandCs: false,
                IsDeclinedTandCs: false
            }
        },
        created: function () {
            if (!this.UserDetails.hasAcceptedTermsAndCondiditions) {
                this.IsOpenTandCs = true;
                return;
            }
            if (!this.UserDetails.isFirmAdminBuyLicenceHerSelf) {
                return;
            }
            if (!this.UserDetails.hasAccessToDataTables) {
                this.$emit('comparison-selected');
            }

        },
        methods:
        {
            AcceptTandCs: function () {
                var thisControl = this;

                $.ajax({ //get redirect uri
                    type: 'GET',
                    url: '/api/AcceptTermsAndConditions',
                    contentType: 'application/json',
                    success: function () {
                        window.location = "/";
                    },
                    error: function (xhr) {
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                        thisControl.$message.error("An error occured: " + xhr.responseText);
                    }
                });
            },
            DeclineTandCs: function () {
                this.IsOpenTandCs = false;
                this.IsDeclinedTandCs = true;
            },
            DeclineTandCsLogout: function () {
                window.location = "/Api/signout";
            },
            Next: function () {
                if (this.analysisType === "DetailedProductData")
                    this.$emit('datatable-selected');
                else if (this.analysisType === "Comparison")
                    this.$emit('comparison-selected');
                else
                    this.$message.error("Please select any anylysis type.");

            }
        },
        computed: {
            UserDetails: function () { return this.userDetails; },
            IsFromFooter: function () { return this.isFromFooter; }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<template>
    <div>
        <fieldset>
            <legend>
                Features in Detailed Product Data <span v-if="maxFeatures != null">(Maximum {{maxFeatures}}) - {{RemainingFeatures}} Remaining</span>
            </legend>
            <p>Please select the areas which are important to you</p>
            <table class="analysisStyles DataTableFeatureSelection">
                <thead>
                    <tr>
                        <th>Feature</th>
                        <th>Subfeatures</th>
                    </tr>
                </thead>
                <tbody v-for="cat in categories" :key="cat">
                    <tr>
                        <th colspan="2">
                            {{cat}}
                        </th>
                    </tr>
                    <tr v-for="Feature in UserSelection.extendedFeatures.filter(function(ef) {return ef.featureCategory == cat})" :key="Feature.featureReference">
                        <td><label><input type="checkbox" :disabled="IsDisabled(Feature)" @click="toggleFeature(Feature)" :checked="isSelected(Feature)" /> {{Feature.featureName}}</label></td>
                        <td v-if="isSelected(Feature) && Feature.subFeatures.length > 0">
                            <button v-if="!hasUnselectedSubfeatures(Feature)" @click="SelectAllSubfeatures(Feature)">Select All</button>
                            <button v-else @click="UnselectAllSubfeatures(Feature)">Unselect All</button>
                            <label v-for="Subfeature in Feature.subFeatures.map(function(a) {return a;}).sort(sortSubfeatures)" :key="Subfeature.subfeatureReference">
                                <br />
                                <input type="checkbox" :disabled="!Subfeature.hasDataTables" :value="Subfeature.subfeatureReference" v-model="UserSelection.includedFeatureInDataTable.filter(function(f) {return f.featureReference === Feature.featureReference;})[0].subfeatures" />
                                {{Subfeature.subfeatureName}}
                            </label>
                        </td>
                        <td v-else>&nbsp;</td>
                    </tr>
                </tbody>
            </table>
            <div>
            </div>
        </fieldset>
        <fieldset class="navigation analysisbottomMargin50">
            <button v-on:click.stop="$emit('back')">Back</button>
            <button v-on:click.stop="emitDataTable" :disabled="!canProceed()">Next</button>
        </fieldset>
    </div>
</template>

<script>
    import helper from './HelperMethods.vue';
    export default {
        name: 'DataTableSelection',
        props: ["userSelection", "userDetails", "datatableTemplate"],
        methods: {
            sortSubfeatures: helper.customSort("subfeatureName"),
            IsDisabled: function (feature) {
                return !feature.hasDataTables ||
                    this.maxFeatures !== null &&
                    (this.maxFeatures - this.UserSelection.includedFeatureInDataTable.length) <= 0 &&
                    !this.UserSelection.includedFeatureInDataTable.some(function (incf) { return incf.featureReference === feature.featureReference; });
            },
            hasUnselectedSubfeatures: function (feature) {
                var that = this;
                return !feature.subFeatures.some(
                    function (sf) {
                        return sf.hasDataTables &&
                            !that.UserSelection.includedFeatureInDataTable.filter(
                                function (f) {
                                    return f.featureReference === feature.featureReference;
                                })[0].subfeatures.some(
                                    function (isf) { return isf === sf.subfeatureReference; }
                                );
                    })
            },
            SelectAllSubfeatures: function (feature) {
                this.UserSelection.includedFeatureInDataTable.filter(function (f) { return f.featureReference === feature.featureReference })[0].subfeatures = feature.subFeatures.filter(function (sf) { return sf.hasDataTables; }).map(function (sf) { return sf.subfeatureReference });
            },
            UnselectAllSubfeatures: function (feature) {
                this.UserSelection.includedFeatureInDataTable.filter(function (f) { return f.featureReference === feature.featureReference })[0].subfeatures = [];
            },
            isSelected: function (feature) {
                return this.UserSelection.includedFeatureInDataTable.some(function (f) { return f.featureReference === feature.featureReference });
            },
            toggleFeature: function (feature) {
                if (this.isSelected(feature))
                    this.UserSelection.includedFeatureInDataTable = this.UserSelection.includedFeatureInDataTable.filter(function (f) { return f.featureReference !== feature.featureReference })
                else
                    this.UserSelection.includedFeatureInDataTable.push({ featureReference: feature.featureReference, subfeatures: [] });
            },
            canProceed: function () {
                var that = this;
                if (this.UserSelection.includedFeatureInDataTable.some(
                    function (incf) {
                        return incf.subfeatures.length == 0
                            && that.UserSelection.extendedFeatures.some(
                                function (f) { return f.featureReference === incf.featureReference && f.subFeatures.length > 0; }
                            )
                    })) {
                    //If there are features selected which have subfeatures but not selected any...
                    return false;
                }
                return this.UserSelection.includedFeatureInDataTable.length > 0;
            },
            emitDataTable: function () {
                this.$emit('datatable-selection-completed');
            }
        },
        created: function () {
            if (this.Template !== null && typeof (this.Template) !== "undefined" && this.UserSelection.includedFeatureInDataTable.length == 0)
                this.UserSelection.includedFeatureInDataTable = this.Template.includedFeatures;
        },
        computed: {
            UserSelection: function () { return this.userSelection; },
            UserDetails: function () { return this.userDetails; },
            DatatableTemplate: function () { return this.datatableTemplate;},
            categories: function () {
                return this.UserSelection.extendedFeatures
                    .map(function (ef) { return { category: ef.featureCategory, order: ef.featureCategoryOrder } })
                    .sort(function (a, b) { return a.featureCategoryOrder - b.featureCategoryOrder; })
                    .map(function (c) { return c.category })
                    .filter(helper.distinct);
            },
            maxFeatures: {
                get: function () {
                    return this.UserDetails.maxFeaturesPerDataTables;

                }
            },
            RemainingFeatures: {
                get: function () {
                    return this.maxFeatures - this.UserSelection.includedFeatureInDataTable.length;
                }
            },
        },
    }
</script>
<style scoped>
    tbody th {
        background: #0070C0;
        color: white;
        font-weight: normal;
    }
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<script setup>
import { ref, computed, watch } from 'vue';
import { ElScrollbar, ElInput, ElButton, ElCheckbox, ElCheckboxGroup } from 'element-plus'
import { Right, Back } from '@element-plus/icons-vue';

const props = defineProps(['modelValue', 'data', 'titles']);
const emit = defineEmits(['update:modelValue']);

// const state = ref(props.modelValue);
const filterAvailable = ref('');
const filterSelected = ref('');
const availableSelection = ref([]);
const selectedSelection = ref([]);

const available = computed(() => props.data.filter(i =>
    availableSelection.value.includes(i.key) || (
        i.label.toLocaleLowerCase().includes(filterAvailable.value.toLocaleLowerCase())
        && !props.modelValue?.includes(i.key)))
);

const selected = computed(() => props.data.filter(i =>
    selectedSelection.value.includes(i.key) || (
        i.label.toLocaleLowerCase().includes(filterSelected.value.toLocaleLowerCase())
        && props.modelValue?.includes(i.key)))
);

function addItems() {
    emit('update:modelValue', [...props.modelValue, ...availableSelection.value].filter(Boolean));
    availableSelection.value = [];
}
function removeItems() {
    emit('update:modelValue', props.modelValue?.filter(i => !!i && !selectedSelection.value.includes(i)));
    selectedSelection.value = [];
}

watch(() => props.data, () => {
    availableSelection.value = [];
    selectedSelection.value = [];
})

</script>

<template>
    <div class="root">
        <div class="row">
            <div class="list-container">
                <h4>
                    <span> {{titles.left}} </span>
                    <span>{{ availableSelection.length }}/{{ available.length || 0 }} items</span>
                </h4>
                <div class="card">
                    <ElInput v-model="filterAvailable" placeholder="Filter items" clearable />
                    <ElScrollbar height="20rem">
                        <ElCheckboxGroup v-model="availableSelection" class="list">
                            <ElCheckbox v-for="item in available" :label="item.key" :key="item.key" class="check"
                                :disabled="item.disabled">
                                {{ item.label }}
                            </ElCheckbox>
                        </ElCheckboxGroup>
                    </ElScrollbar>
                </div>
            </div>
            <div class="actions">
                <ElButton type="primary" :icon="Right" size="small" @click="addItems"
                    :disabled="!availableSelection.length" />
                <ElButton type="primary" :icon="Back" size="small" @click="removeItems"
                    :disabled="!selectedSelection.length" />
            </div>
            <div class="list-container">
                <h4>
                    <span> {{ titles.right }} </span>
                    <span>{{ selectedSelection.length }}/{{ selected.length || 0 }} items</span>
                </h4>
                <div class="card">
                    <ElInput v-model="filterSelected" placeholder="Filter items" clearable />
                    <ElScrollbar height="20rem">
                        <ElCheckboxGroup v-model="selectedSelection" class="list">
                            <ElCheckbox v-for="item in selected" :label="item.key" :key="item.key" class="check">
                                {{ item.label }}
                            </ElCheckbox>
                        </ElCheckboxGroup>
                    </ElScrollbar>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.row {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.root {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.list-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.list-container>h4 {
    margin: 0;
    display: flex;
    justify-content: space-between;
}

.card {
    background-color: #fff;
    flex: 1;
    border-radius: var(--el-border-radius-base);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: var(--el-border);
}

.actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.actions>.el-button {
    margin: 0;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.check {
    white-space: normal;
    height: auto;
    min-height: 32px;
}</style>
<template>
    <div class="holder">
        <div class="mainDiv">
            <div>
                <p>
                    <span>
                        <label class="numbers">3</label>
                        <label class="policyDetails">Policy Details</label>
                    </span>

                    <span style="float:right">
                        <label class="percentageCompleted">60% completed</label>
                    </span>
                </p>

                <p>
                    <el-progress style="padding-top:5px;" class="el-progress--without-text" :percentage="80"></el-progress>
                </p>

                <div class="item">
                    <p class="Heading4">
                        Would you like to compare an historical critical illness policy to current plans in
                        the market?
                    </p>
                </div>

                <div class="radioMargin">
                    <el-radio class="radioElement Paragraph2" v-model="UserSelection.showCIPolicy" label="Yes"
                              border>Yes</el-radio>
                </div>
                <div class="radioMargin">
                    <el-radio class="radioElement Paragraph2" v-model="UserSelection.showCIPolicy" label="No" border>
                        No, I
                        just want to compare new plans
                    </el-radio>
                </div>

                <div v-if="UserSelection.showCIPolicy == 'Yes'" class="panelCIPolicy">
                    <p class="formText">Find the policy</p>
                    <div>
                        <el-form label-position="top" ref="ruleFormCIPolicy" :model="UserSelection" :rules="rules">
                            <el-form-item class="labels" label="Insurer/product type" prop="">
                                <el-select placeholder="Select insurer/product type" filterable
                                           v-model="UserSelection.legacyUniquePolicyProvider">
                                    <el-option v-for="(products, i) in legacyProviderList" :key="i" :value="products"
                                               :label="product"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="labels" label="When was the policy taken out" prop="">
                                <el-form-item class="labels" label="Month" prop="" style="width: 140px; margin-right: 10px">
                                    <el-select placeholder="Select Month" v-model="UserSelection.legacyMonthYear">
                                        <el-option v-for="(month, i) in months" :key="i" :value="month.monthValue"
                                                   :label="month.monthText"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item class="labels" label="Year" prop="" style="width: 130px; margin-right: 10px">
                                    <el-select placeholder="Select Year" v-model="UserSelection.legacyYear">
                                        <el-option v-for="(year, i) in years" :key="i" :value="year"
                                                   :label="year"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item class="labels" label="&nbsp;" prop="" style="padding-left: 20px">
                                    <el-link v-on:click.stop="ResetFilter()"
                                             style="color:blue;text-decoration:underline;">Reset</el-link>
                                </el-form-item>
                            </el-form-item>
                            <el-form-item class="labels" label="Available policies" prop="">
                                <label style="font-style:italic">Please select one policy from the list to continue</label>
                                <el-table :data="legacyPolicyFilter" highlight-current-row
                                          @current-change="lagacyProviderChange"
                                          style="height: 250px; overflow-y:auto;cursor:pointer" row-key="providerReference"
                                          :current-row-key="UserSelection.legacyPolicyProviderReference">
                                    <el-table-column prop="qaProviderName" label="Insurer/Product name" />
                                </el-table>
                            </el-form-item>
                            <el-form-item v-if="!UserDetails.hasSetNoPricing" class="labels" label="Premium/Cost" prop="">
                                <span class="budget">
                                    <el-input type="number" v-model="UserSelection.LegacyPolicyProviderPrice"></el-input>
                                </span>
                            </el-form-item>
                        </el-form>
                        <el-link v-on:click.stop="$emit('find-your-legacy-policy')" class="summaryLabels"
                                 style="color:blue;text-decoration:underline;">Can't find your policy?</el-link>
                    </div>
                </div>
                <div class="btnNextPosition">
                    <el-button type="default" class="btnDefault" v-on:click.stop="$emit('back')">
                        <span class="el-icon-arrow-left"></span> Back
                    </el-button>
                    <el-button :disabled="(UserSelection.showCIPolicy === null || UserSelection.showCIPolicy === '' || UserSelection.showCIPolicy === 'Yes') && (UserSelection.legacyPolicyProvider === null || UserSelection.legacyPolicyProvider === '')"
                               type="success" v-on:click.stop="SelectedCIAnswer">Next</el-button>
                </div>
            </div>
        </div>

        <div class="summary">
            <label class="summaryTitle">Summary</label> <br />

            <div style="display:inline-flex">
                <label class="summaryLabels">Client details</label>
                <el-link v-on:click.stop="$emit('edit-customer')" class="summaryLabels"
                         style="margin-left: 120px; text-decoration: underline">Edit</el-link>
            </div>

            <div v-if="UserSelection.primaryClient !== null">
                <label class="summaryDetail">
                    {{ UserSelection.primaryClient.forename + " " + UserSelection.primaryClient.surname }}
                </label>
                <label class="summaryDetail">
                    {{ helper.GetDisplayableDate(UserSelection.primaryClient.dateOfBirth) }} ,
                </label>
                <label class="summaryDetail">
                    {{ helper.GetDisplayableClassification(UserSelection.primaryClient.classification) }}
                </label>
            </div>

            <div v-if="UserSelection.secondaryClient !== null">
                <label class="summaryDetail">
                    {{ UserSelection.secondaryClient.forename + " " + UserSelection.secondaryClient.surname }}
                </label>
                <label class="summaryDetail">
                    {{ helper.GetDisplayableDate(UserSelection.secondaryClient.dateOfBirth) }} ,
                </label>
                <label class="summaryDetail">
                    {{ helper.GetDisplayableClassification(UserSelection.secondaryClient.classification) }}
                </label>
            </div>

            <hr style="border: 1px solid #A0A0A0;" />
            <label class="summaryLabels">Policy type</label>

            <label class="summaryDetail">{{ UserSelection.tool.toolName }}</label>
            <hr style="border: 1px solid #A0A0A0;" />

            <label class="summaryLabels">Policy details</label>
            <label class="summaryDetail">
                {{ (UserSelection.decreasingPolicy == true ? 'Decreasing Policy ' + 'Yes' : 'Decreasing Policy ' +  'No')  }}
                {{ (UserSelection.policyTerm !== null ? ', Term length ' + UserSelection.policyTerm : ', To age ' + UserSelection.toAge)  }}
                {{ (UserSelection.ipBenefitAmount !== 0 ? ', Benefit Amount ' + UserSelection.ipBenefitAmount : '') }}
                {{ (UserSelection.termBenefitAmount !== null ? UserSelection.tool.toolReference === 'FIBPro - Pricing' || UserSelection.tool.toolReference === 'FIBCIPro - Pricing' ? ', Annual life cover amount ' + UserSelection.termBenefitAmount : ', Life cover amount ' + UserSelection.termBenefitAmount : '') }}
                {{ (UserSelection.termCriticalIllnessCoverAmount !== null ? UserSelection.tool.toolReference === 'FIBCIPro - Pricing' ? ', Annual critical illness cover amount ' + UserSelection.termCriticalIllnessCoverAmount : ', Critical illness cover amount ' + UserSelection.termCriticalIllnessCoverAmount : '') }}
                {{ (UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount !== null ? UserSelection.tool.toolReference === 'FIBCIPro - Pricing' ? ', Annual life or earlier CI cover amount ' : ', Life or earlier CI cover amount ' + UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount : '') }}
            </label>
            <hr style="border: 1px solid #A0A0A0;" />

            <label class="summaryLabels">Type of analysis</label>
            <label class="summaryDetail">
                Historic policy comparison
                <br />
                {{ this.UserSelection.legacyPolicyProvider }}
            </label>
            <hr style="border: 1px solid #A0A0A0;" />
            <label class="summaryLabels">Additional info</label>
        </div>

    </div>
</template>

<style>
    .el-table__body tr.current-row > td.el-table__cell {
        color: black;
        background: #E8EDFB;
        border: 1px solid #6183E4;
        box-sizing: border-box;
        border-radius: 8px;
    }
</style>

<style scoped>
    /* ipad Mini Landscape */
    @media only screen and (width:1024px) {
        .mainDiv {
            margin-left: -250px;
        }

        .summary {
            left: -250px;
        }
    }

    /*.current-row {
        background-color:red;
    }*/

    .el-date-editor {
        --el-date-editor-width: 10% !important;
    }


    .formText {
        width: 366px;
        left: 0px;
        top: 66px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -0.02em;
        color: #0F172A;
    }

    .fieldText {
        font-size: 20px;
    }

    .panelCIPolicy {
        padding-top: 100px;
        padding-bottom: 30px;
    }

    .percentageCompleted {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        align-items: self-end;
        text-align: right;
        letter-spacing: -0.04em;
    }

    .holder {
        max-width: 1144px;
        min-width: 1044px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 24px;
        position: relative;
    }

    .el-link.el-link--default:hover {
        color: black !important;
    }

    .el-link.is-underline:hover:after {
        border: none;
    }

    .el-link.el-link--default:after {
        border: none;
    }

    .summaryDetail {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;
        width: 194px;
        /*height: 40px;*/
        margin-bottom: 5px;
    }

    .summaryLabels {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 36px;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;
        color: #A0A0A0;
    }

    .summary {
        position: absolute;
        top: 30px;
        right: 150px;
        width: 232px;
        height: 572px;
    }

    .summaryTitle {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 41px;
        letter-spacing: -0.04em;
        color: #0F172A;
    }

    /*.el-radio.is-bordered {
        width: 366px !important;
        height: 68px !important;
    }*/

    .desc {
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 10px !important;
        /*padding-left: 20px !important;*/
        color: #4B5768 !important;
        /*margin-left: 10px !important;*/
    }

    .radioTitle {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: black;
    }

    .btnNextPosition {
        display: inline-flex;
        padding-top: 20px;
        padding-left: 10px;
    }

    .el-button--success {
        color: #fff !important;
        background-color: #059669 !important;
        /*border-color: #059669 !important;*/
    }

    .el-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        position: static;
        width: 167px;
        height: 44px;
        left: 199px;
        top: 0px;
        border-radius: 8px;
    }

    .el-button--success {
        color: #fff !important;
        background-color: #059669 !important;
        /*border-color: #059669 !important;*/
    }

        .el-button--success:focus,
        .el-button--success:hover {
            background: #059669 !important;
            border-radius: 8px;
            /*border-color: #059669 !important;*/
        }

        .el-button--success.is-disabled,
        .el-button--success.is-disabled:active,
        .el-button--success.is-disabled:focus,
        .el-button--success.is-disabled:hover {
            background: #E7EAEE !important;
            border-radius: 8px;
            /*border-color: #E7EAEE !important;*/
            font-style: normal;
            color: #A0ABBB;
        }

    .el-button--default {
        color: black !important;
        background: white !important;
        border: 1px solid #191D23 !important;
    }

    .el-radio {
        width: 366px;
        /*height: 68px;*/
        box-sizing: border-box;
        border-radius: 8px;
    }

    .radioMargin {
        padding-top: 20px;
        padding-right: 30px;
        flex: none;
        order: 1;
        flex-grow: 0;
        background: #FFFFFF;
        border-radius: 8px;
        float: right;
    }

    .el-radio__input.is-checked + .el-radio__label {
        color: black;
    }

    .el-radio__input.is-checked .el-radio__inner {
        border-color: none !important;
        background: none !important;
    }

    .el-radio.is-bordered.is-checked {
        color: black;
        background: #E8EDFB;
        border: 1px solid #6183E4;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .el-radio__input.is-checked + .el-radio__label {
        color: black;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
    }

    .el-card.is-always-shadow {
        box-shadow: none;
    }


    .Paragraph2TC {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #191D23;
        padding-left: 15px;
        padding-right: 15px;
    }

    .Heading4 {
        width: 366px;
        height: 74px;
        left: 0px;
        top: 66px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -0.02em;
        color: #0F172A;
        margin-bottom: 60px;
    }


    .el-progress__text {
        display: none !important;
    }

    .numbers {
        background: #EEEEEE;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        background: #EEEEEE;
        border-radius: 15px;
        padding: 2px;
    }

    .policyDetails {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        align-items: center;
        letter-spacing: -0.04em;
        padding-left: 10px;
    }

    .mainDiv {
        padding: 72px;
        width: 530px;
        /*height:554px;*/
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .el-select {
        width: 93% !important;
    }

    .clientBudget {
        position: static;
        width: 280px;
        left: 0px;
        top: 39px;
    }

    .budget {
        display: block;
        margin: 5px 5px 5px 5px;
        position: relative;
        padding-left: 25px;
        border: solid 1px black;
    }

        .budget::before {
            content: "£";
            width: 10px;
            font-family: 'Roboto';
            color: #1e228c;
            display: inline-block;
            padding-left: 5px;
            font-size: 24px;
            text-align: left;
            position: absolute;
            left: 0px;
        }

        .budget input {
            border: none;
            font-size: 24px;
            padding: 0px 0px 0px 0px;
            display: block;
            width: 100%;
        }
</style>

<script>
    import helper from '../components/HelperMethods.vue';
    import axios from 'axios';

    export default {
        name: 'CompareCriticalIllnessPolicyPage',
        props: ["userSelection", "userDetails"],
        computed: {
            UserSelection: function () { return this.userSelection; },
            UserDetails: function () { return this.userDetails; }
        },
        watch:
        {
            'UserSelection.legacyUniquePolicyProvider': function () {
                this.UserSelection.legacyMonthYear = '';
                this.UserSelection.legacyYear = '';
                this.FilterPolicy();
            },
            'UserSelection.legacyMonthYear': function () {
                this.FilterPolicy();
            },
            'UserSelection.legacyYear': function () { this.FilterPolicy(); },
            'UserSelection.showCIPolicy': function () {
                if (this.UserSelection.showCIPolicy.toLowerCase() === "yes") {
                    this.FillInsurerList();
                    this.FilterPolicy();
                }
            }
        },
        data: function () {
            return {
                advert: null,
                isLoading: false,
                helper: helper,
                legacyPolicyFilter: [],
                legacyProviderList: [],
                months: [
                    {
                        monthText: 'Jan',
                        monthValue: '01'
                    },
                    {
                        monthText: 'Feb',
                        monthValue: '02'
                    },
                    {
                        monthText: 'Mar',
                        monthValue: '03'
                    },
                    {
                        monthText: 'Apr',
                        monthValue: '04'
                    },
                    {
                        monthText: 'May',
                        monthValue: '05'
                    },
                    {
                        monthText: 'Jun',
                        monthValue: '06'
                    },
                    {
                        monthText: 'Jul',
                        monthValue: '07'
                    },
                    {
                        monthText: 'Aug',
                        monthValue: '08'
                    },
                    {
                        monthText: 'Sept',
                        monthValue: '09'
                    },
                    {
                        monthText: 'Oct',
                        monthValue: '10'
                    },
                    {
                        monthText: 'Nov',
                        monthValue: '11'
                    },
                    {
                        monthText: 'Dec',
                        monthValue: '12'
                    }
                ],
                years: [],
                legacyProviders: [],
                legacyDataProvider: null,
            }
        },
        created: function () {

            var that = this;
            axios.get('api/Configuration/ListProducts/Legacy')
                .then(response => {
                    that.legacyProviders = response.data;
                    that.years = [];

                    if (that.legacyDataProvider != null) {
                        var prevProviderSelected = that.legacyProviders.filter(item => item.providerReference === that.legacyDataProvider);
                        if (prevProviderSelected !== null && prevProviderSelected.length > 0) {
                            that.UserSelection.legacyPolicyGroup = prevProviderSelected[0].providerName;
                            that.UserSelection.legacyPolicyProvider = prevProviderSelected[0].qaProviderName;
                            that.UserSelection.legacyPolicyProviderReference = prevProviderSelected[0].providerReference
                            that.UserSelection.legacyPolicyProviderPeriod = (prevProviderSelected[0].validFrom ? new Date(prevProviderSelected[0].validFrom).toJSON().slice(0, 10) : '') +
                                ' To ' + (prevProviderSelected[0].validTo ? new Date(prevProviderSelected[0].validTo).toJSON().slice(0, 10) : '');
                            that.UserSelection.legacyProviderValidFrom = prevProviderSelected[0].validFrom;
                            that.UserSelection.legacyProviderValidTo = prevProviderSelected[0].validTo;
                        }
                        that.legacyDataProvider = null;
                    }

                    for (var i = 1985; i <= new Date().getFullYear(); i++)
                        that.years.push(i);

                    that.FillInsurerList();
                    that.FilterPolicy();


                    if (that.UserSelection.legacyPolicyProvider === null || that.UserSelection.legacyPolicyProvider === '') {
                        that.FillInsurerList();
                        that.FilterPolicy();
                    }
                    else if (that.UserSelection.legacyPolicyProvider !== '') {
                        that.FillInsurerList();
                        that.FilterPolicy();
                    }
                    else {
                        that.UserSelection.showCIPolicy = 'No';
                        that.ResetFilter();
                    }

                });
        },
        methods:
        {
            SelectedCIAnswer: function () {
                if (this.userSelection.showCIPolicy !== '' && this.userSelection.showCIPolicy === 'No')
                    this.ResetFilter();

                this.$emit('ci-Policy-Details-Added');
            },
            FilterPolicy: function () {
                var thisControl = this;
                thisControl.legacyPolicyFilter = [];

                //let date = null;

                const selectedYear = parseInt(thisControl.UserSelection.legacyYear);
                const selectedMonth = parseInt(thisControl.UserSelection.legacyMonthYear);
                const selectedProvider = thisControl.UserSelection.legacyUniquePolicyProvider;

                thisControl.legacyPolicyFilter = this.legacyProviders
                    .filter(p => {
                        const providerFromDate = p.validFrom ? new Date(p.validFrom) : undefined;
                        const providerToDate = p.validTo ? new Date(p.validTo) : undefined;

                        const toYear = providerToDate?.getFullYear() || new Date().getFullYear();
                        const fromYear = providerFromDate?.getFullYear() || (toYear - 30); //fallback in case daet from is null
                        const validYears = Array.from({ length: toYear - fromYear + 1 })
                            .fill(0)
                            .map((_, i) => i + fromYear);

                        const date = selectedMonth && selectedYear
                            ? new Date(selectedYear, selectedMonth - 1, 1)
                            : undefined;

                        return (!selectedProvider || p.providerName === selectedProvider)
                            && (!selectedYear || validYears.includes(selectedYear))
                            && (!date ||
                                (date >= providerFromDate
                                    && (!providerToDate || date <= providerToDate)));
                    })
                    .sort((a, b) => {
                        if (a.providerName !== b.providerName) {
                            return a.providerName.localeCompare(b.providerName)
                        }
                        else {
                            return new Date(a.validFrom) - new Date(b.validFrom)

                        }
                    });
            },
            lagacyProviderChange: function (item) {
                if (item != null) {
                    this.UserSelection.legacyPolicyGroup = item.providerName;
                    this.UserSelection.legacyPolicyProvider = item.qaProviderName;
                    this.UserSelection.legacyPolicyProviderReference = item.providerReference;
                    this.UserSelection.legacyPolicyProviderPeriod = (item.validFrom ? new Date(item.validFrom).toJSON().slice(0, 10) : '') +
                        ' To ' + (item.validTo ? new Date(item.validTo).toJSON().slice(0, 10) : '');
                    this.UserSelection.legacyProviderValidFrom = item.validFrom;
                    this.UserSelection.legacyProviderValidTo = item.validTo;
                }
                else {
                    if (this.UserSelection.legacyPolicyProviderReference !== "" && (this.legacyProviders === null || this.legacyProviders.length === 0)) {
                        this.legacyDataProvider = this.UserSelection.legacyPolicyProviderReference;
                    }
                    this.UserSelection.legacyPolicyProvider = '';
                    this.UserSelection.legacyPolicyProviderReference = '';
                    this.UserSelection.legacyPolicyProviderPeriod = '';
                    this.UserSelection.legacyPolicyGroup = '';
                }
            },
            ResetFilter: function () {
                this.UserSelection.legacyPolicyProvider = '';
                this.UserSelection.legacyPolicyGroup = '';
                this.UserSelection.legacyPolicyProviderReference = '';
                this.UserSelection.legacyUniquePolicyProvider = '';
                this.UserSelection.legacyMonthYear = '';
                this.UserSelection.legacyYear = '';
                this.UserSelection.legacyPolicyProviderPeriod = '';
                this.UserSelection.legacyProviderValidFrom = null;
                this.UserSelection.legacyProviderValidTo = null;
            },
            formatDate: function (input) {
                var datePart = input.match(/\d+/g),
                    day = datePart[0],
                    month = datePart[1],
                    cyear = datePart[2];

                return month + '-' + day + '-' + cyear;
            },
            FillInsurerList: function () {
                var thisControl = this;

                var legacyToolProviderLists = this.legacyProviders;

                if (legacyToolProviderLists.length > 0) {
                    legacyToolProviderLists.forEach(function (p) {

                        var providerName = (p.providerName.split("From")[0]).trim();

                        if (!thisControl.legacyProviderList.includes(providerName))
                            thisControl.legacyProviderList.push(providerName);
                    });
                }
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<template>
    <span class="dateControl">
        <select v-model="Day" class="day">
            <option v-for="(d, i) in Days" :key="i" :value="d">{{d}}</option>
        </select>/<select v-model="Month" class="month">
            <option v-for="(m, i) in Months" :key="i" :value="m">{{m}}</option>
        </select>/<select v-model="Year" class="year">
            <option v-for="(y, i) in Years" :key="i" :value="y">{{y}}</option>
        </select>
    </span>
</template>

<script>
    export default {
        name: 'DateControl',
        props: ["value", "minage", "maxage"],
        data: function () {
            return {
                "day": "",
                "month": "",
                "year": "",
                "Months": ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],

            };
        },
        watch: {
            value: function (newValue) {
                if (newValue != null && newValue.length > 0) {
                    var parts = newValue.split('-');
                    this.year = parts[0];
                    this.month = parts[1];
                    this.day = parts[2];
                }
            }
        },
        created: function () {
            if (this.value != null && this.value.length > 0) {
                var parts = this.value.split('-');
                this.year = parts[0];
                this.month = parts[1];
                this.day = parts[2];
            }
        },
        computed: {
            Day: {
                get: function () { return this.day; },
                set: function (value) {
                    if (this.day != value) {
                        this.day = value;
                        this.emitChanged();
                    }
                }
            },
            Month: {
                get: function () { return this.month; },
                set: function (value) {
                    if (this.month != value) {
                        this.month = value;
                        this.emitChanged();
                    }
                }
            },
            Year: {
                get: function () { return this.year; },
                set: function (value) {
                    if (this.year != value) {
                        this.year = value;
                        this.emitChanged();
                    }
                }
            },
            "Years": function () {
                var result = [];
                var maxyear = (new Date()).getFullYear() - this.minage;
                var minyear = (new Date()).getFullYear() - this.maxage;
                for (var year = minyear; year <= maxyear; year++)
                    result.push(year);
                return result;
            },
            "Days": function () {
                var maxDay = 31;
                if (this.month != null && this.year != null) {
                    maxDay = new Date(this.year, this.month, 0).getDate(); 
                }
                var result = [];
                for (var i = 1; i <= maxDay; i++) {
                    if (i < 10) {
                        result.push("0" + i);
                    }
                    else {
                        result.push(i);
                    }
                }
                return result;
            }

        },
        methods: {
            emitChanged: function () {
                if (this.year !== "" && this.month !== "" && this.day !== "")
                    this.$emit('input', this.year + "-" + this.month + "-" + this.day);
            }
        }
    }
</script>
<style scoped>
    select {
        border:none;
    }

    select.day, select.month {
        width: 40px!important;
    }
        select.year {
            width: 60px !important;
        }
    span.dateControl {
        display: inline-block;
        border: solid 1px rgb(76,76,76);
        border-radius: 5px;
    }
</style>

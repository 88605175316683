<template>
    <div class="holder">
        <div class="mainDiv">
            <div>
                <loading-control v-if="isLoading && advert === null" :loading-message="'loading...'" />
                <p>
                    <span>
                        <label class="numbers">5</label>
                        <label class="policyDetails">Additional info</label>
                    </span>

                    <span style="float:right">
                        <label class="percentageCompleted">90% completed</label>
                    </span>
                </p>

                <p>
                    <el-progress style="padding-top:5px;" class="el-progress--without-text" :percentage="90"></el-progress>
                </p>

                <div class="item">
                    <p class="Heading4">Tell us more about your client?</p>
                </div>
                <div v-if="!isLoading">
                    <div style="text-align:center">
                        <div v-for="(expressQuestion, eqindex) in ExpressCustomFields" :key="expressQuestion.displayQuestion">
                            <label class="Question" style="text-align:left;float:left" :for="'eq' + eqindex">{{expressQuestion.displayQuestion}}</label>
                            <br />
                            <div class="selectList label radioOptions" v-for="(option, optionIndex) in expressQuestion.options" :key="optionIndex">
                                <input type="radio" hidden="hidden" :id="'eq' + eqindex + '~' + optionIndex" :value="option" v-model="UserSelection.customFields[expressQuestion.displayQuestion]">
                                <label :for="'eq' + eqindex + '~' + optionIndex">{{option}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="btnNextPosition">
                        <el-button type="default" v-on:click.stop="$emit('back')"> <span class="el-icon-arrow-left"></span> Back</el-button>
                        <el-button type="success" :disabled="!canProceed" v-on:click="GetAnswers()">Next</el-button>
                    </div>
                </div>
            </div>

        </div>

        <div class="summary">
            <label class="summaryTitle">Summary</label> <br />

            <div style="display:inline-flex">
                <label class="summaryLabels">Client details</label>
                <el-link v-on:click.stop="$emit('edit-customer')" class="summaryLabels" style="margin-left: 120px; text-decoration: underline">Edit</el-link>
            </div>

            <div v-if="UserSelection.primaryClient !== null">
                <label class="summaryDetail">{{UserSelection.primaryClient.forename + " " + UserSelection.primaryClient.surname}}</label>
                <label class="summaryDetail">
                    {{helper.GetDisplayableDate(UserSelection.primaryClient.dateOfBirth)}} , {{helper.GetDisplayableClassification(UserSelection.primaryClient.classification)}}
                </label>
            </div>

            <div v-if="UserSelection.secondaryClient !== null">
                <label class="summaryDetail">{{UserSelection.secondaryClient.forename + " " + UserSelection.secondaryClient.surname}}</label>
                <label class="summaryDetail">
                    {{helper.GetDisplayableDate(UserSelection.secondaryClient.dateOfBirth)}} , {{helper.GetDisplayableClassification(UserSelection.secondaryClient.classification)}}
                </label>
            </div>

            <hr style="border: 1px solid #A0A0A0;" />
            <label class="summaryLabels">Policy type</label>
            <label class="summaryDetail">{{UserSelection.tool.toolName}}</label>
            <hr style="border: 1px solid #A0A0A0;" />

            <label class="summaryLabels">Policy details</label><br />
            <label class="summaryDetail">
                {{ (UserSelection.decreasingPolicy == true ? 'Decreasing Policy ' + 'Yes' : 'Decreasing Policy ' +  'No')  }}
                {{ (UserSelection.policyTerm !== null ? ', Term length ' + UserSelection.policyTerm : ', To age ' + UserSelection.toAge)  }}
                {{ (UserSelection.ipBenefitAmount !== 0 ? ', Benefit Amount ' + UserSelection.ipBenefitAmount : '') }}
                {{ (UserSelection.termBenefitAmount !== null ? UserSelection.tool.toolReference === 'FIBPro - Pricing' || UserSelection.tool.toolReference === 'FIBCIPro - Pricing' ? ', Annual life cover amount ' + UserSelection.termBenefitAmount : ', Life cover amount ' + UserSelection.termBenefitAmount : '') }}
                {{ (UserSelection.termCriticalIllnessCoverAmount !== null ? UserSelection.tool.toolReference === 'FIBCIPro - Pricing' ? ', Annual critical illness cover amount ' + UserSelection.termCriticalIllnessCoverAmount : ', Critical illness cover amount ' + UserSelection.termCriticalIllnessCoverAmount : '') }}
                {{ (UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount !== null ? UserSelection.tool.toolReference === 'FIBCIPro - Pricing' ? ', Annual life or earlier CI cover amount ' : ', Life or earlier CI cover amount ' + UserSelection.termLifeOrEarlierCriticalIllnessCoverAmount : '') }}
            </label> <br />
            <hr style="border: 1px solid #A0A0A0;" />

            <div v-if="this.UserSelection.legacyPolicyProvider  !== '' && this.UserSelection.showCIPolicy !== 'No'">
                <label class="summaryLabels">Type of analysis</label><br />
                <label class="summaryDetail">
                    Historic policy comparison
                    <br />
                    {{ this.UserSelection.legacyPolicyProvider }}
                </label><br />
                <hr style="border: 1px solid #A0A0A0;" />
            </div>

            <label class="summaryLabels">Quality of plans</label>
            <label class="summaryDetail">{{UserSelection.planType}}</label>
            <hr style="border: 1px solid #A0A0A0;" />

            <label class="summaryLabels">Additional info</label>
        </div>

        <advertisement v-if="advert !== null" :advert="advert" loading-message="Loading..." :user-selection="UserSelection" screen="Express Questions" />

        <div v-if="false">
            <loading-control v-if="isLoading && advert === null" :loading-message="'loading...'" />
            <div v-if="!isLoading">
                <fieldset class="selectList">
                    <legend class="ExpHeader">
                        How would you like compare the quality of plans...
                    </legend>
                    <div>
                        <div>
                            <input type="checkbox" id="useTemplates" v-model="ShowingQuestions" />
                            <label for="useTemplates">
                                ProtectionGuruPro select features based on my client’s attributes.
                            </label>
                        </div>
                        <div>
                            <input type="checkbox" id="skipTemplates" v-model="skipTemplates" />
                            <label for="skipTemplates">
                                I want to select which features to compare myself
                            </label>
                        </div>
                    </div>
                </fieldset>
                <fieldset class="selectList" v-if="ShowingQuestions === true">

                    <p class="ExpQuePara">
                        By answering the following questions, ProtectionGuruPro will select which policy features are most relevant for your client, their relative importance and compare plans based on these (you can change these if you do not agree or want to tweak).
                    </p>

                    <div style="text-align:center">
                        <div v-for="(expressQuestion, eqindex) in ExpressCustomFields" :key="expressQuestion.displayQuestion">
                            <label :for="'eq' + eqindex" class="ExpQueLabel">{{expressQuestion.displayQuestion}}</label>
                            <div v-for="(option, optionIndex) in expressQuestion.options" :key="optionIndex">
                                <input type="radio" hidden="hidden" :id="'eq' + eqindex + '~' + optionIndex" :value="option" v-model="UserSelection.customFields[expressQuestion.displayQuestion]">
                                <label class="button160" :for="'eq' + eqindex + '~' + optionIndex">{{option}}</label>
                            </div>
                        </div>

                    </div>
                </fieldset>

                <fieldset class="navigation analysisbottomMargin100">
                    <!--<button class="button250" v-on:click.stop="$emit('express-question-selected')">Next</button>-->
                    <button v-on:click.stop="$emit('back')">Back</button>
                    <button :disabled="!canProceed" @click.stop="GetAnswers">Next</button>
                </fieldset>
            </div>
        </div>
    </div>

</template>
<!--<style scoped>
    input[type=checkbox] {
        visibility: hidden;
    }

    .ExpHeader {
        font-weight: 600
    }

    .ExpQueLabel {
        color: #1e228c;
        font-size: medium;
        border: unset !important;
        padding-top: 20px;
        text-align: left !important;
    }

    .ExpQuePara {
        color: #1e228c;
        font-size: medium;
    }

    .custombutton {
        max-width: 250pt !important;
        font-size: 18px !important;
    }

    .analysisbottomMargin100 {
        margin-bottom: 100px;
    }
</style>-->
<style scoped>
    /* ipad Mini Landscape */

    .holder {
        max-width: 1144px;
        min-width: 1044px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 24px;
        position: relative;
    }


    .el-link.el-link--default:hover {
        color: black !important;
    }

    .el-link.is-underline:hover:after {
        border: none;
    }

    .el-link.el-link--default:after {
        border: none;
    }

    .summaryDetail {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;
        width: 194px;
        height: 40px;
    }

    .summaryLabels {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 36px;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;
        color: #A0A0A0;
    }

    .summary {
        position: absolute;
        top: 30px;
        right: 150px;
        width: 232px;
        height: 572px;
    }

    .summaryTitle {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 41px;
        letter-spacing: -0.04em;
        color: #0F172A;
    }

    .selectList :checked + label {
        color: white;
        background-color: #5F5F5F;
        width: 108px;
        height: 35px;
        padding-top: 10px;
    }

    .selectList label {
        border: 1px solid #EEEEEE;
        background-color: #EEEEEE;
        color: black;
        width: 108px;
        vertical-align: middle;
        border-radius: 27px;
        display: table-cell;
        text-align: center;
        height: 35px;
        padding-top: 10px;
        justify-content: center !important;
        align-items: center !important;
    }

    .radioOptions {
        margin-top: 10px;
        margin-bottom: 15px;
        float: left;
        display: inline-flex !important;
        border-radius: 28px !important;
        margin-left: 10px !important;
    }

    .Question {
        width: 386px;
    }

    .btnNextPosition {
        display: inline-flex;
        padding-top: 20px;
        padding-left: 10px;
    }

    .el-button {
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        align-items: center !important;
        padding: 10px !important;
        position: static !important;
        width: 167px !important;
        height: 44px !important;
        left: 199px !important;
        top: 0px !important;
        border-radius: 8px !important;
    }

    .el-button--success {
        color: #fff !important;
        background-color: #059669 !important;
        /*border-color: #059669 !important;*/
    }

        .el-button--success:focus, .el-button--success:hover {
            background: #059669 !important;
            border-radius: 8px !important;
            /*border-color: #059669 !important;*/
        }

        .el-button--success.is-disabled, .el-button--success.is-disabled:active, .el-button--success.is-disabled:focus, .el-button--success.is-disabled:hover {
            background: #E7EAEE !important;
            border-radius: 8px !important;
            /*border-color: #E7EAEE !important;*/
            font-style: normal !important;
            color: #A0ABBB !important;
        }

    .el-button--default {
        color: black !important;
        background-color: white !important;
        border: 1px solid #191D23 !important;
    }

    /*.el-button--default:focus, .el-button--default:hover {
            background: white;
            border-radius: 8px;
            border: 1px solid #191D23;
            border-radius: 8px;
            color: black;
        }*/


    .el-radio {
        width: 366px;
        height: 68px;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .radioMargin {
        padding-bottom: 20px;
        padding-right: 30px;
        flex: none;
        order: 1;
        flex-grow: 0;
        background: #FFFFFF;
        border-radius: 8px;
        float: right;
        height: 68px;
    }

    .el-radio__input.is-checked + .el-radio__label {
        height: 68px;
        color: black;
    }

    .el-radio__input.is-checked .el-radio__inner {
        height: 68px;
        border-color: none !important;
        background: none !important;
    }

    .el-radio.is-bordered.is-checked {
        height: 68px;
        color: black;
        background: #E8EDFB;
        border: 1px solid #6183E4;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .el-radio__input.is-checked + .el-radio__label {
        height: 68px;
        color: black;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
    }

    .el-card.is-always-shadow {
        box-shadow: none;
    }


    .Paragraph2TC {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #191D23;
        padding-left: 15px;
        padding-right: 15px;
    }

    .Heading4 {
        width: 386px;
        left: 0px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -0.02em;
        color: #0F172A;
    }

    .el-progress__text {
        display: none !important;
    }

    .numbers {
        background: #EEEEEE;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        background: #EEEEEE;
        border-radius: 15px;
        padding: 2px;
    }

    .policyDetails {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        align-items: center;
        letter-spacing: -0.04em;
        padding-left: 10px;
    }

    .mainDiv {
        padding: 82px;
        width: 530px;
        height: 700px;
        left: 515px;
        top: 150px;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 8px;
    }
</style>

<script>
    import helper from './HelperMethods.vue';
    import $ from 'jquery';
    import LoadingControl from './LoadingControl.vue';
    import Advertisement from './Advertisement.vue';
    export default {
        components: { LoadingControl, Advertisement },
        name: 'TermAssuranceExpressQuestion',
        props: ["userSelection", "userDetails", "templateSet", "adverts"],
        data: function () {
            return {
                ShowingQuestions: false,
                skipTemplates: false,
                advert: null,
                isLoading: false,
                helper: helper,
            }
        },
        methods:
        {
            GetAnswers: function () {

                var thisControl = this;

                var values = this.Values;
                var matchingOptions = thisControl.ExpressTemplate.options.filter(function (c) {
                    return helper.checkCondition(c.condition, values);
                });
                if (matchingOptions.length > 0) {
                    thisControl.UserSelection.expressTemplate = matchingOptions[0];
                    thisControl.UserSelection.extendedFeatures.forEach(function (f) { f.weight = 0; });
                    thisControl.UserSelection.categoryWeightings.forEach(function (c) { c.weight = 0; });
                }
                else {
                    thisControl.UserSelection.expressTemplate = null;
                }
                //thisControl.$emit('express-question-selected');
                thisControl.$emit('info-verification')
            },
        },
        mounted: function () {
            var waiting = 1;
            this.isLoading = true;
            var thisControl = this;
            var done = function () {
                thisControl.isLoading = false;
                thisControl.advert = null;
                var template = thisControl.ExpressTemplate;
                if (template !== null) {

                    var oldValues = thisControl.UserSelection.customFields;
                    var newValues = {};

                    for (var customFieldsIndex = 0; customFieldsIndex < template.customFields.length; customFieldsIndex++) {
                        var fieldName = template.customFields[customFieldsIndex].displayQuestion;

                        if (typeof (oldValues[fieldName]) === 'string') {
                            newValues[fieldName] = oldValues[fieldName];
                            thisControl.ShowingQuestions = true;
                        }
                        else
                            newValues[fieldName] = null;
                    }
                    thisControl.UserSelection.customFields = newValues;
                }

            }
            var adToShow = helper.GetAdvert(this.adverts, "ExpressTemplateScreen", this.UserSelection);
            if (adToShow !== null) {
                this.advert = adToShow;
                waiting++;
                setTimeout(function () {
                    waiting--;
                    if (waiting == 0)
                        done();
                }, adToShow.minimumDurationInSeconds * 1000);
            }

            $.ajax({
                type: 'GET',
                url: '/api/Journey/Update/' + this.UserSelection.sessionReference + "/" + this.UserSelection.journeyReference + "/10/Express%20Question",
                success: function () {
                    waiting--;
                    if (waiting == 0)
                        done();
                },
            });
        },
        watch: {
            skipTemplates: function () {
                var thisControl = this;
                thisControl.UserSelection.expressTemplate = null;
                thisControl.$emit('express-question-selected');
            }
        },
        computed: {
            UserSelection: function () { return this.userSelection; },
            UserDetails: function () { return this.userDetails; },
            TemplateSet: function () { return this.templateSet; },
            Values: function () {
                return helper.GetValuesForCondtion(this.UserSelection);
            },
            canProceed: function () {
                var template = this.ExpressTemplate;
                if (template !== null) {


                    for (var customFieldsIndex = 0; customFieldsIndex < template.customFields.length; customFieldsIndex++) {
                        var fieldName = template.customFields[customFieldsIndex].displayQuestion;

                        if (typeof (this.UserSelection.customFields[fieldName]) !== 'string') {
                            return false;
                        }
                    }
                    return true;
                }
                return false;
            },
            ExpressTemplate: {
                get: function () {
                    var thisControl = this;
                    if (thisControl.TemplateSet !== null) {
                        var templates;

                        if (thisControl.UserSelection.legacyPolicyProviderReference !== null && thisControl.UserSelection.legacyPolicyProviderReference !== '') {
                            templates = thisControl.TemplateSet.toolTemplates.filter(function (t) {
                                return t.toolKey === "Legacy";
                            });
                        }
                        else {
                            templates = thisControl.TemplateSet.toolTemplates.filter(function (t) {
                                return t.toolKey === thisControl.UserSelection.tool.toolReference;
                            });
                        }

                        if (templates.length > 0)
                            return templates[0];
                    }
                    return null;

                }
            },
            ExpressCustomFields: {
                get: function () {
                    if (this.ExpressTemplate === null)
                        return [];
                    if (this.ExpressTemplate.customFields === null) {
                        return [];
                    }
                    return this.ExpressTemplate.customFields;
                }
            }
        },
        created: function () {
            //this.UserSelection.selectedCustomerType = null;
            //if (this.UserSelection.primaryClient === null)
            //    this.UserSelection.primaryClient = { classification: "Male_NonSmoker", dateOfBirth: '1990-01-01', title: '', surname: '', forename: '', salary: 0, personEmployedStatus: 'Employed' };
        }
    }</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

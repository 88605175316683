<script setup>
import { ElButton, ElRadio } from "element-plus";
import { computed, defineEmits } from "vue";
import SummaryPage from './SummaryPage.vue';

const props = defineProps(['userSelection']);
const UserSelection = computed(() => props.userSelection);
const emit = defineEmits(["info-verification", "edit-customer"]);

function selectedUMEAnswer () { emit('info-verification'); }
function canProceed () { return  UserSelection.value.underwritten != null;}

</script>

<template>
    <div class="holder">
        <div class="mainDiv">
            <p>
                <span>
                    <label class="numbers"> 6 </label>
                    <label class="policyDetails">Underwriting</label>
                </span>
                <span style="float:right">
                    <label class="percentageCompleted">95% completed</label>
                </span>
            </p>
            <p>
                <el-progress style="padding-top:5px;" class="el-progress--without-text" :percentage="95"></el-progress>
            </p>
            <div>
                <p class="Heading4">Would you like to carry out underwriting in advance of comparing quality and price?
                </p>
            </div>
            <div class="radioMargin">
                <el-radio v-model="UserSelection.underwritten" :label="false" border>No, show me
                    non-underwritten prices and I'll complete the underwriting later</el-radio>
            </div>
            <div class="radioMargin">
                <el-radio v-model="UserSelection.underwritten" :label="true" border>Yes, I
                    want to complete underwriting now</el-radio>
            </div>
            <div class="btnNextPosition">
                <el-button class="btnDefault" v-on:click.stop="$emit('back')"> Back</el-button>
                <el-button class="btnDefault" :disabled="!canProceed()" type="success" v-on:click.stop="selectedUMEAnswer()"> Next</el-button>
            </div>
        </div>
        <SummaryPage :user-selection="userSelection" v-on:edit-customer="$emit('edit-customer')" />
    </div>
</template>

<style scoped>
.btnDefault {
    width: 167px;
    height: 44px
}

.el-button--success.is-disabled {
    background: #e7eaee!important;
    border-radius: 8px!important;
    font-style: normal!important;
    color: #a0abbb!important;
}


.mainDiv {
    padding: 72px;
    width: 530px;

    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 8px;
}

.holder {
    max-width: 1144px;
    min-width: 1044px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    position: relative;
}

.Heading4 {
    width: 366px;
    height: 74px;
    left: 0px;
    top: 66px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    letter-spacing: -0.02em;
    color: #0F172A;
    margin-bottom: 60px;
}

.radioMargin {
    padding-top: 20px;
    padding-right: 30px;
    flex: none;
    order: 1;
    flex-grow: 0;
    background: #FFFFFF;
    border-radius: 8px;
}

.btnNextPosition {
    display: inline-flex;
    padding-top: 20px;
    padding-left: 10px;
}

.numbers {
    background: #EEEEEE;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    background: #EEEEEE;
    border-radius: 15px;
    padding: 2px;
}

.policyDetails {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    align-items: center;
    letter-spacing: -0.04em;
    padding-left: 10px;
}

.percentageCompleted {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: self-end;
    text-align: right;
    letter-spacing: -0.04em;
}

.el-button--success {
    color: #fff !important;
    background-color: #059669 !important;
}

.el-button--default {
    color: black !important;
    background: white !important;
    border: 1px solid #191D23 !important;
}

.el-radio.is-bordered {
    white-space: normal;
    height: 2.5rem;
    width: 23rem;
}
</style>

<template>
    <el-select v-model="Age" placeholder="Enter age">
        <el-option :value="0" label="&lt; 1 year old" />
        <el-option :value="1" label="1 year old" />
        <el-option :value="2" label="2 years old" />
        <el-option :value="3" label="3 years old" />
        <el-option :value="4" label="4 years old" />
        <el-option :value="5" label="5 years old" />
        <el-option :value="6" label="6 years old" />
        <el-option :value="7" label="7 years old" />
        <el-option :value="8" label="8 years old" />
        <el-option :value="9" label="9 years old" />
        <el-option :value="10" label="10 years old" />
        <el-option :value="11" label="11 years old" />
        <el-option :value="12" label="12 years old" />
        <el-option :value="13" label="13 years old" />
        <el-option :value="14" label="14 years old" />
        <el-option :value="15" label="15 years old" />
        <el-option :value="16" label="16 years old" />
        <el-option :value="17" label="17 years old" />
        <el-option :value="18" label="18 years old" />
        <el-option :value="19" label="19 years old" />
        <el-option :value="20" label="20 years old" />
        <el-option :value="21" label="21 years old" />
        <el-option :value="22" label="22 years old" />
        <el-option :value="23" label="23 years old" />
        <el-option :value="24" label="24 years old" />
        <el-option :value="25" label="25 years old" />
    </el-select>
</template>

<script>
    import helper from './HelperMethods.vue';

    export default {
        name: 'AgeControl',
        props: ["modelValue"],
        data: function () {
            return {}
        },
        created: function () { },
        computed: {
            Age: {
                get: function () {
                    if (this.modelValue === null)
                        return null;
                    return helper.GetAge(this.modelValue);
                },
                set: function (value) {
                    if (value === null)
                        this.$emit('update:modelValue', value);
                    value = value * 1;
                    var year = 365.25 * 24 * 60 * 60 * 1000;
                    var estBday = new Date(new Date() - (value + 0.5) * year).toISOString().substr(0, 10);
                    this.$emit('update:modelValue', estBday)
                    //this.$emit('input', estBday);
                }
            },

        },
    }
</script>

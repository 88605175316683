<script setup>
import { computed, ref, inject, defineEmits } from "vue";
import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { ElButton, ElInput, ElSwitch } from "element-plus";
import { useQuery } from "@tanstack/vue-query";
import iPipelineLogo from "../../assets/images/iPipelineLogo.png";
import axios from "axios";

const userService = inject('UserService');

const queryClient = useQueryClient();

const isAdmin = computed(() => !!userService.getUserDetails()?.isAdminUser);

const { data: currentIntegration, isLoading: isLoadingCurrent } = useQuery({
  queryKey: ["ipipeline-integration"],
  queryFn: () =>
    axios("api/Integration/GetIPipelineIntegration").then((res) => res.data),
});

const { mutate: deleteUserIntegration, isLoading: isDeleting } = useMutation({
  mutationFn: () =>
    axios.delete("api/Integration/DeleteUserIntegration/IPIPELINE"),
  onSuccess: () => {
    iPipelineCredentials.value.userName = "";
    iPipelineCredentials.value.password = "";
    queryClient.invalidateQueries(["ipipeline-integration"]);
    queryClient.invalidateQueries(["user-integrations"]);
    userService.update();
  },
});

const iPipelineCredentials = ref({
  userName: "",
  password: "",
  isExt: false,
});

const emit = defineEmits(["saveCredentials"]);

const { mutate: saveCredentials, isLoading: isSaving, isError } = useMutation({
  mutationFn: () =>
    axios
      .post(
        "api/Integration/SaveIPipelineCredentials",
        iPipelineCredentials.value
      ),
  onSuccess: async () => {
    queryClient.invalidateQueries(["ipipeline-integration"]);
    queryClient.invalidateQueries(["user-integrations"]);
    await userService.update();
    emit("saveCredentials","IPIPELINE");
  }
});

const isLoading = computed(
  () => isSaving.value || isLoadingCurrent.value || isDeleting.value
);
const disabled = computed(() => !!currentIntegration.value || isLoading.value);
</script>

<template>
  <div class="container">
    <div class="content">
      <div class="tip">
        <p>
          To ensure the prices and quotes we obtain from iPipeline are the prices and products you have access to, please
          provide us with your <b>WebID</b> and <b>Password</b>.
        </p>
        <p>
          Your WebID and Password are <u>NOT</u> the same as the login and password you use to login to the service.
        </p>

        <p style="font-weight: bold; display: none">
          Please note: Your WebID and Password are not the same as the username
          and password you use to access either Assureweb or SolutionBuilder.
        </p>

        <p style="font-weight: bold">
          If you do not know what these are you can view our tutorial on how to
          access these
          <a href="https://protectionguru.co.uk/protectiongurupro/how-to-use-protectiongurupro/recover-ipipeline-webid-password/"
            target="_blank">here</a>.
          <br />
          This may take 5-10 minutes.
          <u>We will only require these details once and will not ask for them
            again.</u>
        </p>
      </div>
      <div>
        <div class="form">
          <img alt="iPipeline" :src="iPipelineLogo" />
          <fieldset>
            <label for="WebID">
              WebID
              <b>
                <a target="_blank"
                  href="https://protectionguru.co.uk/protectiongurupro/how-to-use-protectiongurupro/recover-ipipeline-webid-password/">What
                  is this?</a>
              </b>
            </label>
            <ElInput v-if="!!currentIntegration" id="WebID" v-model="currentIntegration.userName" disabled />
            <ElInput v-else id="WebID" v-model="iPipelineCredentials.userName" :min-length="1" type="text" autofocus :class="{ error : isError}" />
          </fieldset>
          <fieldset>
            <label for="Password">
              Password
              <b>
                <a target="_blank"
                  href="https://protectionguru.co.uk/protectiongurupro/how-to-use-protectiongurupro/recover-ipipeline-webid-password/#Password">How
                  do i get this?</a>
              </b>
            </label>
            <ElInput v-if="!!currentIntegration" id="Password" disabled value="xxxxxxxxxxxxx" type="password" />
            <ElInput v-else id="Password" v-model="iPipelineCredentials.password" :min-length="1" type="password" />
            <span v-if="isError" class="errorText">Sorry, we are unable to match your WebID to an IPipeLine account, please check and try again.</span>
          </fieldset>
          <div class="ext" v-if="isAdmin">
            <label for="isEXT">EXT Environment <b>(Admin only)</b></label>
            <ElSwitch id="isEXT" v-model="iPipelineCredentials.isExt" :disabled="!!currentIntegration" />
          </div>
          <ElButton v-if="!!currentIntegration" type="danger" @click="deleteUserIntegration" :loading="isLoading">
            Unlink account
          </ElButton>
          <ElButton v-else type="primary" @click="saveCredentials" :loading="isLoading" :disabled="disabled">
            Link account
          </ElButton>
        </div>
        <div class="links">
          <p>
            <strong>Can’t remember your WebID or password?</strong> (<a target="_blank"
              href="https://protectionguru.co.uk/protectiongurupro/how-to-use-protectiongurupro/recover-ipipeline-webid-password/">Click
              Here</a>)
          </p>
          <p>
            <strong>Don’t have an iPipeline account? </strong>
            <a target="_blank" href="https://www.ipipeline.uk.com/sign-up/">
              Click Here
            </a>
            to set up an account, it’s FREE!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.errorText {
  color: red;
  font-size: small;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
  border: var(--el-border);
  border-radius: 0.2rem;
  width: 20rem;
}

.form fieldset {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.form img {
  width: 50%;
}

.form fieldset label {
  display: flex;
  justify-content: space-between;
}

.content {
  display: flex;
  gap: 1rem;
}

.links {
  font-size: small;
  text-align: center;
}

.ext {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
</style>

<style>
.error .el-input__wrapper {
  border: 1px solid red;
}
</style>

<script setup>
import MultiSelect from '../ui/MultiSelect.vue';
import ToolSelector from './ToolSelector.vue';
import { computed, ref, watch } from 'vue';
import { useQuery, useMutation } from '@tanstack/vue-query';
import { ElButton, ElMessage } from 'element-plus';
import axios, { HttpStatusCode } from 'axios';

const selectedTool = ref('');
const excluded = ref([]);

const toolQueryKey = computed(() => ['tool-products', selectedTool.value]);
const toolQueryEnabled = computed(() => !!selectedTool.value);

const { data: toolProducts } = useQuery({
    queryKey: toolQueryKey,
    enabled: toolQueryEnabled,
    queryFn: () => axios(`api/Configuration/ListProducts/${selectedTool.value}`).then(res => res.data),
});

const excludedQueryKey = computed(() => ['excluded-products', selectedTool.value]);
const excludedQueryEnabled = computed(() => !!selectedTool.value);

const { data: excludedStored } = useQuery({
    queryKey: excludedQueryKey,
    enabled: excludedQueryEnabled,
    queryFn: () => axios(`api/Configuration/ListExcludedProducts/${selectedTool.value}`).then(res => res.data),
});

watch(excludedStored, (newValue) => {
    excluded.value = newValue || [];
})


const { mutate: save, isLoading: isSaving } = useMutation({
    mutationFn: async () => {
        try {
            const response = await axios.post(
                `api/Configuration/SaveExcludedProducts/${selectedTool.value}`,
                JSON.stringify(excluded.value),
                {
                    headers: {
                        'Accept': 'application/json, text/plain',
                        'Content-Type': 'application/json;charset=UTF-8',
                    },
                }
            );

            if (response.status === HttpStatusCode.Ok) {
                return response.data;
            } else {
                throw new Error('Excluded products could not be saved');
            }
        } catch (error) {
            throw new Error(error.message);
        }
    },
    onSuccess: () => {
        ElMessage.success('Excluded products saved for ' + selectedTool.value);
    },
    onError: () => {
        ElMessage.error('Excluded products could not be saved');
    }
});

const options = computed(() => toolProducts.value?.map(product => ({
    key: product.providerReference,
    label: `${product.providerName} (${product.providerReference})`
})) || []);

const titles = {left:"Products", right:"Excluded products"};

</script>
<template>
    <h2>Excluded products</h2>
    <p class="tip">
        Select a tool for setting the products that should be excluded from non-pricing analysis
        <ToolSelector v-model="selectedTool" />
    </p>
    <h3>{{ selectedTool }}</h3>
    <div style=" line-height: 0.5rem">
        <p> You will find the excluded products in the right pannel. </p>
        <p>Move the products you want to exclude from the Available list (left) to the Selected (right). </p>
        <br>
    </div>
    <div v-if="!!selectedTool">
        <MultiSelect :data="options" v-model="excluded" :titles="titles" />
        <div class="actions">
            <ElButton type="primary" :loading="isSaving" @click="save">Save</ElButton>
        </div>
    </div>
</template>
<style scoped>
.actions {
    display: flex;
    justify-content: end;
    margin: 1rem 0;
}

.actions button {
    margin: unset;
    width: auto;
}
</style>
<script setup>
import { ElSelect, ElOption } from 'element-plus';
import { useQuery } from '@tanstack/vue-query';
import { computed } from 'vue';
import axios from 'axios';

const props = defineProps(['modelValue']);
const emit = defineEmits(['update:modelValue'])

const value = computed({
    get() {
        return props.modelValue;
    },
    set(newValue) {
        emit('update:modelValue', newValue);
    }
});

const {
    isLoading,
    data: tools,
} = useQuery(['api-tools'], () => axios('api/Configuration/ListTools').then(res => res.data));

const sortedTools = computed(() => [...(tools?.value || [])].sort((a,b) => a.toolName.localeCompare(b.toolName)))

</script>
<template>
    <ElSelect v-model="value" :loading="isLoading">
        <ElOption v-for="tool in sortedTools" :key="tool.toolReference" :value="tool.toolReference"
            :label="`${tool.toolName} (${tool.toolReference})`" />
    </ElSelect>
</template>
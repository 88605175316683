import { ref } from 'vue';
import $ from 'jquery';

var localDetailsSingleton = ref(null);
var localTemplatesSigleton = ref(null);
var localIsLoading = ref(false);

export default new class {

    isLoading() {
        return localIsLoading.value;
    }
    getUserDetails() {
        return localDetailsSingleton.value;
    }
    getTemplateSet() {
        return localTemplatesSigleton.value;
    }
    constructor() {
        this.update();
    }
    update() {
        var thisService = this;
        if (!localIsLoading.value) {
            localIsLoading.value = true;

            return $.ajax({
                type: 'GET',
                url: '/Api/UserDetails',
                contentType: 'application/json',
                success: function (UserDetails) {
                    localDetailsSingleton.value = UserDetails;
                    if (localDetailsSingleton.value.templateSetID !== null) {
                        $.ajax({
                            type: 'GET',
                            url: '/Api/ExpressTemplate',
                            contentType: 'application/json',
                            success: function (templateSet) {
                                localTemplatesSigleton.value = templateSet;
                            }
                        });
                    }
                    localIsLoading.value = false;
                },
                error: function (xhr) {
                    if (xhr.status == 401) {
                        window.location = "./Api/signin";
                        return;
                    }
                    thisService.$gtag.exception({
                        'description': 'Error getting user details (' + xhr.status + ').',
                        'fatal': true
                    });
                    localIsLoading.value = false;
                },

            });
        }
    }    
}
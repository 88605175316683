<template>
    <div class="ModalBackground" style="display:block;">
        <div class="ModalBackGroundPanel" style="overflow: hidden">
            <p class="CommissionHeader">Commission Settings</p>
            <p>
                <label class="label" for="ipBenefitIncreaseBasis">Commission</label>
                <select class="DropdownControl" id="ipBenefitIncreaseBasis" v-model="UserDetails.commissionType">
                    <option value="Full">Full</option>
                    <option value="Modified">Modified</option>
                    <option value="Nil">Nil</option>
                </select>
            </p>
            <br />
            <p v-if="UserDetails.commissionType === 'Modified'">
                <label class="label">Percentage of normal initial commission required</label>
                <el-input-number v-model="UserDetails.commissionPercentageRequired" :integer="false" units="%" @change="handleChange" :min="1" :max="99"> </el-input-number>
                <!--<number-input min="1" max="99" units="%" :integer="false" label="" v-model="UserDetails.commissionPercentageRequired" />-->

            </p>
            <p v-if="UserDetails.commissionType !=='Nil'">
                <label class="label" for="ipBenefitIncreaseBasis">Indemnity required</label>
                <select class="DropdownControl" v-model="UserDetails.indemnityRequired">
                    <option :value="true">
                        yes
                    </option>
                    <option :value="false">
                        no
                    </option>
                </select>
            </p>

            <div class="navigation" style="float:right">
                <el-button type="default" v-on:click.stop="$emit('back')">Cancel</el-button>
                <el-button type="success" v-on:click.stop="SaveUserCommissionSettings">Save Commission Settings</el-button>
            </div>

        </div>

    </div>
</template>

<style>
    .el-button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        white-space: nowrap;
        cursor: pointer;
        text-align: center;
        height: 44px !important;
        box-sizing: border-box;
        outline: 0;
        transition: .1s;
        font-weight: 700;
        -webkit-user-select: none;
        user-select: none;
        vertical-align: middle;
        -webkit-appearance: none;
        padding: 8px 15px;
        font-size: 14px !important;
        border-radius: 8px !important;
    }
</style>

<style scoped>

    /* ipad Mini Landscape */
    @media only screen and (width:1024px) {

        .ModalBackGroundPanel {
            margin-top: 20px !important;
            padding-top: 0px !important;
            width: 450px !important;
        }

        .DropdownControl {
            width: 450px !important;
        }
    }


    .ModalBackGroundPanel {
        position: relative !important;
        width: 550px !important;
        background: #FFFFFF !important;
        border-radius: 8px !important;
        overflow: hidden !important;
        height: 350px !important;
    }

    .el-button--success, .el-button--success:focus, .el-button--success:hover {
        background: #1E228C !important;
        border-radius: 8px !important;
        border: none !important;
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        text-align: center !important;
        color: white !important;
        width: max-content !important;
        max-width: 250px !important;
        position: fixed !important;
        bottom: 20px !important;
        right: 32px !important;
    }

    .el-button--default, .el-button--default:focus, .el-button--default:hover {
        background: #FFFFFF !important;
        border: 1px solid #000000 !important;
        box-sizing: border-box !important;
        border-radius: 8px !important;
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        text-align: center !important;
        color: #000000 !important;
        width: 100px !important;
        position: fixed !important;
        bottom: 20px !important;
        right: 290px !important;
    }

    .CommissionHeader {
        position: static;
        width: 189px;
        height: 23px;
        left: 0px;
        top: 0px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 19px;
        line-height: 23px;
        letter-spacing: -0.02em;
        color: #2D2D2D;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .DropdownControl {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 12px 10px 16px;
        position: absolute;
        width: 544px;
        height: 44px;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .label {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #0F172A;
    }
</style>

<script>
    import $ from 'jquery';

    export default {
        components: {},

        name: 'IncomeProtectionCommissionSetting',
        props: ["userDetails", "userSelection"],
        methods:
        {
            ResetPricingSettings: function () {
                window.location = "/api/reset";
            },
            SaveUserCommissionSettings: function () {
                var thisControl = this;

                if (thisControl.UserDetails.commissionType == "Full")
                    thisControl.UserDetails.commissionPercentageRequired = 0;

                if (thisControl.UserDetails.commissionType == "Nil") {
                    thisControl.UserDetails.indemnityRequired = false;
                    thisControl.UserDetails.commissionPercentageRequired = 0;
                }

                thisControl.UserSelection.ipCommissionType = thisControl.UserDetails.commissionType;
                thisControl.UserSelection.ipIndemnityRequired = thisControl.UserDetails.indemnityRequired;
                thisControl.UserSelection.ipCommissionPercentageRequired = thisControl.UserDetails.commissionPercentageRequired;

                $.ajax({
                    type: 'POST',
                    url: '/api/Home/SaveUserSettings/',
                    contentType: 'application/json',
                    data: JSON.stringify(thisControl.UserDetails),
                    success: function () {
                        thisControl.$emit('back');
                    },
                    error: function (e) {
                        thisControl.$message.error("Error:\n" + e.responseText);
                    }
                });
            }
        },
        created: function () {
            if (this.UserDetails.commissionType === null || this.UserDetails.commissionType === 0)
                this.UserDetails.commissionType = "Full";

            if (this.UserDetails.commissionType !== null && this.UserDetails.commissionType === 1)
                this.UserDetails.commissionType = "Modified";

            if (this.UserDetails.commissionType !== null && this.UserDetails.commissionType === 2)
                this.UserDetails.commissionType = "Nil";

            if (this.UserDetails.indemnityRequired === null)
                this.UserDetails.indemnityRequired = true;
        },
        computed: {
            UserDetails: function () { return this.userDetails; },
            UserSelection: function () { return this.userSelection; },
            HasPrice: function () {
                if (this.UserDetails.hasSetNoPricing)
                    return "No";
                else
                    return "Yes";
            }
        }
    }</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<template>
    <div class="ModalBackground" style="display:block">
        <div class="ModalBackGroundPanel elPopup" style="overflow: hidden">
            <div class="header">
                <h1 class="Title">Privacy Policy</h1>
            </div>
            <div class="elPopupBody Paragraph2TC">
                <h3>Protection Guru Pro</h3>
                <h3>Privacy Notice</h3>
                <p>
                    We are committed to protecting your personal information and being transparent about what we do with it, irrespective of how you interact with us.
                </p>
                <p>This privacy notice describes how we via our application, Protection Guru Pro,collects, uses and stores personal information and what it means for you. The terms of this notice may change, so please check it from time to time. For the purposes of this notice, 'us', 'we' and 'our' refer to Financial Technology Research Centre Limited and Protection Guru Pro.</p>
                <p>
                    This privacy notice sets out how we use and protect any information that you give us when you use this application.
                </p>
                <p>
                    We are committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement.
                </p>
                <p>
                    We may change this notice from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. This notice is effective from the 1<sup>st</sup> September 2022.
                </p>

                <h3>What we collect</h3>
                <p>We may collect the following information:</p>
                <ul>
                    <li>Your first and surname.</li>
                    <li>Contact information including email address.</li>
                    <li>Your date of birth.</li>
                    <li>Other information relevant to you based on the answers to the questions within the application.</li>
                </ul>

                <h3>What we do with the information we gather</h3>
                <p>We require this information to understand your needs and provide you with a quote and carry out a quality analysis.</p>
                <p>We will keep your data for the following reasons:</p>
                <ul>
                    <li>For the period of the quote validity and then the data will be deleted after 30 days.</li>
                    <li>Family member data where requested.</li>
                    <li>Children's data but no children's names will be recorded.</li>
                </ul>

                <h3>Security</h3>
                <p>We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.</p>

                <h3>Controlling your personal information</h3>
                <p>You may choose to restrict the collection or use of your personal information in the following ways:</p>
                <ul>
                    <li>whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes</li>
                    <li>if you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by contacting us.</li>
                    <li>A report of your interaction with us will be sent to your protection advisor with all names and identifiers removed.</li>
                </ul>

                <p>We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law. We will not use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.</p>
                <p>
                    You may request details of personal information which we hold about you under the EU & UK General Data Protection Regulations (GDPR). If you would like a copy of the information held on you, please write to us at the address on the contact page. If you believe that any information, we are holding on you is
                    incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly correct any information found to be incorrect
                </p>

                <h3>How long we keep your information</h3>
                <p>We will hold your personal information on our systems for as long as is necessary for the relevant activity and in accordance with legal requirements. We really appreciate it if you let us know if your contact details change.</p>
                <p>When your information is no longer required, we will ensure it is disposed of in a secure manner.</p>
                <p>If you ask us to stop sending you information, we will keep a record of your contact details and appropriate information to enable us to comply with your request not to be contacted by us.</p>
                <p>Where you contribute material to us, e.g. user generated content or in response to a particular campaign, we will only keep your content for as long as is reasonably required for the purpose(s) for which it was submitted unless otherwise stated at the point of generation</p>

                <h3>Your rights in relation to your data</h3>
                <p>You have a right to ask us to stop processing your personal data, and you have a right to ask to access your personal data and to obtain certain information</p>
                <p>If there are any discrepancies in the information we provide, please let us know and we will correct them. If you want to access your information, send a description of the information you want to see by post to Financial Technology Research Centre Limited or via email at hi@fortis-dpc.com. We will then ask you to prove your identity before proceeding with your request. All enquiries will be completed within 30 days of the original request</p>
                <p>In some circumstances, such as when the data is no longer required or if the use of the data has no legal justification, you have the right to have that data erased, as well as the right to object to our handling of your data.You have the right to withdraw your consent at any time when we rely on consent as a legal ground to justify our handling of your personal data.This would not affect the lawfulness of anything done before consent is withdrawn but it would stop us from continuing to use the relevant information.</p>
                <p>You also have the right to lodge a complaint with the Information Commissioner's Office about how we manage your data</p>

                <h3>Changes to our Privacy Notice</h3>
                <p>Our privacy notice may be updated at any time, so please check it from time to time. We may, for example, change the way we process your information, or there may be a change in law. If we make any significant changes to this notice, we will show this clearly on our website. This notice was last updated in September 2022.</p>
            </div>

            <div class="navigation" style="display:inline-flex;float:right">
                <el-button type="success" class="elConsent" v-on:click.stop="$emit('privacypolicy-closed')">Close</el-button>
            </div>
        </div>
    </div>
</template>

<style scoped>

    .header {
        border: none;
    }

        .header .Options {
            padding-top: 15px;
        }

    .Title {
        margin-bottom: 7px;
        margin-top: 10px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 34px;
        align-items: center;
        letter-spacing: -0.02em;
        color: #0F172A;
        padding-left: 15px;
        padding-right: 15px;
    }

    .el-container {
        background-color: #E0E0E0 !important;
    }

    .elConsent {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        position: static;
        width: 182px;
        height: 48px;
        left: 370px;
        top: 0px;
        background-color: #059669 !important;
        border-radius: 8px;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0px 14px;
        /**/
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #FFFFFF;
    }

    .elPopup {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
        padding-bottom: 48px;
        position: relative;
        width: 744px;
        height: 636px;
        background: #FFFFFF;
        border-radius: 8px;
    }

    .elPopupBody {
        display: inline-block;
        overflow-y: auto;
        width: 720px;
        height: 525px;
        margin-bottom: 16px;
    }

    .Paragraph2TC {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #191D23;
        padding-left: 15px;
        padding-right: 15px;
    }
</style>

<script>
    export default {
        name: 'PrivacyPolicy',
        methods: {
        },
    }
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->

<template>
    <div class="demo-collapse" style="margin-left: 1rem;">
        <p style="font-weight: bold; text-decoration: underline">What our notifications mean</p>
        <el-collapse accordion>
            <el-collapse-item title="Best Quality" name="1">
                <div>
                    The product(s) that has the highest overall quality score.
                </div>
            </el-collapse-item>
            <el-collapse-item title="Best in budget" name="2">
                <div>
                    The product(s) that has the highest overall quality score and is equal to or less than the client's
                    budget.
                </div>
            </el-collapse-item>
            <el-collapse-item title="Lowest premium" name="3">
                <div>
                    The product(s) with the lowest premium.
                </div>
            </el-collapse-item>
            <el-collapse-item title="Buy now" name="4">
                <div>
                    The insurer is able to offer your customer(s) cover and you can complete this application by clicking
                    “Apply Now” and selecting the product.
                </div>
            </el-collapse-item>
            <el-collapse-item title="Buy now non-standard" name="5">
                <div>
                    The insurer is able to offer your customer(s) cover but has made changes to the price or coverage.
                    Please check the “underwriting details” for more information.
                    You can complete this application by clicking “Apply Now” and selecting the product.
                </div>
            </el-collapse-item>
            <el-collapse-item title="Exclusion" name="6">
                <div>
                    The insurer has applied an exclusion to one or more element of the cover selected. Please check the
                    “underwriting details” for more information.
                </div>
            </el-collapse-item>
            <el-collapse-item title="Evidence required" name="7">
                <div>
                    The insurer requires additional evidence and to review before they can put this policy on risk. Please
                    see the “underwriting details” for more information.
                    You can complete this application by clicking “Apply Now” and selecting the product and the insurer will
                    complete the further steps.
                </div>
            </el-collapse-item>
            <el-collapse-item title="Referred" name="8">
                <div>
                    The insurer requires additional evidence and / or to review before they can put this policy on risk.
                    Please see the “underwriting details” for more information.
                    You can complete this application by clicking “Apply Now” and selecting the product and the insurer will
                    complete the further steps.
                </div>
            </el-collapse-item>
            <el-collapse-item title="Automated referral" name="9">
                <div>
                    The insurer has an automated medical evidence process, which means your policy should get on risk much
                    faster.
                    Further information can be found in the “underwriting details”.
                    Once all evidence has been collected, you can complete this application by clicking “Apply Now” and
                    selecting the product, check the evidence collection progress, and complete the application.
                </div>
            </el-collapse-item>
            <el-collapse-item title="Can't offer cover" name="10">
                <div>
                    Unfortunately the insurer isn't able to offer cover to your customer(s). Please check the “underwriting
                    details” for more information.
                </div>
            </el-collapse-item>
            <el-collapse-item title="Can't offer cover now" name="11">
                <div>
                    Unfortunately the insurer isn't able to offer cover to your customer(s) at this time. Please check the
                    “underwriting details” for more information and what timescale.
                </div>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<style>
</style>

<script>
</script>
<template>
    <div>
        <income-protection-express-question :adverts="Adverts"
                                            v-if="currentScreen == 'Income Protection Express Question'"
                                            v-on:edit-customer="$emit('edit-customer')"
                                            v-on:express-question-selected="$emit('express-question-selected')" v-bind:template-set="TemplateSet"
                                            @info-verification="$emit('info-verification')" v-on:back="$emit('back')"
                                            v-bind:user-selection="userSelection" v-bind:user-details="UserDetails" />

        <term-assurance-express-question :adverts="Adverts"
                                         v-if="currentScreen == 'Term Assurance Express Question'" v-on:edit-customer="$emit('edit-customer')"
                                         v-on:express-question-selected="$emit('express-question-selected')" v-bind:template-set="TemplateSet"
                                         @info-verification="$emit('info-verification')" v-on:back="$emit('back')"
                                         v-bind:user-selection="userSelection" v-bind:user-details="UserDetails" />

        <family-income-benefit-express-question :adverts="Adverts"
                                                v-if="currentScreen == 'Family Income Benefit Express Question'"
                                                v-on:edit-customer="$emit('edit-customer')"
                                                v-on:express-question-selected="$emit('express-question-selected')" v-bind:template-set="TemplateSet"
                                                @info-verification="$emit('info-verification')" v-on:back="$emit('back')"
                                                v-bind:user-selection="userSelection" v-bind:user-details="UserDetails" />

        <business-protection-express-question :adverts="Adverts"
                                              v-if="currentScreen == 'Business Protection Express Question'"
                                              v-on:edit-customer="$emit('edit-customer')"
                                              v-on:express-question-selected="$emit('express-question-selected')" v-bind:template-set="TemplateSet"
                                              @info-verification="$emit('info-verification')" v-on:back="$emit('back')"
                                              v-bind:user-selection="userSelection" v-bind:user-details="UserDetails" />

        <express-question-without-price :adverts="Adverts"
                                        v-if="currentScreen == 'Express Question Without Price'"
                                        v-on:express-question-selected="$emit('express-question-selected')"
                                        @info-verification="$emit('info-verification')"
                                        v-on:edit-customer="$emit('edit-customer')"
                                        v-bind:template-set="TemplateSet"
                                        v-on:back="$emit('back')"
                                        v-bind:user-selection="userSelection"
                                        v-bind:user-details="userDetails" />
    </div>
</template>

<script>
    import helper from '../components/HelperMethods.vue';

    import ExpressQuestionWithoutPrice from '../components/ExpressQuestionsWithoutPrice.vue';
    import IncomeProtectionExpressQuestion from '../components/IncomeProtectionExpressQuestion.vue';
    import TermAssuranceExpressQuestion from '../components/TermAssuranceExpressQuestion.vue';
    import FamilyIncomeBenefitExpressQuestion from "../components/FamilyIncomeBenefitExpressQuestion.vue";
    import BusinessProtectionExpressQuestion from "../components/BusinessProtectionExpressQuestion.vue";

    export default {
        components: {
            ExpressQuestionWithoutPrice,
            IncomeProtectionExpressQuestion,
            TermAssuranceExpressQuestion,
            FamilyIncomeBenefitExpressQuestion,
            BusinessProtectionExpressQuestion,
        },
        name: 'ExpressQuestionWithoutPriceGroup',
        props: ["userSelection", "userDetails", "templateSet", "currentScreen"],
        data: function () {
            return {
                helper: helper,
                advert: null,
            }
        },
        computed: {
            UserSelection: function () { return this.userSelection; },
            UserDetails: function () { return this.userDetails; },
            TemplateSet: function () { return this.templateSet; },
        },
        created: function () {
        }
    }</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<template>
    <div>
        <el-dialog width="750px" title="Children Notification" v-model="IsShowingChild">
            <p>
                You are including an analysis of children's critical illness but have not given us details of any children.
                If you add the date of birth and gender of the client's children we can analyse the cover provided based on the conditions they specifically are more likely to suffer from. If you do not add the children's details our analysis will be based on a male and female child, both born today.To input the children's details
                <el-link v-on:click.stop="$emit('return-to-policy-details')" class="ellink" type="primary">click here</el-link>
            </p>
            <div style="text-align:center">
                <el-button type="primary" v-on:click.stop="$emit('childconfirm-continue')" style="width:200px;">Continue</el-button>
            </div>
        </el-dialog>
        <div v-if="false" class="ModalBackground" style="display:block">
            <div class="ModalBackGroundPanel" style="height: auto;">
                <div class="header">
                    <h1 class="Title">Children Notification</h1>
                </div>
                <div id="loadingMessages">
                    <p>
                        You are including an analysis of children's critical illness but have not given us details of any children. If you add the date of birth and gender of the client's children we can analyse the cover provided based on the conditions they specifically are more likely to suffer from. If you do not add the children's details our analysis will be based on a male and female child, both born today.To input the children's details
                        <a href="#" v-on:click.stop="$emit('returntopolicydetails')">click here</a>
                    </p>
                    <button v-on:click.stop="$emit('childconfirm-continue')" class="button150">Continue</button>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .ellink {
        color: #1E228C !important;
        text-decoration: underline;
        text-decoration-color: #1E228C !important
    }

    .el-button--primary, .el-button--primary:hover {
        background: #1E228C !important;
    }

    .myRadio {
        text-indent: -12px;
        padding-left: 35px !important;
        width: 100%;
        min-height: 60px;
        margin-bottom: 10px;
    }

    .selectList {
        text-align: center;
    }

        .selectList .dtButton label {
            width: 350px;
        }
</style>
<script>
    export default {
        name: 'ChildConfirm',
        data: function () {
            return {
                IsShowingChild: true
            }
        },
        methods:
        {
        },
        watch: {
            IsShowingChild: function (newValue) {
                if (newValue === false) {
                    this.$emit('closed')
                }
            }
        },

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

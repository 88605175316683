<script>
import NavigationControl from './components/NavigationControl.vue'

export default {
    name: 'MainApp',
    components: {
        NavigationControl
    },
    inject: ["UserService", "SubscriptionService"],
    computed: {
        UserDetails: function () { return this.UserService.getUserDetails() },
        TemplateSet: function () { return this.UserService.getTemplateSet() },
    },
    watch: {
        UserDetails(newValue) {
            if (newValue.hasAcceptedTermsAndCondiditions && newValue.hasAcceptedLegalAgreement) {
                this.SubscriptionService.update();
            }
        }
    }
}
</script>
<template>
    <div id="MainApp">
        <navigation-control v-if="UserDetails !== null" :user-details="UserDetails" :template-set="TemplateSet" />
    </div>
</template>
<style>
/*#9325bc*/

#MainApp {
    --el-color-primary: #353C8E;
    --el-color-primary-light-3: #7277B0;
    --el-color-primary-light-5: #9A9EC7;
    --el-color-primary-light-7: #C2C4DD;
    --el-color-primary-light-9: #EBECF4;
    --el-color-primary-dark-3: #252A63;
    --el-color-primary-dark-5: #1B1E47;
    --el-color-primary-dark-7: #10122B;
    --el-color-primary-dark-9: #56E;
    --el-color-primary-dark-2: #2A3072;
}

#app {
    font-family: 'Inter';
}
</style>

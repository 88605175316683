<template>
    <div>
        <div class="CustomerCard">
            <div>
                <div class="h4">
                    Your clients
                    <el-button class="btnNext" ref="Customers" @click="AddCustomer">Create new client </el-button>
                </div>
                <div>
                    <el-button class="Credits">Search for a client, Edit their report or Create a new quote for a customer.</el-button>
                </div>
                <div>
                    <el-input v-model="FilterCustomer" v-on:change="FilterCustomerChange" placeholder="Search by customer name" class="SearchCustomers" prefix-icon="Search"> </el-input>
                </div>
                <div v-if="UserCustomers !== null && UserCustomersAnalysis !== null">
                    <div v-for="customer in UserCustomers" :key="customer.customerReference" class="customerDetail">
                        <div :name="customer.customerName" class="tableData" style="background:white;">
                            <div style="background: #F3F2EF !important;padding-bottom:20px;">
                                <el-tag class="NamedTag">{{customer.customerName.charAt(0) + customer.customerName.split(' ')[1].charAt(0)}}</el-tag>
                                <label class="CustomerNames">{{customer.customerName}}</label>
                                <el-button class="btnRunAnalysis" @click="RunAnalysis(customer.customerReference)">Run new analysis</el-button>
                                
                                <el-button class="btnRunAnalysis" @click="EditCustomer(customer.customerReference)">Edit client details</el-button>
                            </div>
                            <div v-for="analysis in UserCustomersAnalysis.filter(function(a){ return a.customerReference === customer.customerReference; })" class="AnalysisData" :key="analysis.analysisReference">
                                <div class="Analysis">
                                    <label>{{analysis.tool}}</label>
                                </div>
                                <div class="analysisDate">
                                    <label>{{"Expires " + displayDate(analysis.expires)}}</label>
                                </div>
                                <div class="logo">
                                    <img v-if="analysis.integration == 'UNDERWRITEME'" alt="underWriteMe" src="../assets/images/underWriteMeLogo3.png" /> 
                                    <img v-else-if="analysis.integration == 'IPIPELINE'" alt="iPipeline" src="../assets/images/iPipelineLogo.png" /> 
                                    <p v-else>Quality only</p>
                                </div>
                                <div class="ResultsAndReports">
                                    <el-button v-if="analysis.integration == 'UNDERWRITEME' && analysis.isPending" class="btnText" @click="OpenIntegratorSite(analysis.externalApplicationUrl)">Complete underwriting</el-button>
                                    <el-button v-else class="btnText" @click="LoadAnalysis(analysis.customerReference, analysis.analysisReference)">View results</el-button>
                                    <el-popover v-if="CustomerReports != null && CustomerReports.length > 0" placement="left" trigger="click">
                                        <template #default>
                                            <el-table :data="CustomerReports.filter(function(report){ return report.analysisReference === analysis.analysisReference }).map(function(f) { return {func : function() {DownloadAnalysisReport(analysis.customerReference, analysis.analysisReference, f.reportReference);}, reportReference: f.reportReference, expires: displayDate(f.expires), title: f.title};})" style="width: 100% !important">
                                                <el-table-column prop="title" label="Title" width="180"></el-table-column>
                                                <el-table-column width="120" label="Expires">
                                                    <template #default="scope">
                                                        <label>{{scope.row.expires}}</label>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column fixed="right" label="Action" width="120">
                                                    <template #default="scope">
                                                        <el-button v-if="scope.row.reportReference !== ''" @click="scope.row.func()">Download</el-button>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </template>
                                        <template v-if="!analysis.isPending" #reference>
                                            <el-button :disabled="CustomerReports.filter(function(report){ return report.analysisReference === analysis.analysisReference }).length == 0" class="btnText">| View reports</el-button>
                                        </template>
                                    </el-popover>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="UserCustomers === null || UserCustomersAnalysis === null">
            <loading-control :loading-message="'Loading Customers...'" />
        </div>
    </div>
</template>
<style>
    .el-popper .is-light .el-popover {
        z-index: 2353 !important;
        position: absolute !important;
        inset: 0px 0px auto auto !important;
        margin: 0px !important;
        transform: translate(-585px, 223px) !important;
        width: 450px !important;
    }
</style>
<style>
    /*.el-popper.is-light {*/
    /*width: 430px !important;*/
    /*}*/

    .el-popover.el-popper {
        width: 430px !important;
    }
</style>
<style type="text/css" scoped>
    .el-popper.is-light {
        width: 430px !important;
    }

    .el-button + .el-button {
        margin-left: 0px !important;
        padding: 0px !important;
    }

    .el-button {
        padding: 8px !important;
    }

    .el-button--text:focus, .el-button--text:hover {
        color: black;
    }

    .el-button:hover {
        background: none !important
    }

    .customerDetail {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
        position: static;
        width: 1012px;
        background: #F3F2EF !important;
        border-radius: 8px;
        margin: 16px 0px;
        margin-left: auto;
        margin-right: auto;
    }

    .btnText {
        color: black;
    }

        .btnText:hover {
            color: black;
            background: none !important;
        }

    .TermAnalysisData {
        justify-content: space-between;
        color: #252525;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        position: static;
        width: 1086px;
        height: 45px;
        background-color: #FFFFFF;
        order: 1;
        flex-grow: 0;
        margin-top: 5px;
    }

    .Analysis {
        width: 350px;
        height: 25px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        padding-left: 5px;
    }

    .analysisDate {
        width: 170px;
        height: 25px;
        top: 0px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .el-button {
        font-weight: 600 !important;
        border: none !important
    }

    .ResultsAndReports {
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-size: 16px !important;
        display: flex !important;
        display: flex !important;
        align-items: flex-end !important;
        text-align: right !important;
        padding-right: 5px !important;
        width: 200px;
    }

    .el-icon-arrow-down {
        font-weight: bolder;
        color: black;
        margin-left: 5px;
    }

    .AnalysisData {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px;
        position: static;
        background: #FFFFFF;
    }

    .NamedTag {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        padding: 4px;
        font-size: 21px;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        color: #FFFFFF;
        background: #245A6E;
        border-radius: 4px;
        width: 44px;
        height: 44px;
    }

    .CustomerNames {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        align-items: center;
        color: #333333;
        margin-left: 15px;
    }

    .tableData {
        position: initial !important;
        width: 1010px !important;
        height: auto !important;
        left: 16px !important;
        top: 16px !important;
        color: #252525 !important;
        order: 0 !important;
        flex-grow: 0 !important;
        margin: 0px 0px !important;
    }

    .btnRunAnalysis, .btnRunAnalysis:hover {
        float: right;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        width: 153px;
        height: 44px;
        border: 1px solid black !important;
        box-sizing: border-box !important;
        border-radius: 8px !important;
        color: black !important;
        background: rgb(243, 242, 239) !important;
        margin-left: 10px;
    }

    .SearchCustomers {
        width: 100% !important;
        box-sizing: border-box !important;
        border-radius: 8px !important;
        border-color: #333333 !important;
    }

    .CustomerCard {
        /*display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;*/
        /*box-sizing: border-box;*/
        max-width: 1100px !important;
        min-width: 1044px !important;
        width: 100% !important;
        margin-left: auto !important;
        margin-right: auto !important;
        top: 24px !important;
        border-radius: 8px !important;
        margin-top: 50px !important;
        padding: 24px !important;
        background-color: #FFFFFF !important;
        /*overflow-y: scroll;*/
        margin-top: 24px !important;
    }

    .MoreCredits {
        color: #46A3F8;
    }

    .btnNext {
        border-radius: 8px;
        float: right;
        background-color: #49A267 !important;
        position: static;
        width: 161px;
        height: 44px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        display: flex;
        align-items: center;
        color: #FFFFFF !important;
        text-align: center;
    }

        .btnNext:hover {
            border-radius: 8px;
            float: right;
            background-color: #49A267 !important;
            position: static;
            width: 161px;
            height: 44px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            display: flex;
            align-items: center;
            color: #FFFFFF !important;
            text-align: center;
        }

    .Credits {
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 16px !important;
        line-height: 41px;
        display: contents;
        align-items: center;
        letter-spacing: -0.04em;
        color: #0F172A !important;
    }

    .analysisStyles button {
        max-width: 80px;
        display: inline-block;
        margin-right: 10px;
    }

        .analysisStyles button:last-child {
            margin-right: 0px;
        }

    .analysisStyles th:last-child, .analysisStyles td:last-child {
        width: 200px;
    }
    .logo {
        flex: 1;
        display: flex;
        justify-content: center;
    }

     .logo img {
        width: 130px;
    }
    .logo p {
        font-weight: bold;
    } 
</style>

<script>
    import $ from 'jquery';
    import LoadingControl from './LoadingControl.vue';
    import helper from './HelperMethods.vue';

    export default {
        name: 'CustomerControl',
        props: ["userSelection", "userDetails"],
        components: {
            LoadingControl,
        },
        computed: {
            UserSelection: function () { return this.userSelection; },
            UserDetails: function () { return this.userDetails; }
        },
        data: function () {
            return {
                mainUserCustomer: [],
                SelectedCustomer: null,
                UserCustomers: [],
                UserCustomersAnalysis: [],
                FilterCustomer: '',
                CustomerReports: [],
            }
        },

        //beforeMount: async function () {
        //    // Added mounted for Create New Client Button but it bypass the screen and showing client details screen.
        //    await this.AddCustomer();
        //},

        //mounted: async function () {
        //    // Added mounted for Create New Client Button but it bypass the screen and showing client details screen.
        //    //await this.AddCustomer();
        //    await this.insertBefore(this.AddCustomer());
        //},
        //insertBefore: async function () {
        //    await this.insertBefore(this.AddCustomer());
        //    //await this.AddCustomer();
        //},
        /*updated: async function () {
            //await this.insertBefore(this.AddCustomer());
            await !this.AddCustomer();
        }, */
        methods: {
            displayDate: function (d) {
                if (d === null)
                    return "-";
                return d.substr(8, 2) + "/" + d.substr(5, 2) + "/" + d.substr(0, 4);
            },
            RefreshCustomers: function () {
                this.UserCustomersAnalysis = [];
                this.CustomerReports = [];
                this.DownloadCustomers(function () { });
                this.DownloadReports(function () { });
            },
            DownloadCustomers: function (actionIfEmpty) {
                var thisControl = this;

                thisControl.UserCustomers = [];
                thisControl.UserCustomersAnalysis = [];

                $.ajax({
                    type: 'GET',
                    url: '/Customer/',
                    contentType: 'application/json',
                    success: function (customers) {
                        thisControl.UserCustomers = customers;
                        thisControl.mainUserCustomer = customers;

                        if (thisControl.UserCustomers.length === 0) {
                            actionIfEmpty();
                        }

                    },
                    error: function (xhr) {
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                        actionIfEmpty();
                    }
                });



            },
            DownloadCustomerAnalysis: function (customerReferece) {
                var thisControl = this;

                $.ajax({
                    type: 'GET',
                    url: '/CustomerAnalysis/' + customerReferece,
                    contentType: 'application/json',
                    success: function (analysis) {
                        if (analysis.length > 0 && analysis !== null)
                            thisControl.UserCustomersAnalysis = helper.mergeArrays([thisControl.UserCustomersAnalysis, analysis.sort(function (a, b) { return a.expires < b.expires ? 1 : -1; })]);
                    },
                    error: function (xhr) {
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                    }
                });
            },
            LoadTool: function (customerReference) {
                var us = this.UserSelection;
                us.selectedCustomerType = null;
                us.customerReference = customerReference;
                this.$emit('customer-selected');
            },
            LoadAnalysis: function (customerReference, analysisReference) {

                var thisControl = this;
                thisControl.UserSelection.customerReference = customerReference;

                $.ajax({
                    type: 'GET',
                    url: '/CustomerAnalysis/' + thisControl.UserSelection.customerReference + '/' + analysisReference,
                    contentType: 'application/json',
                    success: function (data) {
                        if (data.userSelection !== null) {
                            if (data.analysis.analysisReferecnce === undefined) {
                                data.analysis.analysisReferecnce = analysisReference;
                            }
                            thisControl.SetUserSelection(data.userSelection, data.analysis);
                        }
                    },
                    error: function (xhr) {
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                    }
                });
            },
            SetUserSelection: function (StoredUserSelection, analysis) {
                this.$emit('analysis-selected', StoredUserSelection, analysis);
            },
            RunAnalysis: function (customerReference) {
                this.UserSelection.selectedCustomerType = null;
                this.UserSelection.customerReference = customerReference;
                
                this.$emit("load-tool-selection");
            },
            FilterCustomerChange: function (evt) {

                this.UserCustomersAnalysis = [];
                this.UserCustomers = [];

                this.UserCustomers = this.mainUserCustomer.filter(function (uCustomer) {
                    return uCustomer.customerName.toLowerCase().includes(evt.toLowerCase(), 0);
                });
            },
            AddCustomer: function () {
                this.UserSelection.customerReference = null;
                this.$emit('add-customer');
            },
            EditCustomer: function (customerReference) {
                this.UserSelection.customerReference = customerReference;
                this.$emit('edit-customer')
            },
            DownloadReports: function () {
                var thisControl = this;

                $.ajax({
                    type: 'GET',
                    url: '/CustomerReport/',
                    contentType: 'application/json',
                    success: function (reports) {
                        thisControl.CustomerReports = reports;
                    },
                    error: function (xhr) {
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                    }
                });
            },
            DownloadAnalysisReport: function (customerReference, analysisReference, reportReference) {
                this.visible = false;

                window.open('/CustomerReport/' + customerReference + "/" + analysisReference + "/" + reportReference, '_self');
            },
            OpenIntegratorSite: function(externalApplicationId)
            {
                window.open(externalApplicationId, '_blank').focus();
            }
        },
        mounted: function () {
            this.RefreshCustomers();
            this.UserSelection.selectedCustomerType = 0;
        },
        watch:
        {
            "UserCustomers": function () {
                var thisContorl = this;

                this.UserCustomers.forEach(function (cus) {
                    thisContorl.DownloadCustomerAnalysis(cus.customerReference);

                });
            },
            "FilterCustomerData": function (evt) {
                this.UserCustomersAnalysis = [];
                this.UserCustomers = [];

                this.UserCustomers = this.mainUserCustomer.filter(function (uCustomer) {
                    return uCustomer.customerName.toLowerCase().includes(evt.toLowerCase(), 0);
                });
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

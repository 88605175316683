<script setup>
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import { computed, ref, watch } from 'vue';
import MultiSelect from '../ui/MultiSelect.vue';
import { ElButton, ElDialog, ElMessage } from 'element-plus';
import axios, { HttpStatusCode } from 'axios';

const props = defineProps({
    product: Object,
    toolReference: String,
    mappings: Array,
    service: String,
});

const queryClient = useQueryClient();

const productsQueryKey = computed(() => ['pricing-products', props.service]);

const {
    data: products,
} = useQuery(productsQueryKey.value, () => axios(`api/Configuration/ListPricingProducts/${props.service}`).then(res => res.data));


const options = computed(() => {
    const mappedProducts = props.mappings?.filter(m => m.apiProductId != props.product.providerReference)
        .flatMap(m => m.externalIds);
    return products.value?.map(product => ({
        key: String(product.productId),
        label: product.productId  + " - " + product.productName,
        disabled: mappedProducts.includes(String(product.productId))
    })) || []
})

const mapping = computed(() => props.mappings?.find(m => m.apiProductId == props.product.providerReference));
const value = ref(mapping?.value?.externalIds || []);
const mapCount = computed(() => mapping.value?.externalIds?.length || 0);

watch(() => mapping.value, (newValue) => {
    value.value = newValue?.externalIds || [];
})

const { mutate, isLoading: isSaving } = useMutation({
    mutationFn: async (request) => {
        try {
            const response = await axios.post('api/Configuration/SaveProductMapping', JSON.stringify(request), {
                headers: {
                    'Accept': 'application/json, text/plain',
                    'Content-Type': 'application/json;charset=UTF-8',
                },
            });

            if (response.status === HttpStatusCode.Ok) {
                return response.data;
            } else {
                throw new Error(response.statusText);
            }
        } catch (error) {
            throw new Error(error.message);
        }
    },
    onSuccess: () => {
        ElMessage.success('Mapping saved.');
        queryClient.invalidateQueries({ queryKey: ['tool-mappings', props.service, props.toolReference] });
        showDialog.value = false;
    },
    onError: () => {
        ElMessage.error('Mapping could not be saved');
    }
});

const showDialog = ref(false);

function saveMappings() {
    mutate({
        serviceType: 'IPIPELINE',
        apiProductId: props.product.providerReference,
        toolReference: props.toolReference,
        externalIds: value.value,
    });
}

const titles = {left:"iPipeline products", right: props.product.providerName};
</script>
<template>
    <div class="row">
        <div class="title">{{ product.providerName }}</div>
        <div class="subtitle">
            <span>{{ product.providerReference }}</span>
            <span class="mapping" @click="showDialog = true">{{ mapCount }} {{ mapCount == 1 ? 'product' : 'products' }}
                mapped</span>
        </div>
    </div>
    <ElDialog v-model="showDialog" destroy-on-close>
        <p class="tip">Select the products from iPipeline to be mapped to <strong>{{ product.providerName }}</strong></p>
        <MultiSelect v-model="value" :data="options" :titles="titles" />
        <template #footer>
            <div class="dialog-footer">
                <ElButton type="primary" link @click="showDialog = false">Cancel</ElButton>
                <ElButton type="primary" :loading="isSaving" @click="saveMappings">Save and close</ElButton>
            </div>
        </template>
    </ElDialog>
</template>
<style scoped>
.row {
    max-width: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem;
}

.title {
    font-weight: bold;
}

.subtitle {
    display: flex;
    font-size: small;
    gap: 1rem
}

.subtitle .mapping {
    color: var(--el-color-primary);
    text-decoration: underline;
    cursor: pointer;
}

.dialog-footer {
    display: inline-flex;
    gap: 2rem;
}

.dialog-footer button {
    width: unset;
}
</style>
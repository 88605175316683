<template>
    <div>
        <income-protection-policy-details v-if="UserSelection.tool.toolReference === 'IPPro - Pricing'"
                                          v-on:edit-customer="$emit('edit-customer')" v-on:back="$emit('back')"
                                          v-on:policy-details-added="policyDetailsAdded" v-bind:user-selection="UserSelection"
                                          v-bind:user-details="UserDetails" v-bind:template-set="TemplateSet"
                                          @tool-updated="toolUpdate" />

        <term-assurance-policy-details v-else-if="(['CIPlus - Pricing', 'LifePro - Pricing', 'MPCIPro - Pricing', 'MPPro - Pricing'].includes(UserSelection.tool.toolReference))"
                                       v-on:edit-customer="$emit('edit-customer')" v-on:back="$emit('back')"
                                       v-on:policy-details-added="policyDetailsAdded" v-bind:user-selection="UserSelection"
                                       v-bind:user-details="UserDetails" @tool-updated="toolUpdate" />

        <business-protection-policy-details v-else-if="(['KeyPersonCIPro - Pricing', 'KeyPersonPro - Pricing'].includes(UserSelection.tool.toolReference))"
                                            v-on:edit-customer="$emit('edit-customer')" v-on:back="$emit('back')"
                                            v-on:policy-details-added="policyDetailsAdded" v-bind:user-selection="UserSelection"
                                            v-bind:user-details="UserDetails" @tool-updated="toolUpdate" />

        <family-income-benefit-policy-details v-else-if="(['FIBCIPro - Pricing', 'FIBPro - Pricing'].includes(UserSelection.tool.toolReference))"
                                              v-on:edit-customer="$emit('edit-customer')" v-on:back="$emit('back')"
                                              v-on:policy-details-added="policyDetailsAdded" v-bind:user-selection="UserSelection"
                                              v-bind:user-details="UserDetails" @tool-updated="toolUpdate" />

        <policy-details v-else v-on:back="$emit('back')" v-on:edit-customer="$emit('edit-customer')"
                        v-on:policy-details-added="policyDetailsAdded" v-bind:user-selection="UserSelection"
                        v-bind:user-details="UserDetails" @tool-updated="toolUpdate" />

    </div>
</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->

<script>
    import PolicyDetails from "../components/PolicyDetails.vue";
    import IncomeProtectionPolicyDetails from "../components/IncomeProtectionPolicyDetails.vue";
    import TermAssurancePolicyDetails from '../components/TermAssurancePolicyDetails.vue';
    import FamilyIncomeBenefitPolicyDetails from '../components/FamilyIncomeBenefitPolicyDetails.vue';
    import BusinessProtectionPolicyDetails from '../components/BusinessProtectionPolicyDetails.vue';
    import axios from 'axios';
    import helper from '../components/HelperMethods.vue';

    export default {
        name: 'PolicyDetailsPage',
        props: ["userSelection", "userDetails", "templateSet", "currentScreen"],
        computed: {
            UserSelection: function () { return this.userSelection; },
            UserDetails: function () { return this.userDetails; },
            TemplateSet: { get: function () { return this.templateSet; } },
        },
        data: function () {
            return {
                loadingToolConfig: false,
            };
        },
        components: {
            PolicyDetails,
            IncomeProtectionPolicyDetails,
            TermAssurancePolicyDetails,
            FamilyIncomeBenefitPolicyDetails,
            BusinessProtectionPolicyDetails,
        },
        methods: {
            getToolConfiguration: function (toolReference) {
                var that = this;
                that.loadingToolConfig = true;
                return axios.get(`api/Configuration/GetToolConfiguration/${toolReference}`)
                    .then(response => {
                        var { tool, features, extendedFeatures, providers } = response.data;
                        tool.toolReference = toolReference; // for backwards compatibillity
                        that.UserSelection.tool = tool;
                        that.UserSelection.features = features;
                        that.UserSelection.extendedFeatures = extendedFeatures;
                        that.UserSelection.providers = providers
                        that.UserSelection.selectedProviders = []; //clear providers
                        that.UserSelection.includedFeatureInDataTable = []; //Clear included features in DataTable;

                        if (this.UserSelection.extendedFeatures !== undefined && this.UserSelection.extendedFeatures !== null) {
                            this.UserSelection.categoryWeightings = this.UserSelection.extendedFeatures
                                .sort(function (ef1, ef2) { return ef1.featureCategoryOrder - ef2.featureCategoryOrder; })
                                .map(function (ef) { return ef.featureCategory; })
                                .filter(helper.distinct)
                                .map(function (c) { return { category: c, weight: 0, includedInDataTable: 0 } });
                        }
                    })
                    .then(() => {
                        that.loadingToolConfig = false;
                    });
            },
            policyDetailsAdded: function () {
                var thisControl = this;
                thisControl.$emit('policy-details-added');
            },
            toolUpdate: function (toolReference) {
                if (toolReference !== this.UserSelection.tool.toolReference && !this.loadingToolConfig) {
                    this.getToolConfiguration(toolReference);
                }
            },
        }
    }
</script>
<template>
    <div class="holder">
        <div class="mainDiv">
            <div>
                <p>
                    <span>
                        <label class="numbers">4</label>
                        <label class="policyDetails">Quality of plans</label>
                    </span>

                    <span style="float:right">
                        <label class="percentageCompleted">80% completed</label>
                    </span>
                </p>

                <p>
                    <el-progress style="padding-top:5px;" class="el-progress--without-text" :percentage="80"></el-progress>
                </p>

                <div class="item">
                    <p class="Heading4">How would you like to compare the quality of plans?</p>
                </div>


                <div class="radioMargin">
                    <el-radio class="el-radio.is-bordered " label="Express" border v-model="UserSelection.planType">
                        <label class="radioTitle">Express </label><br />
                        <span class="desc">Use preselected features</span>
                    </el-radio>
                </div>

                <div class="radioMargin">
                    <el-radio class="radioElement Paragraph2" label="Custom" border v-model="UserSelection.planType">
                        <label class="radioTitle">Custom </label><br />
                        <span class="desc">I want to select features myself</span>
                    </el-radio>
                </div>

                <div class="btnNextPosition">
                    <el-button type="default" class="btnDefault" v-on:click.stop="$emit('back')"> <span class="el-icon-arrow-left"></span> Back</el-button>
                    <el-button type="success" :disabled="UserSelection.planType === null" v-on:click.stop="SelectedAnswer">Next</el-button>
                </div>

            </div>

        </div>

        <div class="summary" v-if="Customers != null">
            <label class="summaryTitle">Summary</label> <br />

            <div style="display:inline-flex">
                <label class="summaryLabels">Client details</label>
                <el-link v-on:click.stop="$emit('edit-customer')" class="summaryLabels" style="margin-left: 120px; text-decoration: underline">Edit</el-link>
            </div>

            <div v-if="UserSelection.primaryClient !== null">
                <label class="summaryDetail">{{UserSelection.primaryClient.forename + " " + UserSelection.primaryClient.surname}}</label>
                <label class="summaryDetail">
                    {{helper.GetDisplayableDate(UserSelection.primaryClient.dateOfBirth)}} ,
                </label>
                <label class="summaryDetail">
                    {{helper.GetDisplayableClassification(UserSelection.primaryClient.classification)}}
                </label>
            </div>

            <div v-if="UserSelection.secondaryClient !== null">
                <label class="summaryDetail">{{UserSelection.secondaryClient.forename + " " + UserSelection.secondaryClient.surname}}</label>
                <label class="summaryDetail">
                    {{helper.GetDisplayableDate(UserSelection.secondaryClient.dateOfBirth)}} ,
                </label>
                <label class="summaryDetail">{{helper.GetDisplayableClassification(UserSelection.secondaryClient.classification)}}</label>
            </div>

            <hr style="border: 1px solid #A0A0A0;" />
            <label class="summaryLabels">Policy type</label>

            <label class="summaryDetail">{{UserSelection.tool.toolName}}</label>
            <hr style="border: 1px solid #A0A0A0;" />

            <label class="summaryLabels">Policy details</label>
            <label class="summaryDetail">{{PolicyDetails}}</label>
            <hr style="border: 1px solid #A0A0A0;" />

            <div v-if="this.UserSelection.legacyPolicyProvider  !== '' && this.UserSelection.showCIPolicy !== 'No'">
                <label class="summaryLabels">Type of analysis</label>
                <label class="summaryDetail">
                    Historic policy comparison
                    <br />
                    {{ this.UserSelection.legacyPolicyProvider }}
                </label>
                <hr style="border: 1px solid #A0A0A0;" />
            </div>

            <label class="summaryLabels">Quality of plans</label>
            <hr style="border: 1px solid #A0A0A0;" />

            <label class="summaryLabels">Additional info</label>
        </div>
    </div>
</template>
<style scoped>
    /* ipad Mini Landscape */
    @media only screen and (width:1024px) {
        .mainDiv {
            margin-left: -250px;
        }

        .summary {
            left: -250px;
        }
    }

    .percentageCompleted {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        align-items: self-end;
        text-align: right;
        letter-spacing: -0.04em;
    }

    .holder {
        max-width: 1144px;
        min-width: 1044px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 24px;
        position: relative;
    }

    .el-link.el-link--default:hover {
        color: black !important;
    }

    .el-link.is-underline:hover:after {
        border: none;
    }

    .el-link.el-link--default:after {
        border: none;
    }

    .summaryDetail {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;
        width: 194px;
        /*height: 40px;*/
        margin-bottom: 5px;
    }

    .summaryLabels {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 36px;
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;
        color: #A0A0A0;
    }

    .summary {
        position: absolute;
        top: 30px;
        right: 150px;
        width: 232px;
        height: 572px;
    }

    .summaryTitle {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 41px;
        letter-spacing: -0.04em;
        color: #0F172A;
    }

    .el-radio.is-bordered {
        width: 366px !important;
        height: 68px !important;
    }

    .desc {
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 17px !important;
        /*padding-left: 20px !important;*/
        color: #4B5768 !important;
        /*margin-left: 10px !important;*/
    }

    .radioTitle {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: black;
    }

    .btnNextPosition {
        display: inline-flex;
        padding-top: 20px;
        padding-left: 10px;
    }

    .el-button--success {
        color: #fff !important;
        background-color: #059669 !important;
        /*border-color: #059669 !important;*/
    }

    .el-button--default {
        color: black !important;
        background-color: white !important;
        border: 1px solid #191D23 !important;
    }

    /*.el-button--default:focus, .el-button--default:hover {
            background: white;
            border-radius: 8px;
            border: 1px solid #191D23;
            border-radius: 8px;
            color: black;
        }*/

    .el-button {
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        align-items: center !important;
        padding: 10px !important;
        position: static !important;
        width: 167px !important;
        height: 44px !important;
        left: 199px !important;
        top: 0px !important;
        border-radius: 8px !important;
    }

    .el-button--success:focus, .el-button--success:hover {
        background: #059669;
        border-radius: 8px;
        /*border-color: #059669;*/
    }

    .el-button--success.is-disabled, .el-button--success.is-disabled:active, .el-button--success.is-disabled:focus, .el-button--success.is-disabled:hover {
        background: #E7EAEE;
        border-radius: 8px;
        /*border-color: #E7EAEE;*/
        font-style: normal;
        color: #A0ABBB;
    }

    .el-radio {
        width: 366px;
        height: 68px;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .radioMargin {
        padding-bottom: 20px;
        padding-right: 15px;
        flex: none;
        order: 1;
        flex-grow: 0;
        background: #FFFFFF;
        border-radius: 8px;
        float: right;
        height: 68px;
    }

    .el-radio__input.is-checked + .el-radio__label {
        height: 68px;
        color: black;
    }

    .el-radio__input.is-checked .el-radio__inner {
        height: 68px;
        border-color: none !important;
        background: none !important;
    }

    .el-radio.is-bordered.is-checked {
        height: 68px;
        color: black;
        background: #E8EDFB;
        border: 1px solid #6183E4;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .el-radio__input.is-checked + .el-radio__label {
        height: 68px;
        color: black;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
    }

    .el-card.is-always-shadow {
        box-shadow: none;
    }


    .Paragraph2TC {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #191D23;
        padding-left: 15px;
        padding-right: 15px;
    }

    .Heading4 {
        width: 366px;
        height: 74px;
        left: 0px;
        top: 66px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -0.02em;
        color: #0F172A;
    }


    .el-progress__text {
        display: none !important;
    }

    .numbers {
        background: #EEEEEE;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        background: #EEEEEE;
        border-radius: 15px;
        padding: 2px;
    }

    .policyDetails {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        align-items: center;
        letter-spacing: -0.04em;
        padding-left: 10px;
    }

    .mainDiv {
        padding: 72px;
        width: 530px;
        /*height:554px;*/
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 8px;
    }
</style>
<script>
    import $ from 'jquery';
    import helper from './HelperMethods.vue';
    export default {
        name: 'FamilyIncomeBenefitPlanSelection',
        props: ["userSelection", "userDetails", "templateSet"],
        computed: {
            UserSelection: function () { return this.userSelection; },
            UserDetails: function () { return this.userDetails; },
            TemplateSet: function () { return this.templateSet; },
            PolicyDetails: function () {
                const {
                    policyTerm,
                    ipBenefitBasis,
                    ipInitialDeferredPeriod,
                    toAge,
                } = this.userSelection;

                //Benefit basis " + UserSelection.ipBenefitBasis + ", initial deffered period " +UserSelection.ipInitialDeferredPeriod
                return [
                    policyTerm && `Term length: ${policyTerm}`,
                    toAge && `To age: ${toAge}`,
                    ipBenefitBasis && `benefit basis: ${ipBenefitBasis}`,
                    ipInitialDeferredPeriod && `initial deferred period: ${ipInitialDeferredPeriod}`
                ].filter(Boolean).join(', ');
            }
        },
        data: function () {
            return {
                advert: null,
                isLoading: false,
                helper: helper,
                Customers: [],
            }
        },
        methods:
        {
            SelectedAnswer: function () {
                this.UserSelection.expressTemplate = null;

                if (this.UserSelection.planType === "Express")
                    this.$emit('fib-express-question-selected');
                if (this.UserSelection.planType === "Custom")
                    this.$emit('express-question-selected');
            },
            GetCustomer: function () {
                var thisControl = this;
                this.Customers = null;

                $.ajax({
                    type: 'GET',
                    url: '/Customer/' + thisControl.UserSelection.customerReference,
                    data: {},
                    success: function (customer) {
                        if (customer.customerReference === null)
                            customer.customerReference = thisControl.UserSelection.customerReference;

                        thisControl.Customers = customer;

                        if (thisControl.Customers.life1DateOfBirth !== null)
                            thisControl.Customers.life1DateOfBirth = customer.life1DateOfBirth.slice(0, 10);

                        if (thisControl.Customers.life2DateOfBirth !== null)
                            thisControl.Customers.life2DateOfBirth = customer.life2DateOfBirth.slice(0, 10);

                        thisControl.UserSelection.selectedCustomerType = 0;

                    },
                    error: function (xhr) {
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                        thisControl.$message.error("Error updating customer: " + xhr.responseText);
                    }
                });

                return this.Customers;
            },
        },
        created: function () {
            if (window.event?.type !== 'popstate' && this.UserSelection.legacyPolicyProviderReference !== null && this.UserSelection.legacyPolicyProviderReference !== '') {
                this.UserSelection.planType = "Express";
                this.$emit('fib-express-question-selected');
            }

            if (window.event?.type === 'popstate' && this.UserSelection.legacyPolicyProviderReference !== null && this.UserSelection.legacyPolicyProviderReference !== '')
                this.$emit('back');
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

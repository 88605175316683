<template>
    <div class="mainElement">

        <div class="header">
            <h1 class="Title">Detailed Product Data</h1>

        </div>
        <div v-if="DataTableResponse === null">
            <loading-control :loading-message="'Getting Data Tables'" />
        </div>

        <div style="text-align:left;position:sticky;left:0;" v-if="DataTableResponse !== '[null]'">
            <button class="TopRefining" @click="toggleRefining"> {{IsRefining ? 'Remove Selected Questions/Providers' : 'Refine Selection'}}</button>
            <h3 v-if="IsRefining">Select Providers/Questions to remove...</h3>
        </div>


        <div v-if="DataTableResponse !== '[null]'">
            <div>
                <div>
                    <select v-model="SelectedFeatureReference">
                        <option v-for="feature in FeaturesInDataTable.map(function(a) {return a;}).sort(sortFeatures)" v-bind:key="feature.featureReference" v-bind:value="feature.featureReference">{{feature.featureName}}</option>
                    </select>
                    <div v-if="DataTableResponse !== null && SelectedFeatureReference !== null">
                        <div v-if="DataTableResponse.featureDataTables[SelectedFeatureReference].shouldPivotWhenDisplaying === true">
                            <table v-bind:key="subsection.sectionReference" v-for="subsection in DataTableResponse.featureDataTables[SelectedFeatureReference].subsections.map(function(a) {return a;}).sort(sortSectionName)" class="DataTable pivotedDataTable">
                                <thead>
                                    <tr>                                        
                                        <th v-bind:colspan="3">
                                            {{ subsection.sectionName }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th style="width:33%;">
                                            Product
                                        </th>
                                        <th :style="'width:' + (shouldShowQuestion(subsection.questions[0].questions[subsection.questions[0].questions.length-1]) ? '33%' : '66%') + ';'" v-if="shouldShowQuestion(subsection.questions[0])">
                                            <label>
                                                {{ subsection.questions[0].question }}
                                                <input type="checkbox" v-if="IsRefining" :value="subsection.questions[0].questionReference" v-model="RemovedQuestions" />
                                            </label>
                                        </th>
                                        <th v-if="shouldShowQuestion(subsection.questions[0].questions[subsection.questions[0].questions.length-1])"
                                            :style="'width:' + (shouldShowQuestion(subsection.questions[0]) ? '33%' : '66%') + ';'">
                                            <label>
                                                {{ subsection.questions[0].questions[subsection.questions[0].questions.length-1].question }}
                                                <input type="checkbox" v-if="IsRefining" :value="subsection.questions[0].questions[subsection.questions[0].questions.length-1].questionReference" v-model="RemovedQuestions" />
                                            </label>
                                        </th>
                                    </tr>


                                    <tr v-for="provider in DataTableResponse.providers.filter(shouldShowProvider)" :key="'FirstSectionAnswers' + provider.providerReference">
                                        <td style="text-align:left;" class="providerPivoted">
                                            <label>
                                                <strong>{{ provider.providerName }}</strong>
                                                <input type="checkbox" v-if="IsRefining" v-model="RemovedProviders" :value="provider.providerReference" />
                                            </label>
                                        </td>
                                        <td v-if="shouldShowQuestion(subsection.questions[0])"
                                            v-bind:class="'boldbefore ' + (subsection.questions[0].answers[provider.providerReference].answer === 'Yes' ? 'Positive' : 'Negative')"
                                            class="boldbefore"
                                            :data-content="subsection.questions[0].answers[provider.providerReference].answer">
                                            &nbsp;
                                        </td>
                                        <td v-if="shouldShowQuestion(subsection.questions[0].questions[subsection.questions[0].questions.length-1])"
                                            v-bind:class="subsection.questions[0].questions[subsection.questions[0].questions.length-1].answers[provider.providerReference].type">
                                            <span :data-content="returnNAIfNull(subsection.questions[0].questions[subsection.questions[0].questions.length-1].answers[provider.providerReference].answer)" class="boldbefore">&nbsp;</span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-if="shouldShowQuestion(subsection.questions[0].questions[0])">
                                    <tr>
                                        <th style="text-align:left;">
                                        </th>
                                        <th style="text-align:center;position: relative;padding-bottom: 20px;" colspan="2">
                                            <label>
                                                {{ subsection.questions[0].questions[0].question }}
                                                <input type="checkbox" :value="subsection.questions[0].questions[0].questionReference" v-model="RemovedQuestions" />
                                            </label>
                                        </th>
                                    </tr>
                                    <tr v-for="provider in DataTableResponse.providers.filter(shouldShowProvider)" :key="'SecondSectionAnswers' + provider.providerReference">
                                        <td style="text-align:left;" class="providerPivoted">
                                            <label>
                                                <strong>{{ provider.providerName }}</strong>
                                                <input v-if="IsRefining" type="checkbox" :value="provider.providerReference" v-model="RemovedProviders" />
                                            </label>
                                        </td>
                                        <td colspan="2" :data-content="subsection.questions[0].questions[0].answers[provider.providerReference].answer">{{'\xa0'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else class="scrollableTable">
                            <table class="DataTable NormalDataTable">
                                <thead>
                                    <tr>
                                        <th style="min-width:8cm;left:0;z-index:15;">&nbsp;</th>
                                        <th v-for="provider in DataTableResponse.providers.filter(shouldShowProvider)" :key="provider.providerReference">
                                            <label>
                                                {{provider.providerName}}
                                                <input type="checkbox" :value="provider.providerReference" v-if="IsRefining" v-model="RemovedProviders" />
                                            </label>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-for="(item , itemIndex) in DataTableItems.filter(shouldShowQuestion)" :key="itemIndex" class="SubsectionTBody">

                                    <tr v-if="item.type === 'Section'" class="SubsectionHeadingRow">
                                        <!--Section Heading-->
                                        <td v-bind:colspan="DataTableResponse.providers.length + 1">
                                            <div class="qcell">
                                                {{ item.text }}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="item.type === 'Question' && item.questionType === 'Standard'">
                                        <td v-bind:class="'questionCell stickycell QuestionLevel' + item.level">
                                            <label>
                                                {{ item.text }}
                                                <input type="checkbox" v-if="IsRefining" :value="item.questionReference" v-model="RemovedQuestions" />
                                            </label>
                                        </td>
                                        <td v-bind:key="'s' + item.reference + provider.providerReference" v-for="provider in DataTableResponse.providers.filter(shouldShowProvider)" :data-content="Pad(item.answers[provider.providerReference].answer)" v-bind:class="item.answers[provider.providerReference].type">
                                            {{'\xa0'}}
                                        </td>
                                    </tr>

                                    <tr v-if="item.questionType === 'PercentageWithMax'">
                                        <td v-bind:class="'questionCell stickycell QuestionLevel' + item.level" style="font-weight:bold;">
                                            <label>
                                                Maximum percentage of the sum assured
                                                <input type="checkbox" :value="item.questionReference" v-if="IsRefining" v-model="RemovedQuestions" />
                                            </label>
                                        </td>
                                        <td v-for="provider in DataTableResponse.providers.filter(shouldShowProvider)" :key="'p' + item.reference + provider.providerReference" :data-content="typeof(item.answers[provider.providerReference].percentage) == 'undefined' ? '0%' : Pad(item.answers[provider.providerReference].percentage === null ? '-' : item.answers[provider.providerReference].percentage + '%')">
                                            {{'\xa0'}}
                                        </td>
                                    </tr>
                                    <tr v-if="item.questionType === 'PercentageWithMax'">
                                        <td v-bind:class="'questionCell stickycell QuestionLevel' + item.level" style="font-weight:bold;">
                                            <label>
                                                Maximum monetary amount
                                                <input type="checkbox" :value="item.questionReference" v-if="IsRefining" v-model="RemovedQuestions" />
                                            </label>
                                        </td>
                                        <td v-for="provider in DataTableResponse.providers.filter(shouldShowProvider)" :key="'m' + item.reference + provider.providerReference" :data-content="typeof(item.answers[provider.providerReference].maximum) == 'undefined' ? '' : ( Pad( item.answers[provider.providerReference].maximum === null ? 'N/A' : '\xa3' + item.answers[provider.providerReference].maximum))">
                                            {{'\xa0'}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <fieldset class="navigation analysisbottomMargin50">
            <button v-on:click.stop="$emit('back')">Back</button>
            <button :disabled="!CanExportOrSaveTemplate" @click="IsRequestingDataTables = true">Export Selection</button>
            <button :disabled="!CanExportOrSaveTemplate" @click="SaveTemplate">Save Template</button>
        </fieldset>

        <div v-if="IsRequestingDataTables === true" style="min-width:unset">
            <div class="ModalBackground modalDiv" style="display: block;">
                <div class="ModalBackGroundPanel" style="display: block; overflow: hidden; max-height: 650px; min-width: unset; width: 1000px;overflow-y:auto">
                    <div class="header">
                        <h1 class="Title">Export DataTable's</h1>
                        <div v-for="provider in DataTableResponse.providers.filter(shouldShowProvider)" :key="provider.providerReference">
                            <input class="CustomProCheckbox" v-bind:id="'Provider' + provider.providerReference" type="checkbox" :disabled="IncludedProvidersInExport.indexOf(provider.providerReference) === -1 && IncludedProvidersInExport.length > 4" v-model="IncludedProvidersInExport" v-bind:value="provider.providerReference" />
                            <!--<button v-bind:id="provider.providerReference" v-bind:value="provider.providerReference" v-on:click.stop="changeImage(provider.providerReference)" />-->
                            <label v-bind:for="'Provider' + provider.providerReference" style="vertical-align: sub;">{{provider.providerName}}</label>
                        </div>
                        <fieldset class="navigation" style="display: flex;text-align: center;padding-left: 200px !important;">
                            <button :disabled="IncludedProvidersInExport.length === 0 || !CanExportOrSaveTemplate" v-on:click.stop="DownloadPDFDataTables">Export Selection</button>
                            <button v-on:click.stop="IsRequestingDataTables = false" class="button150">Cancel</button>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="IsLoading">
            <loading-control :loading-message="'Exporting DataTables'" />
        </div>
    </div>
</template>
<style scoped>
    .TopRefining {
        display: inline-block;
    }

    .header {
        width: 100%;
    }
    .CustomProCheckbox + label {
        background-image: url(/Images/unselected.svg);
        background-repeat: no-repeat;
        min-height: 30px;
        display: block;
        padding-left: 45px;
        padding-top: 10px;
    }
    .CustomProCheckbox:disabled + label {
        background-image: url(/Images/disabledunselected.svg)
    }
    .CustomProCheckbox:checked + label {
        background-image: url(/Images/selected.svg)
    }
    .providerPivoted {
        padding-left: 20px;
        position: relative;
    }

        .providerPivoted input {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
        }

    .questionCell label input {
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translate(0, -50%)
    }

    table.DataTable td.QuestionLevel0 {
        text-align: left;
        padding-left: 20px;
    }

    table.DataTable td.QuestionLevel1 {
        text-align: left;
        padding-left: 25px;
    }

    table.DataTable td.QuestionLevel2 {
        text-align: left;
        padding-left: 30px;
    }

    table.DataTable td.QuestionLevel3 {
        text-align: left;
        padding-left: 35px;
    }

    table.DataTable td.QuestionLevel4 {
        text-align: left;
        padding-left: 40px;
    }

    table.DataTable td.QuestionLevel5 {
        text-align: left;
        padding-left: 45px;
    }

    table.DataTable tbody th {
        color: white;
        background: #0070C0;
        text-align: center;
    }

        table.DataTable tbody th:first-child {
            text-align: left;
        }

    .pivotedDataTable {
        min-width: 1096px;
        max-width: 1096px;
        margin-left: auto;
        margin-right: auto;
    }

    .Title, select {
        position: sticky;
        left: 0;
    }

    thead th {
        position: sticky;
        top: 0;
        z-index: 5;
        text-align: center;
        padding-bottom: 20px;
    }

        thead th label input {
            position: absolute;
            bottom: 5px;
            left: 50%;
            transform: translate(-50%, 0);
        }

    .pivotedDataTable th label input {
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .qcell {
        position: sticky;
        left: 0;
        min-width: 80vw;
        max-width: 95vw;
    }

    .questionCell {
        width: 8cm;
        background: white;
        border: 1px solid black;
    }

    .stickycell {
        position: sticky;
        left: 0;
    }

    .scrollableTables {
        overflow: auto;
        max-height: 100vh;
    }

    .mainElement {
        display: block;
    }

    div {
        display: inline-block;
    }
</style>
<script>
    import LoadingControl from './LoadingControl.vue';
    import $ from 'jquery';
    import helper from './HelperMethods.vue';

    export default {
        name: 'ViewDataTables',
        components: { LoadingControl },
        props: ["userSelection", "userDetails", "datatableTemplate", "shouldDoFullRefine"],
        data: function () {
            return {
                Error: null,
                IsRefining: false,
                SelectedFeatureReference: null,
                IsLoading: false,
                DataTableResponse: null,
                SelectFeatureInAnalysis: null,
                Features: null,
                ExtendedFeatures: null,
                Providers: null,
                RemovedProviders: [],
                RemovedQuestions: [],
                IncludedProvidersInExport: [],
                IsRequestingDataTables: false
            }
        },
        methods: {
            shouldShowProvider: function (provider) {
                return this.IsRefining || this.RemovedProviders.indexOf(provider.providerReference) === -1;
            },
            shouldShowQuestion: function (question) {
                return this.IsRefining || question.questionReference === null || this.RemovedQuestions.indexOf(question.questionReference) === -1;
            },
            sortSectionName: helper.customSort("sectionName"),
            sortFeatures: helper.customSort("featureName"),
            LoadTables: function () {
                var analysisUrl = "Api/DataTable";
                var request = this.UserSelection;
                var thisControl = this;
                $.ajax({
                    type: 'POST',
                    url: analysisUrl,
                    contentType: 'application/json',
                    data: JSON.stringify(request),
                    success: function (analysis)
                    {
                        thisControl.DataTableResponse = analysis;
                        thisControl.SelectedFeatureReference = thisControl.UserSelection.includedFeatureInDataTable[0].featureReference;
                    },
                    error: function (xhr) {
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                        thisControl.$gtag.exception({
                            'description': 'Error getting Analysis details (' + xhr.status + ').',
                            'fatal': false
                        });
                        //alert("Problem Performing Analysis" + xhr.responseText);
                    }
                });
            },
            Pad: function (v) {
                if (v == null)
                    return '\xa0';
                if (!v.trim) {
                    v = v.toLocaleString();
                }
                if ((v + "").trim() === "")
                    return '\xa0';
                return (v + "").trim();
            },
            ReportDetails: function () {
                var thisControl = this;

                thisControl.UserSelection.reportTitle = "DataTables";

                return {
                    selection: thisControl.UserSelection,
                    analysis: null,
                    firstInsuredName: null,
                    secondInsuredName: null,
                    providersInDataTables: this.IncludedProvidersInExport,
                    reportTitle: "DataTables",
                    ShouldIncludeDefinitions: true, //must correct
                    includedQuestionsInDataTables: this.IncludedQuestionsInExport,
                    removedQuestionInDataTables: this.RemovedQuestions,
                    dataTable: thisControl.DataTableResponse
                };
            },
            DownloadPDFDataTables: function () {
                var request = this.ReportDetails();

                var analysisUrl = "/report/DataTablesPDF";
                var thisControl = this;
                this.IsLoading = true;
                this.IsRequestingReport = false;
                this.IsRequestingDataTables = false;

                $.ajax({
                    type: 'POST',
                    url: analysisUrl,
                    contentType: 'application/json',
                    data: JSON.stringify(request),
                    success: function (pdf) {
                        helper.downloadFile("application/pdf", "DataTable.pdf", pdf);
                        thisControl.IsLoading = false;
                        thisControl.$gtag.event('DataTables Export', {
                            'event_category': thisControl.UserSelection.tool.toolName,
                            'event_label': request.providersInDataTables != null && request.selection.tool.hasDataTables ? "with datatables" : "without datatables",
                        });
                    },
                    error: function (xhr) {
                        thisControl.IsLoading = false;
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                        thisControl.$gtag.exception({
                            'description': 'Error getting dataTables Export (' + xhr.status + ').',
                            'fatal': true
                        });
                        //alert("Problem Getting Report" + xhr.responseText);
                    }
                });
            },
            returnNAIfNull: function (value) {
                if (value == "" || value == null) //deliberatly lazy == here (not using ===)
                    return "N/A";
                return value;
            },
            DownloadHTMLDataTables: function () {
                var request = this.ReportDetails();

                var analysisUrl = "/report/DataTablesHTML";

                var thisControl = this;
                $.ajax({
                    type: 'POST',
                    url: analysisUrl,
                    contentType: 'application/json',
                    data: JSON.stringify(request),
                    success: function (html) {
                        var newWin = open('', 'Report', 'height=900,width=900');
                        newWin.document.write(html);
                    },
                    error: function (xhr) {
                        if (xhr.status == 401) {
                            window.location = "./Api/signin";
                            return;
                        }
                        thisControl.$gtag.exception({
                            'description': 'Error getting Datatable HTML (' + xhr.status + ').',
                            'fatal': true
                        });
                        //alert("Problem Performing Analysis" + xhr.responseText);
                    }
                });
            },
            SaveTemplate: function () {
                var thisControl = this;
                var vTemplateName = prompt("Enter template name:");
                if (vTemplateName != null && vTemplateName != undefined && vTemplateName != "") {
                    $.ajax({
                        type: 'PUT',
                        url: "/DataTableTemplate/" + this.UserSelection.tool.toolReference,
                        data: JSON.stringify({
                            templateName: vTemplateName,
                            toolKey: thisControl.UserSelection.tool.toolReference,
                            includedProviders: thisControl.DataTableResponse.providers.map(function (p) { return p.providerReference; }),
                            filteredProviders: thisControl.RemovedProviders,
                            filteredQuestions: thisControl.RemovedQuestions,
                            includedFeatures: thisControl.UserSelection.includedFeatureInDataTable
                        }),
                        contentType: 'application/json',
                        error: function (xhr) {
                            thisControl.$message.error("Error saving template:" + xhr.responseText);
                        },
                        success: function () {
                            thisControl.$message.error("Saved");
                        }
                    });
                }
            },
            toggleRefining: function () {
                if (this.ShouldDoFullRefine) {
                    this.$emit('full-refine');
                }
                else {
                    this.IsRefining = !this.IsRefining;
                }
            },
            changeImage: function (reference) {
                var thisControl = this;
                if (thisControl.IncludedProvidersInExport != null && thisControl.IncludedProvidersInExport.length <= 5 && getComputedStyle(document.getElementById(reference)).backgroundImage.includes("unselected")) {
                    document.getElementById(reference).style.background = "url(/Images/selected.svg)";
                    thisControl.AddProvider(reference);
                }
                else {
                    document.getElementById(reference).style.background = "url(/Images/unselected.svg)";
                    thisControl.RemoveIncludedProvider(reference);
                }

                if (thisControl.IncludedProvidersInExport != null && thisControl.IncludedProvidersInExport.length == 5) {
                    thisControl.DataTableResponse.providers.filter(thisControl.shouldShowProvider).forEach(function (provider) {
                        if (!thisControl.IncludedProvidersInExport.includes(provider.providerReference))
                            document.getElementById(provider.providerReference).style.background = "url(/Images/disabledunselected.svg)";
                    });
                }
            },
            RemoveIncludedProvider: function (reference) {
                var thisControl = this;

                if (thisControl.IncludedProvidersInExport.indexOf(reference) != -1)
                    thisControl.IncludedProvidersInExport.splice(thisControl.IncludedProvidersInExport.indexOf(reference), 1);

                if (thisControl.IncludedProvidersInExport != null && thisControl.IncludedProvidersInExport.length < 5) {
                    thisControl.DataTableResponse.providers.filter(thisControl.shouldShowProvider).forEach(function (provider) {
                        if (!thisControl.IncludedProvidersInExport.includes(provider.providerReference))
                            document.getElementById(provider.providerReference).style.background = "url(/Images/unselected.svg)";
                    });
                }
            },
            AddProvider: function (reference) {
                if (this.IncludedProvidersInExport != null
                    && this.IncludedProvidersInExport.length < 5
                    && this.IncludedProvidersInExport.indexOf(reference) == -1)
                    this.IncludedProvidersInExport.push(reference);
            }
        },
        created: function () {
            this.LoadTables();
            if (this.DatatableTemplate !== null && typeof (this.DatatableTemplate) !== "undefined") {
                this.RemovedProviders = this.DatatableTemplate.filteredProviders;
                this.RemovedQuestions = this.DatatableTemplate.filteredQuestions;
            }
        },
        watch: {

        },
        computed: {
            UserSelection: function () { return this.userSelection; },
            UserDetails: function () { return this.userDetails; },
            TemplateSet: function () { return this.templateSet; },
            ShouldDoFullRefine: function () { return this.shouldDoFullRefine; },

            CanExportOrSaveTemplate: {
                get: function () {
                    return true;
                }
            },
            FeaturesInDataTable: function () {
                var that = this;
                return this.UserSelection.includedFeatureInDataTable.map(function (incf) { return { featureReference: incf.featureReference, featureName: that.UserSelection.extendedFeatures.filter(function (f) { return f.featureReference === incf.featureReference; })[0].featureName }; })
            },
            DataTableItems: function () {
                var thisControl = this;
                if (thisControl.DataTableResponse === null)
                    return null;
                if (thisControl.SelectedFeatureReference === null)
                    return null;
                var getTable = function () { };
                getTable = function (item, level) {
                    var result = [];
                    if (item.sectionName !== undefined) {//this is a question
                        result = helper.mergeArrays([
                            helper.mergeArrays(item.subsections.map(function (a) { return a; }).sort(thisControl.sortSectionName).map(function (ss) { return getTable(ss, level + 1); })),
                            helper.mergeArrays(item.questions.map(function (q) { return getTable(q, level + 1); }))]
                        );

                        result.unshift(//this is a section
                            {
                                text: item.sectionName,
                                type: "Section",
                                level: level,
                                shouldPivotWhenDisplaying: item.shouldPivotWhenDisplaying,
                                subsections: item.subsections,
                                questionType: item.type,
                                reference: 'Section' + item.sectionReference,
                                questionReference: null
                            });
                    }
                    else {
                        result = helper.mergeArrays(item.questions.map(function (q) { return getTable(q, level + 1); }));
                        result.unshift({
                            text: item.question,
                            type: "Question",
                            level: level,
                            answers: item.answers,
                            questionType: item.type,
                            reference: 'Question' + item.questionReference,
                            questionReference: item.questionReference
                        })
                    }
                    return result;
                }
                return getTable(thisControl.DataTableResponse.featureDataTables[thisControl.SelectedFeatureReference], 0);
                //return null;
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

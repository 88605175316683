<template>
    <p>
        <label :for="id">{{label}}</label>
        <input :id="id" type="number" :min="min" :max="max" :step="step" v-model="displayValue">
        {{units}}
    </p>
</template>

<script>
    function uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }


    export default {
        name: 'NumberInput',
        props: ["label", "value", "units", "integer", "min", "max", "step"],
        data: function () {
            return {
                displayValue: null,
                id: 'numberinput' + uuidv4()
            };
        },
        watch:
        {
            displayValue: function (newValue) {
                var numberValue;
                if (this.integer === true) {
                    numberValue = parseInt(newValue);
                }
                else {
                    numberValue = parseFloat(newValue);
                }
                if (isNaN(numberValue))
                    this.$emit('input', null);
                else if (numberValue != this.value)
                    this.$emit('input', numberValue);
            },
            value: function (newValue) {
                if (newValue === null)
                    this.displayValue = "";
                else
                    this.displayValue = newValue + "";
            }
        },
        methods:
        {
        },
        created: function () {
            if (this.value === null)
                this.displayValue = "";
            else
                this.displayValue = this.value + ""
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

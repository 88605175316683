<template>
    <div class="holder" v-if="!UserSelection.goToUnderWriteMe">
        <div class="mainFeatureDiv">
            <div>
                <p>
                    <span>
                        <label class="numbers">6</label>
                        <label class="featuresDetails">Product features</label>
                    </span>

                    <span style="float:right">
                        <label class="percentageCompleted">90% completed</label>
                    </span>
                </p>

                <p>
                    <el-progress style="padding-top:5px;" class="el-progress--without-text" :percentage="90"></el-progress>
                </p>

                <div class="item">
                    <p class="Heading4">Select the product features you would like to include</p>
                </div>

                <feature-row :key="extendedFeature.featureReference" v-for="extendedFeature in AvailableFeatures"
                             :should-disable="ShouldDisable || LimitFeature"
                             v-on:custom-subfeature-weights-selected="customSubfeatureWeightsSelected"
                             :extended-feature="extendedFeature" :analysis-type="UserSelection.analysisType"
                             :express-template-feature="expressTemplateFeature(extendedFeature)" />

                <div class="btnNextPosition">
                    <el-button type="default" class="btnDefault" v-on:click.stop="$emit('back')">
                        <span class="el-icon-arrow-left"></span> Back
                    </el-button>
                    <el-button type="success" v-on:click.stop="CheckContinue(CurrentCategory)" :disabled="!CanProceed()"> Next </el-button>
                </div>

                <sub-feature-selection v-if="!isLoading && CurrentFeature !== null"
                                       :feature-name="CurrentFeature.featureName" :sub-features="EditedSubFeatures"
                                       v-on:subfeature-committed="commitSubfeatureWeights()"
                                       v-on:subfeature-cancelled="cancelSubfeatureWeight()" />
                <child-confirm v-if="!isLoading && IsShowingChild"
                               v-on:return-to-policy-details="$emit('backtopolicy-selected')" v-on:closed="IsShowingChild = false"
                               v-on:childconfirm-continue="$emit('feature-selection-completed', CurrentCategory); IsShowingChild = false;" />

            </div>
        </div>

        <div v-if="false">
            <advertisement v-if="advert !== null" :advert="advert" loading-message="Loading..."
                           :user-selection="UserSelection" :screen="'Feature Selection - ' + CurrentCategory" />
            <loading-control v-if="isLoading && advert === null" :loading-message="'loading...'" />
            <fieldset v-if="!isLoading">
                <legend>
                    Category - {{ CurrentCategory }} <span v-if="UserDetails.maxFeaturesPerComparison != null">(Maximum
                        {{ UserDetails.maxFeaturesPerComparison }} Features in Total) - {{ RemainingFeatures() }}
                        Remaining</span>
                </legend>
                <p v-if="UserSelection.analysisType === 'Comparison'">Please select the features you would like to include
                    in the analysis and give these a priority, where 1 is the lowest priority and 5 is the highest priority.
                    Features that are given a higher priority will have a bigger impact on the end score</p>
                <p v-else>Please select the features you would like to include in the data table and give these a priority.
                </p>
                <table class="analysisStyles">
                    <thead>
                        <tr>
                            <th>Feature</th>
                            <th>{{ UserSelection.analysisType === "Comparison" ? "Priority" : "Included" }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <feature-row :key="extendedFeature.featureReference" v-for="extendedFeature in AvailableFeatures"
                                     :should-disable="ShouldDisable || LimitFeature"
                                     v-on:custom-subfeature-weights-selected="customSubfeatureWeightsSelected"
                                     :extended-feature="extendedFeature" :analysis-type="UserSelection.analysisType"
                                     :express-template-feature="expressTemplateFeature(extendedFeature)" />
                    </tbody>
                </table>
                <div id="performAnalysisBackButtons">
                </div>
            </fieldset>
            <fieldset v-if="!isLoading" class="navigation analysisbottomMargin50">
                <button v-on:click.stop="$emit('back')">Back</button>
                <button v-on:click.stop="CheckContinue(CurrentCategory)" :disabled="!CanProceed()">{{ !IsLast ? "Next" :
                    UserSelection.analysisType === "Comparison" ? "Get Results" : "Get Data Tables" }}</button>
            </fieldset>
            <sub-feature-selection v-if="!isLoading && CurrentFeature !== null" :feature-name="CurrentFeature.featureName"
                                   :sub-features="EditedSubFeatures" v-on:subfeature-committed="commitSubfeatureWeights()"
                                   v-on:subfeature-cancelled="cancelSubfeatureWeight()" />
            <child-confirm v-if="!isLoading && IsShowingChild"
                           v-on:return-to-policy-details="$emit('backtopolicy-selected')"
                           v-on:childconfirm-continue="$emit('feature-selection-completed', CurrentCategory); IsShowingChild = false;" />
        </div>

        <div class="summary">
            <label class="summaryTitle">Summary</label> <br />

            <div style="display:inline-flex">
                <label class="summaryLabels">Client details</label>
                <el-link v-on:click.stop="$emit('edit-customer')" class="summaryLabels"
                         style="margin-left: 120px; text-decoration: underline">Edit</el-link>
            </div>

            <div v-if="UserSelection.primaryClient !== null">
                <label class="summaryDetail">{{ UserSelection.primaryClient.forename + " " +
                    UserSelection.primaryClient.surname }}</label>
                <label class="summaryDetail">
                    {{ helper.GetDisplayableDate(UserSelection.primaryClient.dateOfBirth) }} ,
                    {{ helper.GetDisplayableClassification(UserSelection.primaryClient.classification) }}
                </label>
            </div>

            <div v-if="UserSelection.secondaryClient !== null">
                <label class="summaryDetail">{{ UserSelection.secondaryClient.forename + " " +
                    UserSelection.secondaryClient.surname }}</label>
                <label class="summaryDetail">
                    {{ helper.GetDisplayableDate(UserSelection.secondaryClient.dateOfBirth) }} ,
                    {{ helper.GetDisplayableClassification(UserSelection.secondaryClient.classification) }}
                </label>
            </div>

            <hr style="border: 1px solid #A0A0A0;" />
            <label class="summaryLabels">Policy type</label>

            <label class="summaryDetail">{{ UserSelection.tool.toolName }}</label>
            <hr style="border: 1px solid #A0A0A0;" />

            <label class="summaryLabels">Policy details</label><br />
            <label class="summaryDetail">{{ "Term length " + UserSelection.policyTerm + " , Benefit basis " +
                UserSelection.ipBenefitBasis + ", initial deferred period " + deferredPeriod[UserSelection.ipInitialDeferredPeriod]
                }}</label> <br />

            <hr style="border: 1px solid #A0A0A0;" />

            <label class="summaryLabels">Quality of plans</label>
            <label class="summaryDetail">{{ UserSelection.planType }}</label>
            <hr style="border: 1px solid #A0A0A0;" />

            <label class="summaryLabels">Additional info</label>
        </div>

    </div>
</template>


<style scoped>
.holder {
    max-width: 1144px;
    min-width: 1144px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    position: relative;
}

/* ipad Mini Landscape */
@media only screen and (width:1024px) {
    .mainFeatureDiv {
        margin-left: -250px;
    }

    .summary {
        left: -250px;
    }
}

.mainFeatureDiv {
    padding: 52px;
    width: 531px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 8px;
}

.el-link.el-link--default:hover {
    color: black !important;
}

.el-link.is-underline:hover:after {
    border: none;
}

.el-link.el-link--default:after {
    border: none;
}

.summaryDetail {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: -0.04em;
    width: 194px;
    height: 40px;
}

.summaryLabels {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 36px;
    display: flex;
    align-items: center;
    letter-spacing: -0.04em;
    color: #A0A0A0;
}

.summary {
    position: absolute;
    width: 232px;
    height: 572px;
    top: 0;
    right: 150px;
}

.summaryTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 41px;
    letter-spacing: -0.04em;
    color: #0F172A;
}

.btnNextPosition {
    display: inline-flex;
    padding-top: 20px;
    padding-left: 10px;
}

.el-button--success {
    color: #fff !important;
    background-color: #059669 !important;
    /*border-color: #059669 !important;*/
}

.el-button--default {
    color: black !important;
    background-color: white !important;
    border: 1px solid #191D23 !important;
}

/*.el-button--default:focus, .el-button--default:hover {
            background: white;
            border-radius: 8px;
            border: 1px solid #191D23;
            border-radius: 8px;
            color: black;
        }*/

.el-button {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 10px !important;
    position: static !important;
    width: 167px !important;
    height: 44px !important;
    left: 199px !important;
    top: 0px !important;
    border-radius: 8px !important;
}

.el-button--success:focus,
.el-button--success:hover {
    background: #059669 !important;
    border-radius: 8px !important;
    /*border-color: #059669 !important;*/
}

.el-button--success.is-disabled,
.el-button--success.is-disabled:active,
.el-button--success.is-disabled:focus,
.el-button--success.is-disabled:hover {
    background: #E7EAEE !important;
    border-radius: 8px !important;
    /*border-color: #E7EAEE !important;*/
    font-style: normal !important;
    color: #A0ABBB !important;
}

.Heading4 {
    width: 366px;
    height: 74px;
    left: 0px;
    top: 66px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    letter-spacing: -0.02em;
    color: #0F172A;
}

.el-collapse-item__header {
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 19px !important;
    display: flex !important;
    align-items: center !important;
    width: 313px !important;
    height: 33px !important;
}

.el-icon-arrow-right:before {
    content: "+" !important;
}

.el-icon-arrow-right:before {
    content: "-" !important
}

.numbers {
    background: #EEEEEE;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    background: #EEEEEE;
    border-radius: 15px;
    padding: 2px;
}

.featuresDetails {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    align-items: center;
    letter-spacing: -0.04em;
    padding-left: 10px;
}

.percentageCompleted {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: -0.04em;
}
</style>

<script>
    import helper from '../components/HelperMethods.vue';
import $ from 'jquery';
    import LoadingControl from '../components/LoadingControl.vue';
    import Advertisement from '../components/Advertisement.vue';

    import ChildConfirm from "../components/ChildConfirm.vue";
    import FeatureRow from "../components/FeatureRow.vue";
    import SubFeatureSelection from "../components/SubFeatureSelection.vue";
export default {
    name: 'FeatureSelection',
    props: ["userSelection", "userDetails", "currentCategory", "isLast", "adverts"],
    components: { FeatureRow, SubFeatureSelection, ChildConfirm, LoadingControl, Advertisement },
    data: function () {
        return {
            deferredPeriod: ['1 day', '1 Week', '2 Weeks', '1 Month', '2 Month', '3 Month', '6 Month', '12 Month', '24 Months'],
            helper: helper,
            FeatureCategories: [],
            isDatatable: false,
            CurrentFeature: null,
            IsShowingChild: false,
            EditedSubFeatures: [],
            advert: null,
            isLoading: false
        }
    },
    mounted: function () {
        var thisControl = this;
        var eCat = thisControl.expressCategory;

        if (eCat !== null) {
            eCat.availableFeatures.forEach(function (af) {
                var ef;

                if (thisControl.UserSelection.legacyPolicyProviderReference !== null && thisControl.UserSelection.legacyPolicyProviderReference !== '') {
                    var legacyFeature = thisControl.UserSelection.legacyFeatures.filter(function (lf) { return lf.featureReference === af.featureReference });

                    ef = thisControl.UserSelection.extendedFeatures.filter(function (aef) {
                        return aef.featureName.toLowerCase().replace('tooltip:', '') === legacyFeature[0].featureName.toLowerCase().replace('tooltip:', '');
                    });
                }
                else {
                    ef = thisControl.UserSelection.extendedFeatures.filter(function (aef) {
                        return aef.featureReference === af.featureReference
                    });
                }

                if (ef.length > 0)
                    ef = ef[0];
                else
                    return;
                ef.weight = af.defaultPriority;
                switch (af.subfeatureWeightingModel) {
                    case "WeightSubfeaturesEqually":
                        ef.subFeatureWeightModel = "Equality weightings for subfeatures";
                        break;
                    case "CustomWeighting":
                        ef.subFeatureWeightModel = "Custom Weightings";
                        //Set subfeature weights...

                        ef.subFeatures.forEach(function (sf) {
                            var afsf = af.customSubFeatureWeightings[sf.subfeatureReference];

                            if (afsf !== null)
                                sf.weight = afsf;
                        });
                        break;
                }
            });

            //if (any features can alter their priority return)
            if (eCat.availableFeatures.some(function (af) { return af.availablePriorities.length > 1 })) {
                return;
            }

            if (eCat.canAddAdditionalFeatures) {
                return;
            }
            //if some features where we can edit subfeatures then return
            if (eCat.availableFeatures.some(function (af) {
                var ef = thisControl.UserSelection.extendedFeatures.filter(function (aef) { return aef.featureReference === af.featureReference });
                if (ef.length > 0)
                    ef = ef[0];
                else
                    return;

                return af.canChangeSubfeaturesweightings && ef.subFeatures.length == 0;
            })) {
                return;
            }

            //User cannot change anything here so lets skip.
            this.$emit('feature-selection-skipped', thisControl.CurrentCategory);
            return;
        }

        this.logScreenAndShowAdvert();

    },

    methods: {
        logScreenAndShowAdvert: function () {
            var thisControl = this;
            var categoryOrder = thisControl.UserSelection.extendedFeatures.filter(function (ef) {
                return ef.featureCategory === thisControl.CurrentCategory;
            })[0].featureCategoryOrder;
            if (thisControl.UserSelection.expressTemplate === null) {
                var waiting = 1;
                this.isLoading = true;

                var done = function () {
                    thisControl.isLoading = false;
                    thisControl.advert = null;
                }
                var adToShow = helper.GetAdvert(this.adverts, "FeatureSelectionPage" + (categoryOrder + 1), this.UserSelection);
                if (adToShow !== null) {
                    this.advert = adToShow;
                    waiting++;
                    setTimeout(function () {
                        waiting--;
                        if (waiting == 0)
                            done();
                    }, adToShow.minimumDurationInSeconds * 1000);
                }
                $.ajax({
                    type: 'GET',
                    url: '/api/Journey/Update/' + this.UserSelection.sessionReference + "/" + this.UserSelection.journeyReference + "/" + (30 + categoryOrder) + "/Feature%20Selection%20" + encodeURIComponent("(" + thisControl.CurrentCategory + ")"),
                    success: function () {
                        waiting--;
                        if (waiting == 0)
                            done();
                    },
                });
            }
        },
        RemainingFeatures: function () {
            if (this.UserDetails.maxFeaturesPerComparison === null)
                return null;
            return this.UserDetails.maxFeaturesPerComparison - this.UserSelection.extendedFeatures.filter(function (ef) { return ef.weight > 0; }).length;
        },
        CanProceed: function () {
            if (this.UserSelection.analysisType === "Comparison" && this.RemainingFeatures() === 0)
                return true;
            else
                return this.AvailableFeatures.some(this.UserSelection.analysisType === "Comparison" ? function (ef) { return ef.weight > 0; } : function (ef) { return ef.includeInDataTable > 0; });
        },
        cancelSubfeatureWeight: function () {
            this.CurrentFeature = null;
            this.EditedSubFeatures = [];
        },
        commitSubfeatureWeights: function () {
            this.CurrentFeature.subFeatureWeightModel = 'Custom Weightings';
            this.CurrentFeature.subFeatures = this.EditedSubFeatures;
            this.cancelSubfeatureWeight();
        },
        customSubfeatureWeightsSelected: function (ef) {
            this.CurrentFeature = ef;
            this.EditedSubFeatures = ef.subFeatures.map(function (toCopy) { return { subfeatureReference: toCopy.subfeatureReference, weight: toCopy.weight, subfeatureName: toCopy.subfeatureName }; });
        },
        CheckContinue: function (CurrentCategory) {
            var thisControl = this;
            var childFeature = thisControl.UserSelection.features.filter(function (f) {
                return thisControl.UserSelection.extendedFeatures.some(function (ef) {
                    return ef.featureCategory === thisControl.CurrentCategory && f.featureReference == ef.featureReference;
                })
            });
            childFeature = childFeature.filter(function (f) {
                return f.requiresChildrensDetails === true;
            });

            if (childFeature.length > 0)
                thisControl.UserSelection.hasChildRequire = thisControl.UserSelection.extendedFeatures.filter(function (ef) { return ef.weight > 0; }).filter(function (sef) { return sef.featureReference === childFeature[0].featureReference }).length > 0 ? "Yes" : "No";
            else
                thisControl.UserSelection.hasChildRequire = "No";

            if (thisControl.UserSelection.analysisType !== "DataTables" && thisControl.UserSelection.children.length === 0
                && childFeature !== null && childFeature.length > 0
                && thisControl.UserSelection.extendedFeatures.filter(function (ef) { return ef.weight > 0; }).filter(function (sef) { return sef.featureReference === childFeature[0].featureReference }).length > 0) {
                this.IsShowingChild = true;
            }
            else {
                this.$emit('feature-selection-completed', CurrentCategory);
            }
        },
        expressTemplateFeature: function (ef) {
            var eCat = this.expressCategory;
            if (eCat === null)
                return null;
            var eCatFeature = eCat.availableFeatures.filter(function (af) { return af.featureReference === ef.featureReference; });
            if (eCatFeature.length > 0) {
                return eCatFeature[0];
            }
            return null;

        },
    },
    computed:
    {
        UserSelection: function () { return this.userSelection; },
        UserDetails: function () { return this.userDetails; },
        CurrentCategory: function () { return this.currentCategory; },
        IsLast: function () { return this.isLast },
        expressCategory: function () {
            var thisControl = this;
            if (thisControl.UserSelection.expressTemplate === null)
                return null;

            var templateCategories = thisControl.UserSelection.expressTemplate.includedCategories
                .filter(function (ic) {
                    return (ic.categoryReference === thisControl.CurrentCategory || ic.categoryReference.includes(thisControl.CurrentCategory));
                });

            if (templateCategories.length > 0)
                return templateCategories[0];
            return null;
        },
        ShouldDisable: function () {
            if (this.RemainingFeatures() === 0 || this.RemainingFeatures() > 5)
                return true;
            else
                return false;
        },
        LimitFeature: function () {
            //var thisControl = this;
            return false;//thisControl.UserSelection.extendedFeatures.filter(function (ef) { return ef.featureCategory === thisControl.CurrentCategory && ef.weight > 0; }).length > 4;
        },
        AvailableFeatures: function () {
            var thisControl = this;
            var result = [];
            if (this.UserSelection.extendedFeatures !== null) {
                result = this.UserSelection.extendedFeatures.filter(function (feature) {
                    return feature.featureCategory === thisControl.CurrentCategory;
                });
            }

            if (this.expressCategory !== null) {
                if (!this.expressCategory.canAddAdditionalFeatures) {

                    result = result.filter(function (f) { return thisControl.expressCategory.availableFeatures.some(function (af) { return af.featureReference == f.featureReference; }) })
                }
            }
            /*if (false && this.UserSelection.expressTemplate !== null && !this.UserSelection.expressTemplate.canAddAdditionalCategories) {
                return result.filter(function (c) { return thisControl.UserSelection.expressTemplate.includedCategories.some(function (ic) { return ic.categoryReference == c.category; }) })
            }*/


            return result;
        },
    },
    watch: {
        "CurrentCategory": function () {
            var thisControl = this;

            var eCat = thisControl.expressCategory;
            if (eCat !== null) {
                eCat.availableFeatures.forEach(function (af) {
                    var ef = thisControl.UserSelection.extendedFeatures.filter(function (aef) { return aef.featureReference === af.featureReference });
                    if (ef.length > 0)
                        ef = ef[0];
                    else
                        return;
                    ef.weight = af.defaultPriority;
                    switch (af.subfeatureWeightingModel) {
                        case "WeightSubfeaturesEqually":
                            ef.subFeatureWeightModel = "Equality weightings for subfeatures";
                            break;
                        case "CustomWeighting":
                            ef.subFeatureWeightModel = "Custom Weightings";
                            //Set subfeature weights...

                            ef.subFeatures.forEach(function (sf) {
                                var afsf = af.customSubFeatureWeightings[sf.subfeatureReference];

                                if (afsf !== null)
                                    sf.weight = afsf;
                            });
                            break;
                    }
                });

                //if (any features can alter their priority return)
                if (eCat.availableFeatures.some(function (af) { return af.availablePriorities.length > 1 })) {
                    return;
                }

                if (eCat.canAddAdditionalFeatures) {
                    return;
                }
                //if some features where we can edit subfeatures then return
                if (eCat.availableFeatures.some(function (af) {
                    var ef = thisControl.UserSelection.extendedFeatures.filter(function (aef) { return aef.featureReference === af.featureReference });
                    if (ef.length > 0)
                        ef = ef[0];
                    else
                        return;

                    return af.canChangeSubfeaturesweightings && ef.subFeatures.length == 0;
                })) {
                    return;
                }

                //User cannot change anything here so lets skip.
                this.$emit('feature-selection-skipped', thisControl.CurrentCategory);
                return;
            }
            this.logScreenAndShowAdvert();
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
